import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { DATE_FORMAT } from '../constants/global';

export const FormUtils = {
  createTextField(name, label, type = 'text', options = {}) {
    return (
      <TextField
        margin="normal"
        fullWidth
        id={name}
        label={label}
        name={name}
        type={type}
        { ...options }
      />
    );
  },

  bindMaterialFormDataForAPI(formData = {}) {
    const materialMasterDTO = {
      materialId: formData.materialId,
      activityId: formData.activity?.key,
      modelId: formData.model?.key,
      subActivityId: formData.subActivity?.key,
      nameId: formData.subActivityName?.key,
      specifications: [
        ...formData.specification?.map(item => ({
          specId: item?.key,
        })),
      ],
      brandId: formData.brand?.key,
      madeId: formData.country?.key,
      packId: formData.package?.key,
      latitude: formData.location.lat,
      longitude: formData.location.lng,
      delyRadius: formData.deliveryRadius,
      delyTime: formData.deliveryTime,
      model: formData.model,
      seasonDetails: [],
      materialProdDTO: {
        materialId: formData.materialId,
        warrExpyDate: formData.warrExpyDate?.format(DATE_FORMAT, DATE_FORMAT),
        prodDuration: formData.prodDuration,
        prodQty: formData.prodQty,
        prodUnit: formData.prodUnit,
      },
    };
    const { prices } = formData;
    for (const [key, value] of Object.entries(prices)) {
      const rows = Object.values(value);
      const seasonMasId = parseInt(key, 10);
      const seasonData = {
        seasonMasId,
        unitDetails: [],
      };
      const seasonId = formData.seasonIds[seasonMasId];
      if (seasonId) {
        seasonData.seasonId = seasonId;
      }

      if (seasonMasId === 2) {
        seasonData.seasonFrom = formData.sFromDate?.format(DATE_FORMAT);
        seasonData.seasonTo = formData.sToDate?.format(DATE_FORMAT);
      } else if (seasonMasId === 3) {
        seasonData.seasonFrom = formData.cFromDate?.format(DATE_FORMAT);
        seasonData.seasonTo = formData.cToDate?.format(DATE_FORMAT);
      }

      rows.forEach(({
        unitId,
        unit,
        quantity,
        price,
      }) => {
        if (unit && quantity && price) {
          seasonData.unitDetails.push({
            unitId: unitId || null,
            unitMasId: unit.key,
            quantity,
            price,
          });
        }
      });
      materialMasterDTO.seasonDetails.push(seasonData);
    }
    return { materialMasterDTO };
  },

  bindMaterialFormDataForUI(data = {}, activities, subActivities,
    subActivityNames, brands, models, specifications = [], countries, packages, units
  ) {
    const { warrExpyDate } = data.materialProdDTO;
    const materialData = {
      activity: activities.find(({ key }) => key === data.activityId),
      subActivity: subActivities.find(({ key }) => key === data.subActivityId),
      subActivityName: subActivityNames.find(({ key }) => key === data.nameId),
      brand: brands.find(({ key }) => key === data.brandId),
      model: models.find(({ key }) => key === data.modelId),
      specification: data.specifications.map(({ specId }) => specifications.find(({ key }) => key === specId)),
      country: countries.find(({ key }) => key === data.madeId),
      package: packages.find(({ key }) => key === data.packId),
      warrExpyDate: warrExpyDate ? dayjs(warrExpyDate, DATE_FORMAT, true) : null,
      sFromDate: null,
      sToDate: null,
      cFromDate: null,
      cToDate: null,
      prodDuration: data.materialProdDTO?.prodDuration,
      prodQty: data.materialProdDTO?.prodQty,
      prodUnit: data.materialProdDTO?.prodUnit,
      deliveryRadius: data.delyRadius,
      deliveryTime: data.delyTime,
      location: {
        lat: parseFloat(data.latitude),
        lng: parseFloat(data.longitude),
      },
      imgDetails: data.imgDetails,
      parentId: data.parentId,
      groupDetails: data.groupDetails,
    };
    const { seasonDetails } = data;
    const prices = {};
    const rowNumber = {
      1: 1,
      2: 1,
      3: 1,
    };
    const seasonIds = {};
    seasonDetails?.forEach(season => {
      const {
        seasonId,
        seasonMasId,
        unitDetails,
        seasonFrom,
        seasonTo,
      } = season;
      seasonIds[seasonMasId] = seasonId;
      rowNumber[seasonMasId] = unitDetails?.length + 1;
      const rowData = {};
      if (seasonMasId === 2) {
        materialData.sFromDate = seasonFrom ? dayjs(seasonFrom, DATE_FORMAT, true) : null;
        materialData.sToDate = seasonTo ? dayjs(seasonTo, DATE_FORMAT, true) : null;
      } else if (seasonMasId === 3) {
        materialData.cFromDate = seasonFrom ? dayjs(seasonFrom, DATE_FORMAT, true) : null;
        materialData.cToDate = seasonTo ? dayjs(seasonTo, DATE_FORMAT, true) : null;
      }
      for (const [key, details] of Object.entries(unitDetails)) {
        const row = {
          rowNum: parseInt(key, 10) + 1,
          unitId: details.unitId,
          unit: units.find(({ key: id }) => id === details.unitMasId),
          quantity: details.quantity,
          price: details.price,
        };
        rowData[parseInt(key, 10) + 1] = row;
      }
      prices[seasonMasId] = rowData;
    });
    return { ...materialData, prices, rowNumber, seasonIds };
  },

  getMaterialDefaultData() {
    return {
      activity: null,
      brand: null,
      country: null,
      package: null,
      specification: [],
      subActivity: null,
      subActivityName: null,
      warrExpyDate: null,
      sFromDate: null,
      sToDate: null,
      cFromDate: null,
      cToDate: null,
      prodDuration: '',
      prodQty: '',
      prodUnit: '',
      deliveryRadius: '',
      deliveryTime: '',
      model: null,
      imgDetails: [],
      location: {
        lat: 25.2048,
        lng: 55.2708,
      },
      prices: {},
      seasonIds: {},
      rowNumber: {
        1: 1,
        2: 1,
        3: 1,
      },
    };
  },
  getFilterQuery(payload) {
    let query = null;
    if (payload) {
      const queryParams = [];
      Object.keys(payload).forEach(key => {
        const value = payload[key];
        if (value) {
          if (key === 'specification') {
            if (value.length) {
              queryParams.push(value.map(item => `${key}=${encodeURIComponent(item.value)}`).join('&'));
            }
          } else if (value.value) {
            queryParams.push(`${key}=${encodeURIComponent(value.value)}`);
          } else {
            queryParams.push(`${key}=${encodeURIComponent(value)}`);
          }
        }
      });
      query = queryParams.join('&');
    }
    return query;
  },
  getActivityFormData(data = []) {
    const formData = {};
    data.forEach(act => {
      const subAct = [];
      act.listGroupSubActivity.forEach(sub => {
        if (sub.selected) {
          subAct.push(sub.groupSubActivityId);
        }
      });
      formData[act.groupActivityId] = subAct;
    });
    return formData;
  },
  getActivityFormDataForAPI(formData = {}) {
    const data = [];
    for (const [key, value] of Object.entries(formData)) {
      const act = {};
      act.activity = key;
      act.subActivity = value;
      data.push(act);
    }
    return data;
  },
};

export default FormUtils;
