export default {
  title: 'Self Service Team',
  userName: 'User Name',
  password: 'Password',
  confirmPass: 'Confirm Password',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email Address',
  captcha: 'Enter Captcha',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  memberCategory: 'Member Category',
  member: 'Member',
  group: 'Group',
  company: 'Company',
  rememberMe: 'Remember me',
  haveAccountMsg: 'Already have an account? Sign in',
  reloadCaptcha: 'Reload Captcha',
  signUpMsg: 'Don\'t have an account? Sign Up',
  forgotPass: 'Forgot password?',
};
