import { RequestActionType } from '../actions/utils';

export const MEMBER_PERSONAL_DETAILS = RequestActionType('MEMBER_PERSONAL_DETAILS');
export const MEMBER_CONTACT_DETAILS = RequestActionType('MEMBER_CONTACT_DETAILS');
export const SAVE_MEMBER_PERSONAL_DETAILS = RequestActionType('SAVE_MEMBER_PERSONAL_DETAILS');
export const GET_DOCUMENT_DETAILS = RequestActionType('GET_DOCUMENT_DETAILS');
export const MEMBER_EDUCATION_DETAILS = RequestActionType('MEMBER_EDUCATION_DETAILS');
export const MEMBER_BANK_DETAILS = RequestActionType('MEMBER_BANK_DETAILS');
export const MEMBER_EMPLOYEE_DETAILS = RequestActionType('MEMBER_EMPLOYEE_DETAILS');
export const MEMBER_JOB_DETAILS = RequestActionType('MEMBER_JOB_DETAILS');
export const MEMBER_REGISTRATION_DETAILS = RequestActionType('MEMBER_REGISTRATION_DETAILS');
