/* eslint-disable react/prop-types */
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import React from 'react';

const InnerSnackbarUtilsConfigurator = props => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef;
const setUseSnackbarRef = useSnackbarRefProp => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

const closeButton = snackbarId => (
  <IconButton
    aria-label="close"
    color="inherit"
    size="small"
    onClick={() => { useSnackbarRef.closeSnackbar(snackbarId); }}
  >
    <CloseIcon />
  </IconButton>
);

export const snackActions = {
  success(msg, options) {
    this.toast(msg, { ...options, variant: 'success', action: closeButton });
  },
  warning(msg, options) {
    this.toast(msg, { ...options, variant: 'warning', action: closeButton });
  },
  info(msg, options) {
    this.toast(msg, { ...options, variant: 'info', action: closeButton });
  },
  error(msg, options) {
    this.toast(msg, { ...options, variant: 'error', action: closeButton });
  },
  toast(msg, options = {}) {
    useSnackbarRef.enqueueSnackbar(msg, { ...options, action: closeButton });
  },
};
