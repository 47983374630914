import { lazy, React } from 'react';
import Loadable from './LazyComponents/Loadable';
import ProtectedLayout from '../layout/ProtectedLayout';

const AdminDashBoard = Loadable(lazy(() => import('../pages/admin/DashBoard')));
const Members = Loadable(lazy(() => import('../pages/admin/Member')));
const NewGroups = Loadable(lazy(() => import('../pages/admin/Group/New')));
const GroupActivity = Loadable(lazy(() => import('../pages/admin/Group/Activity')));
const MaterialMaster = Loadable(lazy(() => import('../pages/admin/MaterialMaster')));

const AdminRoute = {
  path: '/admin',
  element: <ProtectedLayout />,
  children: [
    {
      path: 'dashboard',
      element: <AdminDashBoard />,
    },
    {
      path: 'members',
      element: <Members cat={1}/>,
    },
    {
      path: 'group/list',
      element: <Members cat={2} />,
    },
    {
      path: 'group/new-list',
      element: <NewGroups cat={2} />,
    },
    {
      path: 'group/activity',
      element: <GroupActivity cat={2} />,
    },
    {
      path: 'company/list',
      element: <Members cat={3} />,
    },
    {
      path: 'company/new-list',
      element: <NewGroups cat={3} />,
    },
    {
      path: 'company/activity',
      element: <GroupActivity cat={3} />,
    },
    {
      path: 'material-master',
      element: <MaterialMaster />,
    },
  ],
};

export default AdminRoute;
