export const signUserMock = () => Promise.resolve({
  data: {
    token: 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VySWQiOjEsInN1YiI6ImFkbWluIiwiaWF0IjoxNjY1MjQ3NjEwNzU5LCJleHAiOjE2NjUyNjkyMTB9.YPDfsCueD2kefHQMVa92Wqa74pkzqlVg3WDKlggfiZWL7d6kfofdH7d-razY5qt28_LMYWb8iqYcknnwV_JVyw',
    username: 'admin',
    roles: [
      'ROLE_MEMBER',
    ],
    refreshToken: 'ac25add4-0890-49c6-bedc-420adbaad099',
    memberProfileCompletionStatusDTO: {
      personalDetail: false,
      contactDetail: false,
      documentDetail: false,
      educationDetail: false,
      bankDetail: false,
      employmentDetail: false,
      jobRequestDetail: false,
    },
  },
});

export default {
  signUser: signUserMock,
};
