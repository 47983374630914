import { all, call, put, takeLatest } from 'redux-saga/effects';
import { sendOTP, signUp, signUser, validateOTP } from '../api/auth';
import { SIGN_UP, SIGN_USER, VALIDATE_OTP } from '../constants/auth';
import { snackActions } from '../utils/SnackBarUtils';

export function * signUserSaga({ payload }) {
  try {
    const response = yield call(signUser, payload);
    const { data } = response;
    const { responseMessage, responseCode, responseDesc } = data;

    if (responseMessage) {
      let error = null;
      if (responseMessage === 'deactivatedUser') {
        snackActions.error('Your account has been deactivated. Please contact your admin to activate.');
      } else if (responseMessage === 'notVerified') {
        snackActions.error('Your account has been not verified. Please verify it.');
        error = data;
      } else {
        snackActions.error('Could not able to login!. Please try after some time');
      }
      yield put({
        type: SIGN_USER.Fail,
        payload: error,
      });
    } else if (responseCode && responseCode !== 1) {
      snackActions.error(responseDesc);
      yield put({
        type: SIGN_USER.Fail,
      });
    } else {
      snackActions.success('Logged in successfully!!');
      yield put({
        type: SIGN_USER.Success,
        payload: data,
      });
    }
  } catch (e) {
    yield put({
      type: SIGN_USER.Fail,
      payload: e,
    });
    snackActions.error('Could not able to login!. Username or Password Incorrect');
  }
}

export function * signUpSaga({ payload }) {
  try {
    const response = yield call(signUp, payload);
    const { data: { userId, responseCode, responseDesc } } = response;
    if (responseCode && responseCode > 1) {
      snackActions.error(responseDesc);
      yield put({
        type: SIGN_UP.Fail,
      });
    } else {
      const otpResponse = yield call(sendOTP, { userId });
      const { data: { verificationId } } = otpResponse;
      yield put({
        type: SIGN_UP.Success,
        payload: verificationId,
      });
    }
  } catch (e) {
    yield put({
      type: SIGN_UP.Fail,
      payload: e,
    });
    const { response = {} } = e;
    const { status, data: { responseMessage } } = response;
    if (status === 400) {
      snackActions.error(responseMessage);
    } else {
      snackActions.error('Could not able to sign up!. Please try after some time');
    }
  }
}

export function * validateOTPSaga({ payload }) {
  try {
    const response = yield call(validateOTP, payload);
    const { data: { responseCode, responseDesc } } = response;
    if (responseCode !== 1) {
      snackActions.error(responseDesc);
      yield put({
        type: VALIDATE_OTP.Fail,
        payload: 'failed',
      });
    } else {
      snackActions.success('Sign Up successful Please Login');
      yield put({
        type: VALIDATE_OTP.Success,
        payload: 'success',
      });
    }
  } catch (e) {
    yield put({
      type: VALIDATE_OTP.Fail,
      payload: e,
    });
    snackActions.error('Could not able to sign up!. Please try after some time');
  }
}

export function * watchUserSaga() {
  yield takeLatest(SIGN_USER.Request, signUserSaga);
  yield takeLatest(SIGN_UP.Request, signUpSaga);
  yield takeLatest(VALIDATE_OTP.Request, validateOTPSaga);
}

export default function * userSaga() {
  yield all([
    watchUserSaga(),
  ]);
}
