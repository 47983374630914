export const groupMaterialMock = () => Promise.resolve({
  status: 'success',
  data: {
    data: [
      {
        id: 'MM00001',
        subAct: 'Elec',
        subActName: 'Abc',
        specification: 'specification',
        brand: 'brand',
        make: 'make',
        warranty: '2025-09-23',
        packageDesc: 'packageDesc',
        quantity: 1,
        priceDetailsMap: {
          Season: {
            id: 2,
            prices: [
              495.00,
              500.00,
            ],
            '495.00': [
              {
                matId: 2,
                groupId: 150,
                groupName: 'Abc1 private limited',
              },
            ],
            '500.00': [
              {
                matId: 1,
                groupId: 149,
                groupName: 'Abc2 private limited',
              },
              {
                matId: 10,
                groupId: 151,
                groupName: 'Abc3 private limited',
              },
            ],
          },
        },
      },
      {
        id: 'MM00002',
        subAct: 'Elec',
        subActName: 'Abc',
        specification: 'specification',
        brand: 'brand',
        make: 'make',
        warranty: '2025-09-23',
        packageDesc: 'packageDesc',
        quantity: 10,
        priceDetailsMap: {
          Season: {
            id: 2,
            prices: [
              4950.00,
              5000.00,
            ],
            '4950.00': [
              {
                matId: 2,
                groupId: 150,
                groupName: 'Abc1 private limited',
              },
            ],
            '5000.00': [
              {
                matId: 1,
                groupId: 149,
                groupName: 'Abc2 private limited',
              },
              {
                matId: 10,
                groupId: 151,
                groupName: 'Abc3 private limited',
              },
            ],
          },
        },
      },
    ],
  },
});

export default {
  groupMaterial: groupMaterialMock,
};
