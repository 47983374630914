/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Box, Skeleton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { downloadFile } from '../../api/common';

class UserAvatar extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
  }

  state = {
    imageLoading: false,
    imageURL: null,
  };

  componentDidMount() {
    const { fileId } = this.props;
    if (fileId) {
      this.loadImage();
    }
  }

  componentDidUpdate(prevProps) {
    const { fileId: prevFileId, uploadCount: preUploadCount } = prevProps;
    const { fileId, uploadCount } = this.props;
    const { imageLoading } = this.state;
    if (((fileId && fileId !== prevFileId) ||
      preUploadCount !== uploadCount) && !imageLoading) {
      this.loadImage();
    }
  };

  componentWillUnmount() {
    const { imageURL } = this.state;
    URL.revokeObjectURL(imageURL);
  }

  loadImage = () => {
    const { fileId } = this.props;
    this.setState({ imageLoading: true });
    downloadFile(fileId).then(response => {
      this.setState({ imageURL: URL.createObjectURL(response.data), imageLoading: false });
    });
  };

  render() {
    const { imageURL, imageLoading } = this.state;
    const {
      fontSize = 15,
      text = 'U',
      size = 40,
      fileId,
    } = this.props;
    return (
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        height: size,
        width: size,
        backgroundColor: grey[400],
        color: 'white',
        fontSize,
        textTransform: 'uppercase',
        overflow: 'hidden',
        '& img': {
          width: '100%',
          border: 'none',
          outline: 'none',
          display: 'block',
        },
      }}>
        {
          !fileId
            ? text.charAt(0)
            : null
        }
        {
          fileId && imageLoading
            ? <Skeleton variant="rounded" width="100%" height={size} />
            : null
        }
        {
          fileId && !imageLoading
            ? <img src={imageURL} />
            : null
        }
      </Box>
    );
  }
}

export default UserAvatar;
