/* eslint-disable react/prop-types */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router';
import { Container } from '@mui/material';
import UserMenu from './UserMenu';
import Logo from './Logo';
import ResponsiveMenuIcon from './ResponsiveMenuIcon';

const ResponsiveAppBar = ({ menuItems }) => {
  const { pages = [], user: userMenu = [] } = menuItems;
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = path => {
    setAnchorElNav(null);
    if (typeof path === 'string') {
      navigate(path);
    }
  };

  return (
    <AppBar position="fixed" component="nav">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {
            pages.length
              ? (
                <>
                  <ResponsiveMenuIcon
                    handleToggle={handleOpenNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}
                  />
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}
                  >
                    {pages.map(({ label, path }) => (
                      <MenuItem key={label} onClick={() => handleCloseNavMenu(path)}>
                        <Typography textAlign="center">{label}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )
              : null
          }

          <Logo />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, px: 2 }}>
            {pages.map(({ label, path }) => (
              <Button
                key={label}
                onClick={() => handleCloseNavMenu(path)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {label}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />
          <UserMenu userMenu={userMenu} handleCloseNavMenu={handleCloseNavMenu} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
