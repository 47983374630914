import {
  ADD_BRANDS,
  ADD_PACKAGE,
  ADD_SPECIFICATIONS,
  ADD_SUB_ACTIVITY_NAMES,
  ADD_UNITS,
  DELETE_BANK_DETAILS,
  DELETE_CONTACT_DETAILS,
  DELETE_DOCUMENT_DETAILS,
  GET_ACTIVITIES,
  GET_BRANDS,
  GET_COUNTRIES,
  GET_GROUP_DETAILS,
  GET_PACKAGE,
  GET_SPECIFICATIONS,
  GET_SUB_ACTIVITIES,
  GET_SUB_ACTIVITY_NAMES,
  GET_UNITS,
  GROUP_ADMIN_ROLES,
  GROUP_BANK_DETAILS,
  GROUP_CONTACT_DETAILS,
  GROUP_DOCUMENT_DETAILS,
  GROUP_MATERIAL,
  GROUP_MATERIAL_SELECTION,
  GROUP_TEAMS_CONTACT,
  GROUP_TEAM_MEMBER,
  RESET_ADD_BRANDS,
  RESET_ADD_PACKAGE,
  RESET_ADD_SPECIFICATIONS,
  RESET_ADD_SUB_ACTIVITY_NAMES,
  RESET_ADD_UNITS,
  RESET_SAVE_BANK_DETAILS,
  RESET_SAVE_CONTACT_DETAILS,
  RESET_SAVE_DOCUMENT_DETAILS,
  RESET_SAVE_GROUP_TEAM_MEMBER,
  RESET_SAVE_MATERIAL_MASTER,
  SAVE_BANK_DETAILS,
  SAVE_CONTACT_DETAILS,
  SAVE_DOCUMENT_DETAILS,
  SAVE_GROUP_DETAILS,
  SAVE_GROUP_TEAM_MEMBER,
  SAVE_MATERIAL_MASTER
} from '../constants/group';

const dataObjArray = {
  fetching: false,
  data: [],
};

const dataObj = {
  fetching: false,
  data: {},
};

const saveObj = {
  saving: false,
  data: null,
  hasErrors: false,
};

export const initialState = {
  materials: dataObjArray,
  materialSelection: dataObjArray,
  teams: dataObjArray,
  roles: dataObjArray,
  member: dataObj,
  saveMember: saveObj,
  subActivities: dataObjArray,
  subActivityNames: dataObjArray,
  specifications: dataObjArray,
  brands: dataObjArray,
  countries: dataObjArray,
  packages: dataObjArray,
  unites: dataObjArray,
  addSubActivityName: saveObj,
  addSpecification: saveObj,
  addBrand: saveObj,
  addPackage: saveObj,
  addUnit: saveObj,
  contactDetails: dataObjArray,
  documentDetails: dataObjArray,
  bankDetails: dataObjArray,
  saveMaterial: saveObj,
  saveGroupDetails: saveObj,
  groupDetails: dataObj,
  saveDocuments: saveObj,
  saveContact: saveObj,
  saveBank: saveObj,
  deleteData: {
    deleting: false,
  },
  activities: dataObjArray,
};

const groupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case GROUP_MATERIAL.Request: {
    return {
      ...state,
      materials: {
        fetching: true,
        data: [],
      },
    };
  }
  case GROUP_MATERIAL.Success: {
    return {
      ...state,
      materials: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GROUP_MATERIAL.Fail: {
    return {
      ...state,
      materials: dataObj,
    };
  }
  case GROUP_MATERIAL_SELECTION.Request: {
    return {
      ...state,
      materialSelection: {
        fetching: true,
        data: [],
      },
    };
  }
  case GROUP_MATERIAL_SELECTION.Success: {
    return {
      ...state,
      materialSelection: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GROUP_MATERIAL_SELECTION.Fail: {
    return {
      ...state,
      materialSelection: dataObjArray,
    };
  }
  case GROUP_TEAMS_CONTACT.Request: {
    return {
      ...state,
      teams: {
        fetching: true,
        data: [],
      },
    };
  }
  case GROUP_TEAMS_CONTACT.Success: {
    return {
      ...state,
      teams: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GROUP_TEAMS_CONTACT.Fail: {
    return {
      ...state,
      teams: dataObjArray,
    };
  }
  case GROUP_ADMIN_ROLES.Request: {
    return {
      ...state,
      roles: {
        fetching: true,
        data: [],
      },
    };
  }
  case GROUP_ADMIN_ROLES.Success: {
    return {
      ...state,
      roles: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GROUP_ADMIN_ROLES.Fail: {
    return {
      ...state,
      roles: dataObjArray,
    };
  }
  case GROUP_TEAM_MEMBER.Request: {
    return {
      ...state,
      member: {
        fetching: true,
        data: [],
      },
    };
  }
  case GROUP_TEAM_MEMBER.Success: {
    return {
      ...state,
      member: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GROUP_TEAM_MEMBER.Fail: {
    return {
      ...state,
      member: dataObjArray,
    };
  }
  case SAVE_GROUP_TEAM_MEMBER.Request: {
    return {
      ...state,
      saveMember: {
        ...initialState.saveMember,
        saving: true,
      },
    };
  }
  case SAVE_GROUP_TEAM_MEMBER.Success: {
    return {
      ...state,
      saveMember: {
        ...initialState.saveMember,
        saving: false,
        data: payload,
      },
    };
  }
  case SAVE_GROUP_TEAM_MEMBER.Fail: {
    return {
      ...state,
      saveMember: {
        ...initialState.saveMember,
        hasErrors: true,
      },
    };
  }
  case RESET_SAVE_GROUP_TEAM_MEMBER: {
    return {
      ...state,
      saveMember: initialState.saveMember,
    };
  }
  case GET_SUB_ACTIVITIES.Request: {
    return {
      ...state,
      subActivities: {
        fetching: true,
        data: [],
      },
    };
  }
  case GET_SUB_ACTIVITIES.Success: {
    return {
      ...state,
      subActivities: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_SUB_ACTIVITIES.Fail: {
    return {
      ...state,
      subActivities: dataObjArray,
    };
  }
  case GET_ACTIVITIES.Request: {
    return {
      ...state,
      activities: {
        fetching: true,
        data: [],
      },
    };
  }
  case GET_ACTIVITIES.Success: {
    return {
      ...state,
      activities: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_ACTIVITIES.Fail: {
    return {
      ...state,
      activities: dataObjArray,
    };
  }
  case GET_SUB_ACTIVITY_NAMES.Request: {
    return {
      ...state,
      subActivityNames: {
        fetching: true,
        data: [],
      },
    };
  }
  case GET_SUB_ACTIVITY_NAMES.Success: {
    return {
      ...state,
      subActivityNames: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_SUB_ACTIVITY_NAMES.Fail: {
    return {
      ...state,
      subActivityNames: dataObjArray,
    };
  }
  case GET_SPECIFICATIONS.Request: {
    return {
      ...state,
      specifications: {
        fetching: true,
        data: [],
      },
    };
  }
  case GET_SPECIFICATIONS.Success: {
    return {
      ...state,
      specifications: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_SPECIFICATIONS.Fail: {
    return {
      ...state,
      specifications: dataObjArray,
    };
  }
  case GET_BRANDS.Request: {
    return {
      ...state,
      brands: {
        fetching: true,
        data: [],
      },
    };
  }
  case GET_BRANDS.Success: {
    return {
      ...state,
      brands: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_BRANDS.Fail: {
    return {
      ...state,
      brands: dataObjArray,
    };
  }
  case GET_COUNTRIES.Request: {
    return {
      ...state,
      countries: {
        fetching: true,
        data: [],
      },
    };
  }
  case GET_COUNTRIES.Success: {
    return {
      ...state,
      countries: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_COUNTRIES.Fail: {
    return {
      ...state,
      countries: dataObjArray,
    };
  }
  case GET_PACKAGE.Request: {
    return {
      ...state,
      packages: {
        fetching: true,
        data: [],
      },
    };
  }
  case GET_PACKAGE.Success: {
    return {
      ...state,
      packages: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_PACKAGE.Fail: {
    return {
      ...state,
      packages: dataObjArray,
    };
  }
  case GET_UNITS.Request: {
    return {
      ...state,
      unites: {
        fetching: true,
      },
    };
  }
  case GET_UNITS.Success: {
    return {
      ...state,
      unites: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_UNITS.Fail: {
    return {
      ...state,
      unites: dataObjArray,
    };
  }
  case ADD_SUB_ACTIVITY_NAMES.Request: {
    return {
      ...state,
      addSubActivityName: {
        ...initialState.addSubActivityName,
        saving: true,
      },
    };
  }
  case ADD_SUB_ACTIVITY_NAMES.Success: {
    return {
      ...state,
      addSubActivityName: {
        ...initialState.addSubActivityName,
        saving: false,
        data: payload,
      },
    };
  }
  case ADD_SUB_ACTIVITY_NAMES.Fail: {
    return {
      ...state,
      addSubActivityName: {
        ...initialState.addSubActivityName,
        hasErrors: true,
      },
    };
  }
  case RESET_ADD_SUB_ACTIVITY_NAMES: {
    return {
      ...state,
      addSubActivityName: initialState.addSubActivityName,
    };
  }
  case ADD_BRANDS.Request: {
    return {
      ...state,
      addBrand: {
        ...initialState.addBrand,
        saving: true,
      },
    };
  }
  case ADD_BRANDS.Success: {
    return {
      ...state,
      addBrand: {
        ...initialState.addBrand,
        saving: false,
        data: payload,
      },
    };
  }
  case ADD_BRANDS.Fail: {
    return {
      ...state,
      addBrand: {
        ...initialState.addBrand,
        hasErrors: true,
      },
    };
  }
  case RESET_ADD_BRANDS: {
    return {
      ...state,
      addBrand: initialState.addBrand,
    };
  }
  case ADD_PACKAGE.Request: {
    return {
      ...state,
      addPackage: {
        ...initialState.addPackage,
        saving: true,
      },
    };
  }
  case ADD_PACKAGE.Success: {
    return {
      ...state,
      addPackage: {
        ...initialState.addPackage,
        saving: false,
        data: payload,
      },
    };
  }
  case ADD_PACKAGE.Fail: {
    return {
      ...state,
      addPackage: {
        ...initialState.addPackage,
        hasErrors: true,
      },
    };
  }
  case RESET_ADD_PACKAGE: {
    return {
      ...state,
      addPackage: initialState.addPackage,
    };
  }
  case ADD_SPECIFICATIONS.Request: {
    return {
      ...state,
      addSpecification: {
        ...initialState.addSpecification,
        saving: true,
      },
    };
  }
  case ADD_SPECIFICATIONS.Success: {
    return {
      ...state,
      addSpecification: {
        ...initialState.addSpecification,
        saving: false,
        data: payload,
      },
    };
  }
  case ADD_SPECIFICATIONS.Fail: {
    return {
      ...state,
      addSpecification: {
        ...initialState.addSpecification,
        hasErrors: true,
      },
    };
  }
  case RESET_ADD_SPECIFICATIONS: {
    return {
      ...state,
      addSpecification: initialState.addSpecification,
    };
  }
  case ADD_UNITS.Request: {
    return {
      ...state,
      addUnit: {
        ...initialState.addUnit,
        saving: true,
      },
    };
  }
  case ADD_UNITS.Success: {
    return {
      ...state,
      addUnit: {
        ...initialState.addUnit,
        saving: false,
        data: payload,
      },
    };
  }
  case ADD_UNITS.Fail: {
    return {
      ...state,
      addUnit: {
        ...initialState.addUnit,
        hasErrors: true,
      },
    };
  }
  case RESET_ADD_UNITS: {
    return {
      ...state,
      addUnit: initialState.addUnit,
    };
  }
  case GROUP_CONTACT_DETAILS.Request: {
    return {
      ...state,
      contactDetails: {
        fetching: true,
      },
    };
  }
  case GROUP_CONTACT_DETAILS.Success: {
    return {
      ...state,
      contactDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GROUP_CONTACT_DETAILS.Fail: {
    return {
      ...state,
      contactDetails: dataObjArray,
    };
  }
  case GROUP_DOCUMENT_DETAILS.Request: {
    return {
      ...state,
      documentDetails: {
        fetching: true,
      },
    };
  }
  case GROUP_DOCUMENT_DETAILS.Success: {
    return {
      ...state,
      documentDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GROUP_DOCUMENT_DETAILS.Fail: {
    return {
      ...state,
      documentDetails: dataObjArray,
    };
  }
  case GROUP_BANK_DETAILS.Request: {
    return {
      ...state,
      bankDetails: {
        fetching: true,
      },
    };
  }
  case GROUP_BANK_DETAILS.Success: {
    return {
      ...state,
      bankDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GROUP_BANK_DETAILS.Fail: {
    return {
      ...state,
      bankDetails: dataObjArray,
    };
  }
  case SAVE_MATERIAL_MASTER.Request: {
    return {
      ...state,
      saveMaterial: {
        ...initialState.saveMaterial,
        saving: true,
      },
    };
  }
  case SAVE_MATERIAL_MASTER.Success: {
    return {
      ...state,
      saveMaterial: {
        ...initialState.saveMaterial,
        saving: false,
        data: payload,
      },
    };
  }
  case SAVE_MATERIAL_MASTER.Fail: {
    return {
      ...state,
      saveMaterial: {
        ...initialState.saveMaterial,
        hasErrors: true,
      },
    };
  }
  case RESET_SAVE_MATERIAL_MASTER: {
    return {
      ...state,
      saveMaterial: initialState.saveMaterial,
    };
  }
  case GET_GROUP_DETAILS.Request: {
    return {
      ...state,
      groupDetails: {
        fetching: true,
      },
    };
  }
  case GET_GROUP_DETAILS.Success: {
    return {
      ...state,
      groupDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_GROUP_DETAILS.Fail: {
    return {
      ...state,
      groupDetails: dataObj,
    };
  }
  case SAVE_GROUP_DETAILS.Request: {
    return {
      ...state,
      saveGroupDetails: {
        ...initialState.saveGroupDetails,
        saving: true,
      },
    };
  }
  case SAVE_GROUP_DETAILS.Success: {
    return {
      ...state,
      saveGroupDetails: {
        ...initialState.saveGroupDetails,
        saving: false,
        data: payload,
      },
    };
  }
  case SAVE_GROUP_DETAILS.Fail: {
    return {
      ...state,
      saveGroupDetails: {
        ...initialState.saveGroupDetails,
        hasErrors: true,
      },
    };
  }
  case SAVE_DOCUMENT_DETAILS.Request: {
    return {
      ...state,
      saveDocuments: {
        ...initialState.saveDocuments,
        saving: true,
      },
    };
  }
  case SAVE_DOCUMENT_DETAILS.Success: {
    return {
      ...state,
      saveDocuments: {
        ...initialState.saveDocuments,
        saving: false,
        data: payload,
      },
    };
  }
  case SAVE_DOCUMENT_DETAILS.Fail: {
    return {
      ...state,
      saveDocuments: {
        ...initialState.saveDocuments,
        hasErrors: true,
      },
    };
  }
  case RESET_SAVE_DOCUMENT_DETAILS: {
    return {
      ...state,
      saveDocuments: initialState.saveDocuments,
    };
  }
  case SAVE_CONTACT_DETAILS.Request: {
    return {
      ...state,
      saveContact: {
        ...initialState.saveContact,
        saving: true,
      },
    };
  }
  case SAVE_CONTACT_DETAILS.Success: {
    return {
      ...state,
      saveContact: {
        ...initialState.saveContact,
        saving: false,
        data: payload,
      },
    };
  }
  case SAVE_CONTACT_DETAILS.Fail: {
    return {
      ...state,
      saveContact: {
        ...initialState.saveContact,
        hasErrors: true,
      },
    };
  }
  case RESET_SAVE_CONTACT_DETAILS: {
    return {
      ...state,
      saveContact: initialState.saveContact,
    };
  }
  case SAVE_BANK_DETAILS.Request: {
    return {
      ...state,
      saveBank: {
        ...initialState.saveBank,
        saving: true,
      },
    };
  }
  case SAVE_BANK_DETAILS.Success: {
    return {
      ...state,
      saveBank: {
        ...initialState.saveBank,
        saving: false,
        data: payload,
      },
    };
  }
  case SAVE_BANK_DETAILS.Fail: {
    return {
      ...state,
      saveBank: {
        ...initialState.saveBank,
        hasErrors: true,
      },
    };
  }
  case RESET_SAVE_BANK_DETAILS: {
    return {
      ...state,
      saveBank: initialState.saveBank,
    };
  }
  case DELETE_CONTACT_DETAILS.Success: {
    return {
      ...state,
    };
  }
  case DELETE_DOCUMENT_DETAILS.Success: {
    return {
      ...state,
    };
  }
  case DELETE_BANK_DETAILS.Success: {
    return {
      ...state,
    };
  }
  default: return state;
  }
};

export default groupReducer;
