import {
  ACTIVE_MEMBERS,
  ADD_DESCRIPTION,
  ADD_MATERIAL_DETAILS,
  ALL_ROLE_GROUP_ACTIVITIES,
  ALL_ROLE_GROUP_ADMIN,
  BANK_MASTER,
  BLOOD_GROUP,
  CONTACT_TYPE,
  DELETE_NOTIFICATION,
  DELETE_PRICE,
  DOCUMENT_TYPE,
  GET_ALL_MATERIALS,
  GET_DESCRIPTION,
  GET_LATEST_PRICE,
  GET_MATERIAL,
  GET_MATERIAL_DETAILS,
  GET_NOTIFICATIONS,
  GET_PRICE_REQUESTS,
  PAID_BASIC_MASTER,
  PRICE_REQUEST,
  PRICE_REQUEST_APPROVE,
  PRICE_REQUEST_REJECT,
  RESET_ADD_MATERIAL_DETAILS,
  RESET_PRICE_REQUEST,
  SAVE_MATERIAL_IMAGES,
  WORK_TIME_MASTER
} from '../constants/common';

export const actionGetAllActiveMembers = query => ({
  type: ACTIVE_MEMBERS.Request,
  payload: query,
});

export const actionGetAllContactType = () => ({
  type: CONTACT_TYPE.Request,
});

export const actionGetAllDocType = () => ({
  type: DOCUMENT_TYPE.Request,
});

export const actionGetBankMaster = () => ({
  type: BANK_MASTER.Request,
});

export const actionGetBloodGroup = () => ({
  type: BLOOD_GROUP.Request,
});

export const actionGetWorkTimeMaster = () => ({
  type: WORK_TIME_MASTER.Request,
});

export const actionGetPaidBasisMaster = () => ({
  type: PAID_BASIC_MASTER.Request,
});

export const actionGetAllRoleGroupAdmin = () => ({
  type: ALL_ROLE_GROUP_ADMIN.Request,
});

export const actionGetAllGroupActivitesActive = () => ({
  type: ALL_ROLE_GROUP_ACTIVITIES.Request,
});

export const actionGetAllMaterials = filter => ({
  type: GET_ALL_MATERIALS.Request,
  payload: filter,
});

export const actionGetMaterialById = id => ({
  type: GET_MATERIAL.Request,
  payload: id,
});

export const actionSaveMaterialImages = payload => ({
  type: SAVE_MATERIAL_IMAGES.Request,
  payload,
});

export const actionAddDescription = payload => ({
  type: ADD_DESCRIPTION.Request,
  payload,
});

export const actionGetDescription = payload => ({
  type: GET_DESCRIPTION.Request,
  payload,
});

export const actionDeletePriceDetails = (matId, id) => ({
  type: DELETE_PRICE.Request,
  payload: {
    matId,
    id,
  },
});

export const actionGetMaterialDetails = matId => ({
  type: GET_MATERIAL_DETAILS.Request,
  payload: matId,
});

export const actionAddMaterialDetails = (data, filters) => ({
  type: ADD_MATERIAL_DETAILS.Request,
  payload: {
    data,
    filters,
  },
});

export const actionResetAddMaterialDetails = () => ({
  type: RESET_ADD_MATERIAL_DETAILS,
});

export const actionGetLatestPrice = matId => ({
  type: GET_LATEST_PRICE.Request,
  payload: matId,
});

export const actionPriceRequest = (data, filters) => ({
  type: PRICE_REQUEST.Request,
  payload: {
    data,
    filters,
  },
});

export const actionResetPriceRequest = () => ({
  type: RESET_PRICE_REQUEST,
});

export const actionPriceRequestApprove = (data, type, status) => ({
  type: PRICE_REQUEST_APPROVE.Request,
  payload: {
    data,
    type,
    status,
  },
});

export const actionPriceRequestReject = (data, type, status) => ({
  type: PRICE_REQUEST_REJECT.Request,
  payload: {
    data,
    type,
    status,
  },
});

export const actionGetPriceRequests = (type, status) => ({
  type: GET_PRICE_REQUESTS.Request,
  payload: {
    type,
    status,
  },
});

export const actionGetNotifications = () => ({
  type: GET_NOTIFICATIONS.Request,
});

export const actionDeleteNotifications = id => ({
  type: DELETE_NOTIFICATION.Request,
  payload: {
    id,
  },
});
