import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  enableMembers,
  getAllCompanyRegDetails,
  getAllGroupRegDetails,
  getAllMembers,
  getCompanyBankDetails,
  getCompanyContactDetails,
  getCompanyDetails,
  getCompanyDocumentDetails,
  getDocumentDetails,
  getGroupBankDetails,
  getGroupContactDetails,
  getGroupDetails,
  getGroupDocumentDetails,
  getGroupUserActivityDetails,
  getMemberBankDetails,
  getMemberContactlDetails,
  getMemberEduDetails,
  getMemberEmpDetails,
  getMemberJobReqDetails,
  getMemberPersonalDetails,
  getNewCompanyRegDetails,
  getNewGroupRegDetails,
  saveActivityDetails
} from '../api/admin';
import {
  ADMIN_ALL_COMPANY_REG_DETAILS,
  ADMIN_ALL_GROUP_REG_DETAILS,
  ADMIN_ALL_MEMBER_REG_DETAILS,
  ADMIN_BANK_DETAILS,
  ADMIN_COMPANY_BANK_DETAILS,
  ADMIN_COMPANY_CONTACT_DETAILS,
  ADMIN_COMPANY_DETAILS,
  ADMIN_COMPANY_DOCUMENT_DETAILS,
  ADMIN_CONTACT_DETAILS,
  ADMIN_DOCUMENT_DETAILS,
  ADMIN_EDUCATION_DETAILS,
  ADMIN_EMPLOYEE_DETAILS,
  ADMIN_GROUP_BANK_DETAILS,
  ADMIN_GROUP_CONTACT_DETAILS,
  ADMIN_GROUP_DETAILS,
  ADMIN_GROUP_DOCUMENT_DETAILS,
  ADMIN_GROUP_USER_ACTIVITY_DETAILS,
  ADMIN_JOB_DETAILS,
  ADMIN_NEW_COMPANY_REG_DETAILS,
  ADMIN_NEW_GROUP_REG_DETAILS,
  ADMIN_PERSONAL_DETAILS,
  ENABLE_MEMBERS,
  SAVE_USER_ACTIVITY_DETAILS
} from '../constants/admin';
import { snackActions } from '../utils/SnackBarUtils';

export function * getMemberPersonalDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberPersonalDetails, payload);
    const { data: { personDetails } } = response;
    yield put({
      type: ADMIN_PERSONAL_DETAILS.Success,
      payload: personDetails,
    });
  } catch (e) {
    yield put({
      type: ADMIN_PERSONAL_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin person Details');
  }
}
export function * getMemberContactlDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberContactlDetails, payload);
    const { data: { contactDetails } } = response;
    yield put({
      type: ADMIN_CONTACT_DETAILS.Success,
      payload: contactDetails,
    });
  } catch (e) {
    yield put({
      type: ADMIN_CONTACT_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin contact Details');
  }
}

export function * getDocumentDetailsSaga({ payload }) {
  try {
    const response = yield call(getDocumentDetails, payload);
    const { data: { documentDetailDTO } } = response;
    yield put({
      type: ADMIN_DOCUMENT_DETAILS.Success,
      payload: documentDetailDTO,
    });
  } catch (e) {
    yield put({
      type: ADMIN_DOCUMENT_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin document Details');
  }
}

export function * getMemberEduDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberEduDetails, payload);
    const { data: { eduDetails } } = response;
    yield put({
      type: ADMIN_EDUCATION_DETAILS.Success,
      payload: eduDetails,
    });
  } catch (e) {
    yield put({
      type: ADMIN_EDUCATION_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin education Details');
  }
}

export function * getMemberBankDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberBankDetails, payload);
    const { data: { bankDetails } } = response;
    yield put({
      type: ADMIN_BANK_DETAILS.Success,
      payload: bankDetails,
    });
  } catch (e) {
    yield put({
      type: ADMIN_BANK_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin bank Details');
  }
}

export function * getMemberEmpDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberEmpDetails, payload);
    const { data: { empDetails } } = response;
    yield put({
      type: ADMIN_EMPLOYEE_DETAILS.Success,
      payload: empDetails,
    });
  } catch (e) {
    yield put({
      type: ADMIN_EMPLOYEE_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin employee Details');
  }
}

export function * getMemberJobReqDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberJobReqDetails, payload);
    const { data: { empDetails } } = response;
    yield put({
      type: ADMIN_JOB_DETAILS.Success,
      payload: empDetails,
    });
  } catch (e) {
    yield put({
      type: ADMIN_JOB_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin job Details');
  }
}

export function * getAllGroupRegDetailsSaga({ payload }) {
  try {
    const query = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
    const response = yield call(getAllGroupRegDetails, query);
    const { data: { getAllGroupRegDetails: data } } = response;
    yield put({
      type: ADMIN_ALL_GROUP_REG_DETAILS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ADMIN_ALL_GROUP_REG_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllMembersSaga({ payload }) {
  try {
    const query = Object.keys(payload).map(key => `${key}=${encodeURIComponent(payload[key])}`).join('&');
    const response = yield call(getAllMembers, query);
    const { data } = response;
    yield put({
      type: ADMIN_ALL_MEMBER_REG_DETAILS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ADMIN_ALL_MEMBER_REG_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getGroupDetailsSaga({ payload }) {
  try {
    const response = yield call(getGroupDetails, payload);
    const { data: { groupDetails } } = response;
    yield put({
      type: ADMIN_GROUP_DETAILS.Success,
      payload: groupDetails,
    });
  } catch (e) {
    yield put({
      type: ADMIN_GROUP_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin group Details');
  }
}

export function * getGroupContactDetailsSaga({ payload }) {
  try {
    const response = yield call(getGroupContactDetails, payload);
    const { data: { contactDetails } } = response;
    yield put({
      type: ADMIN_GROUP_CONTACT_DETAILS.Success,
      payload: contactDetails,
    });
  } catch (e) {
    yield put({
      type: ADMIN_GROUP_CONTACT_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin group contact Details');
  }
}

export function * getGroupDocumentDetailsSaga({ payload }) {
  try {
    const response = yield call(getGroupDocumentDetails, payload);
    const { data: { documentDetailDTO } } = response;
    yield put({
      type: ADMIN_GROUP_DOCUMENT_DETAILS.Success,
      payload: documentDetailDTO,
    });
  } catch (e) {
    yield put({
      type: ADMIN_GROUP_DOCUMENT_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin group document Details');
  }
}

export function * getGroupBankDetailsSaga({ payload }) {
  try {
    const response = yield call(getGroupBankDetails, payload);
    const { data: { bankDetails } } = response;
    yield put({
      type: ADMIN_GROUP_BANK_DETAILS.Success,
      payload: bankDetails,
    });
  } catch (e) {
    yield put({
      type: ADMIN_GROUP_BANK_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin group bank Details');
  }
}

export function * getNewGroupRegDetailsSaga({ payload }) {
  try {
    const query = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
    const response = yield call(getNewGroupRegDetails, query);
    const { data: { getNewGroupRegDetails: data } } = response;
    yield put({
      type: ADMIN_ALL_GROUP_REG_DETAILS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ADMIN_ALL_GROUP_REG_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}
export function * getAllCompanyRegDetailsSaga({ payload }) {
  try {
    const query = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
    const response = yield call(getAllCompanyRegDetails, query);
    const { data: { getAllCompanyRegDetails: data } } = response;
    yield put({
      type: ADMIN_ALL_COMPANY_REG_DETAILS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ADMIN_ALL_COMPANY_REG_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getCompanyDetailsSaga({ payload }) {
  try {
    const response = yield call(getCompanyDetails, payload);
    const { data: { companyDetails } } = response;
    yield put({
      type: ADMIN_COMPANY_DETAILS.Success,
      payload: companyDetails,
    });
  } catch (e) {
    yield put({
      type: ADMIN_COMPANY_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin group bank Details');
  }
}

export function * getCompanyContactDetailsSaga({ payload }) {
  try {
    const response = yield call(getCompanyContactDetails, payload);
    const { data: { contactDetails } } = response;
    yield put({
      type: ADMIN_COMPANY_CONTACT_DETAILS.Success,
      payload: contactDetails,
    });
  } catch (e) {
    yield put({
      type: ADMIN_COMPANY_CONTACT_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin company contact Details');
  }
}

export function * getCompanyDocumentDetailsSaga({ payload }) {
  try {
    const response = yield call(getCompanyDocumentDetails, payload);
    const { data: { documentDetailDTO } } = response;
    yield put({
      type: ADMIN_COMPANY_DOCUMENT_DETAILS.Success,
      payload: documentDetailDTO,
    });
  } catch (e) {
    yield put({
      type: ADMIN_COMPANY_DOCUMENT_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin company Document Details');
  }
}
export function * getCompanyBankDetailsSaga({ payload }) {
  try {
    const response = yield call(getCompanyBankDetails, payload);
    const { data } = response;
    yield put({
      type: ADMIN_COMPANY_BANK_DETAILS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ADMIN_COMPANY_BANK_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the admin company Document Details');
  }
}

export function * getNewCompanyRegDetailsSaga({ payload }) {
  try {
    const query = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
    const response = yield call(getNewCompanyRegDetails, query);
    const { data } = response;
    yield put({
      type: ADMIN_NEW_COMPANY_REG_DETAILS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ADMIN_NEW_COMPANY_REG_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getGroupUserActivityDetailsSaga({ payload }) {
  try {
    const response = yield call(getGroupUserActivityDetails, payload);
    const { data } = response;
    yield put({
      type: ADMIN_GROUP_USER_ACTIVITY_DETAILS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ADMIN_GROUP_USER_ACTIVITY_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the Details');
  }
}

export function * enableMembersSaga({ payload }) {
  try {
    const { filter, data } = payload;
    const response = yield call(enableMembers, data);
    const { data: { responseCode, responseDesc } } = response;
    if (responseCode) {
      snackActions.error(responseDesc);
      yield put({
        type: ENABLE_MEMBERS.Fail,
      });
    } else {
      snackActions.success('Enabled successfully!!');
      yield put({
        type: ENABLE_MEMBERS.Success,
        payload: 'success',
      });
      yield put({
        type: ADMIN_ALL_MEMBER_REG_DETAILS.Request,
        payload: filter,
      });
    }
  } catch (e) {
    snackActions.error('Could not able to save the data');
    yield put({
      type: ENABLE_MEMBERS.Fail,
    });
  }
}

function * saveActivityDetailsSaga({ payload }) {
  try {
    const { data, memberId } = payload;
    const response = yield call(saveActivityDetails, data, memberId);
    const { data: { responseCode } } = response;
    if (responseCode === 0) {
      snackActions.error('Could not able to save the data');
      yield put({
        type: SAVE_USER_ACTIVITY_DETAILS.Fail,
      });
    } else {
      snackActions.success('Added/Updated Activities successfully!!');
      yield put({
        type: SAVE_USER_ACTIVITY_DETAILS.Success,
        payload: 'success',
      });
    }
  } catch (e) {
    snackActions.error('Could not able to save the data');
    yield put({
      type: SAVE_USER_ACTIVITY_DETAILS.Fail,
    });
  }
}

export function * watchAdminSaga() {
  yield takeLatest(ADMIN_PERSONAL_DETAILS.Request, getMemberPersonalDetailsSaga);
  yield takeLatest(ADMIN_CONTACT_DETAILS.Request, getMemberContactlDetailsSaga);
  yield takeLatest(ADMIN_DOCUMENT_DETAILS.Request, getDocumentDetailsSaga);
  yield takeLatest(ADMIN_EDUCATION_DETAILS.Request, getMemberEduDetailsSaga);
  yield takeLatest(ADMIN_BANK_DETAILS.Request, getMemberBankDetailsSaga);
  yield takeLatest(ADMIN_EMPLOYEE_DETAILS.Request, getMemberEmpDetailsSaga);
  yield takeLatest(ADMIN_JOB_DETAILS.Request, getMemberJobReqDetailsSaga);
  yield takeLatest(ADMIN_ALL_GROUP_REG_DETAILS.Request, getAllGroupRegDetailsSaga);
  yield takeLatest(ADMIN_ALL_MEMBER_REG_DETAILS.Request, getAllMembersSaga);
  yield takeLatest(ADMIN_GROUP_DETAILS.Request, getAllGroupRegDetailsSaga);
  yield takeLatest(ADMIN_GROUP_CONTACT_DETAILS.Request, getGroupContactDetailsSaga);
  yield takeLatest(ADMIN_GROUP_DOCUMENT_DETAILS.Request, getGroupDocumentDetailsSaga);
  yield takeLatest(ADMIN_GROUP_BANK_DETAILS.Request, getGroupBankDetailsSaga);
  yield takeLatest(ADMIN_NEW_GROUP_REG_DETAILS.Request, getAllCompanyRegDetailsSaga);
  yield takeLatest(ADMIN_COMPANY_DETAILS.Request, getCompanyDetailsSaga);
  yield takeLatest(ADMIN_COMPANY_CONTACT_DETAILS.Request, getCompanyContactDetailsSaga);
  yield takeLatest(ADMIN_COMPANY_DOCUMENT_DETAILS.Request, getCompanyDocumentDetailsSaga);
  yield takeLatest(ADMIN_COMPANY_BANK_DETAILS.Request, getCompanyBankDetailsSaga);
  yield takeLatest(ADMIN_NEW_COMPANY_REG_DETAILS.Request, getNewCompanyRegDetailsSaga);
  yield takeLatest(ADMIN_GROUP_USER_ACTIVITY_DETAILS.Request, getGroupUserActivityDetailsSaga);
  yield takeLatest(ENABLE_MEMBERS.Request, enableMembersSaga);
  yield takeLatest(SAVE_USER_ACTIVITY_DETAILS.Request, saveActivityDetailsSaga);
}

export default function * adminSaga() {
  yield all([
    watchAdminSaga(),
  ]);
}
