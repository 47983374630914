import { all } from 'redux-saga/effects';
import userSaga from './auth';
import groupSaga from './group';
import commonSaga from './common';
import memberSaga from './member';
import contactSaga from './contact';
import filterSaga from './filter';
import adminSaga from './admin';

export default function * rootSaga() {
  yield all([
    adminSaga(),
    userSaga(),
    groupSaga(),
    commonSaga(),
    memberSaga(),
    contactSaga(),
    filterSaga(),
  ]);
};
