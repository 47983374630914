import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  getAllMaterials,
  getAllActiveMembers,
  getAllContactType,
  getAllDocType,
  getAllGroupActivitiesActive,
  getAllRoleGroupAdmin,
  getBankMaster,
  getBloodGroup,
  getPaidBasisMaster,
  getWorkTimeMaster,
  getMaterialById,
  saveMaterialImages,
  addDescription,
  getDescription,
  getItemNames,
  getBrands,
  getModels,
  getSpecifications,
  deletePriceDetails,
  getMaterialDetails,
  addMaterial,
  getLatestPrice,
  priceRequest,
  priceRequestApprove,
  priceRequestReject,
  getPriceRequests,
  getNotifications,
  deleteNotification
} from '../api/common';
import { loadGrpSubActItem } from '../api/group';
import {
  ACTIVE_MEMBERS,
  ADD_DESCRIPTION,
  ADD_MATERIAL_DETAILS,
  ALL_ROLE_GROUP_ACTIVITIES,
  ALL_ROLE_GROUP_ADMIN,
  BANK_MASTER,
  BLOOD_GROUP,
  CONTACT_TYPE,
  DELETE_NOTIFICATION,
  DELETE_PRICE,
  DOCUMENT_TYPE,
  GET_ALL_MATERIALS,
  GET_DESCRIPTION,
  GET_LATEST_PRICE,
  GET_MATERIAL,
  GET_MATERIAL_DETAILS,
  GET_NOTIFICATIONS,
  GET_PRICE_REQUESTS,
  PAID_BASIC_MASTER,
  PRICE_REQUEST,
  PRICE_REQUEST_APPROVE,
  PRICE_REQUEST_REJECT,
  SAVE_MATERIAL_IMAGES,
  WORK_TIME_MASTER
} from '../constants/common';
import { MATERIAL_ACTIVITIES } from '../constants/global';
import { GET_SUB_ACTIVITIES, GROUP_MATERIAL_SELECTION } from '../constants/group';
import FormUtils from '../utils/formUtils';
import { snackActions } from '../utils/SnackBarUtils';

export function * getAllActiveMembersSaga({ payload }) {
  try {
    const response = yield call(getAllActiveMembers, payload);
    const { data } = response;
    yield put({
      type: ACTIVE_MEMBERS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ACTIVE_MEMBERS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllContactTypeSaga() {
  try {
    const response = yield call(getAllContactType);
    const { data } = response;
    yield put({
      type: CONTACT_TYPE.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: CONTACT_TYPE.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the contact type');
  }
}

export function * getAllDocTypeSaga() {
  try {
    const response = yield call(getAllDocType);
    const { data } = response;
    yield put({
      type: DOCUMENT_TYPE.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: DOCUMENT_TYPE.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the document type');
  }
}

export function * getBankMasterSaga() {
  try {
    const response = yield call(getBankMaster);
    const { data } = response;
    yield put({
      type: BANK_MASTER.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: BANK_MASTER.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the Bank Master data');
  }
}

export function * getBloodGroupSaga() {
  try {
    const response = yield call(getBloodGroup);
    const { data } = response;
    yield put({
      type: BLOOD_GROUP.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: BLOOD_GROUP.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the Blood Group data');
  }
}
export function * getWorkTimeMasterSaga() {
  try {
    const response = yield call(getWorkTimeMaster);
    const { data } = response;
    yield put({
      type: WORK_TIME_MASTER.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: WORK_TIME_MASTER.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getPaidBasisMasterSaga() {
  try {
    const response = yield call(getPaidBasisMaster);
    const { data } = response;
    yield put({
      type: PAID_BASIC_MASTER.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: PAID_BASIC_MASTER.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllRoleGroupAdminSaga() {
  try {
    const response = yield call(getAllRoleGroupAdmin);
    const { data } = response;
    yield put({
      type: ALL_ROLE_GROUP_ADMIN.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ALL_ROLE_GROUP_ADMIN.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllGroupActivitiesActiveSaga() {
  try {
    const response = yield call(getAllGroupActivitiesActive);
    const { data } = response;
    yield put({
      type: ALL_ROLE_GROUP_ACTIVITIES.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ALL_ROLE_GROUP_ACTIVITIES.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllMaterialsSaga({ payload }) {
  try {
    const response = yield call(getAllMaterials, FormUtils.getFilterQuery(payload));
    const { data } = response;
    yield put({
      type: GET_ALL_MATERIALS.Success,
      payload: data,
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: GET_ALL_MATERIALS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getMaterialByIdSaga({ payload }) {
  try {
    const response = yield call(getMaterialById, payload);
    const { data } = response;
    const {
      activityId,
      subActivityId,
      nameId,
      brandId,
      modelId,
    } = data;
    const [subActivities, names, brands, models, specifications] = yield all([
      call(loadGrpSubActItem, activityId),
      call(getItemNames, subActivityId),
      call(getBrands, nameId),
      call(getModels, brandId),
      call(getSpecifications, modelId),
    ]);
    yield put({
      type: GET_SUB_ACTIVITIES.Success,
      payload: subActivities.data,
    });
    yield put({
      type: GET_DESCRIPTION.Success,
      payload: {
        masterType: MATERIAL_ACTIVITIES.MATERIAL_NAME,
        data: names.data,
      },
    });
    yield put({
      type: GET_DESCRIPTION.Success,
      payload: {
        masterType: MATERIAL_ACTIVITIES.MATERIAL_BRAND,
        data: brands.data,
      },
    });
    yield put({
      type: GET_DESCRIPTION.Success,
      payload: {
        masterType: MATERIAL_ACTIVITIES.MATERIAL_MODEL,
        data: models.data,
      },
    });
    yield put({
      type: GET_DESCRIPTION.Success,
      payload: {
        masterType: MATERIAL_ACTIVITIES.MATERIAL_SPEC,
        data: specifications.data,
      },
    });
    yield put({
      type: GET_MATERIAL.Success,
      payload: data,
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: GET_MATERIAL.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getMaterialDetailsSaga({ payload }) {
  try {
    const response = yield call(getMaterialDetails, payload);
    const { data } = response;
    yield put({
      type: GET_MATERIAL_DETAILS.Success,
      payload: data,
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: GET_MATERIAL_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * saveMaterialImagesSaga({ payload }) {
  try {
    const { materialId, attachments } = payload;
    const formData = new FormData();
    formData.append('docTypeId', 24);
    formData.append('materialId', materialId);
    for (let i = 0; i < attachments.length; i++) {
      formData.append('attachments', attachments[i]);
    }
    const response = yield call(saveMaterialImages, formData);
    const { data } = response;
    console.log('images saved---', data);
  } catch (e) {
    console.error(e);
  }
}

export function * addDescriptionSaga({ payload }) {
  try {
    const { masterType, parentId, key } = payload;
    const response = yield call(addDescription, payload);
    const { data } = response;
    yield put({
      type: ADD_DESCRIPTION.Success,
      payload: {
        data,
        masterType,
      },
    });
    yield put({
      type: GET_DESCRIPTION.Request,
      payload: {
        masterType,
        parentId,
      },
    });
    if (key) {
      snackActions.success(`${payload.label} updated successfully`);
    } else {
      switch (masterType) {
      case MATERIAL_ACTIVITIES.MATERIAL_NAME: {
        yield put({
          type: GET_DESCRIPTION.Success,
          payload: {
            masterType: MATERIAL_ACTIVITIES.MATERIAL_BRAND,
            data: [],
          },
        });
        yield put({
          type: GET_DESCRIPTION.Success,
          payload: {
            masterType: MATERIAL_ACTIVITIES.MATERIAL_MODEL,
            data: [],
          },
        });
        yield put({
          type: GET_DESCRIPTION.Success,
          payload: {
            masterType: MATERIAL_ACTIVITIES.MATERIAL_SPEC,
            data: [],
          },
        });
        break;
      }
      case MATERIAL_ACTIVITIES.MATERIAL_BRAND: {
        yield put({
          type: GET_DESCRIPTION.Success,
          payload: {
            masterType: MATERIAL_ACTIVITIES.MATERIAL_MODEL,
            data: [],
          },
        });
        yield put({
          type: GET_DESCRIPTION.Success,
          payload: {
            masterType: MATERIAL_ACTIVITIES.MATERIAL_SPEC,
            data: [],
          },
        });
        break;
      }
      case MATERIAL_ACTIVITIES.MATERIAL_MODEL: {
        yield put({
          type: GET_DESCRIPTION.Success,
          payload: {
            masterType: MATERIAL_ACTIVITIES.MATERIAL_SPEC,
            data: [],
          },
        });
        break;
      }
      default: break;
      }
      snackActions.success(`New ${payload.label} added successfully`);
    }
  } catch (e) {
    yield put({
      type: ADD_DESCRIPTION.Fail,
      payload: e,
    });
    snackActions.error(`Could not able add ${payload.label}`);
  }
}

export function * getDescriptionSaga({ payload }) {
  try {
    const { masterType, parentId } = payload;
    const response = yield call(getDescription, parentId, masterType);
    const { data } = response;
    yield put({
      type: GET_DESCRIPTION.Success,
      payload: {
        masterType,
        data,
      },
    });
  } catch (e) {
    yield put({
      type: GET_DESCRIPTION.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * deletePriceDetailsSaga({ payload }) {
  try {
    const { matId, id } = payload;
    const response = yield call(deletePriceDetails, matId, id);
    const { data } = response;
    yield put({
      type: DELETE_PRICE.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: DELETE_PRICE.Fail,
      payload: e,
    });
    snackActions.error('Could not able to delete price');
  }
}

export function * addMaterialDetailsSaga({ payload }) {
  try {
    const { data, filters } = payload;
    const response = yield call(addMaterial, data);
    const { data: { materialId } } = response;
    snackActions.success(`${materialId} added in Material data`);
    yield put({
      type: ADD_MATERIAL_DETAILS.Success,
      payload: 'success',
    });
    yield put({
      type: GROUP_MATERIAL_SELECTION.Request,
      payload: filters,
    });
  } catch (e) {
    yield put({
      type: ADD_MATERIAL_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to add the Material');
  }
}

export function * priceRequestSaga({ payload }) {
  try {
    const { data, filters } = payload;
    const response = yield call(priceRequest, data);
    const { data: { materialId } } = response;
    snackActions.success(`Price Request placed for ${materialId} successfully!`);
    yield put({
      type: PRICE_REQUEST.Success,
      payload: 'success',
    });
    yield put({
      type: GROUP_MATERIAL_SELECTION.Request,
      payload: filters,
    });
  } catch (e) {
    yield put({
      type: PRICE_REQUEST.Fail,
      payload: e,
    });
    snackActions.error('Could not able to make Price Request');
  }
}

export function * priceRequestApproveSaga({ payload }) {
  try {
    const { data, type, status } = payload;
    const response = yield call(priceRequestApprove, data);
    const { data: { responseCode } } = response;
    if (responseCode === 1) {
      snackActions.success('Price Requests approved!');
      yield put({
        type: PRICE_REQUEST_APPROVE.Success,
        payload: 'success',
      });
      yield put({
        type: GET_PRICE_REQUESTS.Request,
        payload: {
          type,
          status,
        },
      });
    } else {
      yield put({
        type: PRICE_REQUEST_APPROVE.Fail,
      });
      snackActions.error('Could not able to make Price Request approve');
    }
  } catch (e) {
    yield put({
      type: PRICE_REQUEST_APPROVE.Fail,
      payload: e,
    });
    snackActions.error('Could not able to make Price Request approve');
  }
}

export function * priceRequestRejectSaga({ payload }) {
  try {
    const { data, type, status } = payload;
    const response = yield call(priceRequestReject, data);
    const { data: { responseCode } } = response;
    if (responseCode === 1) {
      snackActions.success('Price Requests rejected!');
      yield put({
        type: PRICE_REQUEST_REJECT.Success,
        payload: 'success',
      });
      yield put({
        type: GET_PRICE_REQUESTS.Request,
        payload: {
          type,
          status,
        },
      });
    } else {
      yield put({
        type: PRICE_REQUEST_REJECT.Fail,
      });
      snackActions.error('Could not able to make Price Request reject');
    }
  } catch (e) {
    yield put({
      type: PRICE_REQUEST_REJECT.Fail,
      payload: e,
    });
    snackActions.error('Could not able to make Price Request reject');
  }
}

export function * getLatestPriceSaga({ payload }) {
  try {
    const response = yield call(getLatestPrice, payload);
    const { data } = response;
    yield put({
      type: GET_LATEST_PRICE.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GET_LATEST_PRICE.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getPriceRequestsSaga({ payload }) {
  try {
    const { type, status } = payload;
    const response = yield call(getPriceRequests, type, status);
    const { data } = response;
    yield put({
      type: GET_PRICE_REQUESTS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GET_PRICE_REQUESTS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getNotificationsSaga() {
  try {
    const response = yield call(getNotifications);
    const { data } = response;
    yield put({
      type: GET_NOTIFICATIONS.Success,
      payload: data,
    });
  } finally {
    yield delay(3000);
    // eslint-disable-next-line spaced-comment
    yield put({
      type: GET_NOTIFICATIONS.Request,
    });
  }
}

export function * deleteNotificationSaga({ payload }) {
  try {
    yield call(deleteNotification, payload.id);
  } catch (e) {
    console.log(e);
  }
}

export function * watchCommonSaga() {
  yield takeLatest(ACTIVE_MEMBERS.Request, getAllActiveMembersSaga);
  yield takeLatest(CONTACT_TYPE.Request, getAllContactTypeSaga);
  yield takeLatest(DOCUMENT_TYPE.Request, getAllDocTypeSaga);
  yield takeLatest(BANK_MASTER.Request, getBankMasterSaga);
  yield takeLatest(BLOOD_GROUP.Request, getBloodGroupSaga);
  yield takeLatest(WORK_TIME_MASTER.Request, getWorkTimeMasterSaga);
  yield takeLatest(PAID_BASIC_MASTER.Request, getPaidBasisMasterSaga);
  yield takeLatest(ALL_ROLE_GROUP_ADMIN.Request, getAllRoleGroupAdminSaga);
  yield takeLatest(ALL_ROLE_GROUP_ACTIVITIES.Request, getAllGroupActivitiesActiveSaga);
  yield takeLatest(GET_ALL_MATERIALS.Request, getAllMaterialsSaga);
  yield takeLatest(GET_MATERIAL.Request, getMaterialByIdSaga);
  yield takeLatest(SAVE_MATERIAL_IMAGES.Request, saveMaterialImagesSaga);
  yield takeLatest(ADD_DESCRIPTION.Request, addDescriptionSaga);
  yield takeLatest(GET_DESCRIPTION.Request, getDescriptionSaga);
  yield takeLatest(DELETE_PRICE.Request, deletePriceDetailsSaga);
  yield takeLatest(GET_MATERIAL_DETAILS.Request, getMaterialDetailsSaga);
  yield takeLatest(ADD_MATERIAL_DETAILS.Request, addMaterialDetailsSaga);
  yield takeLatest(GET_LATEST_PRICE.Request, getLatestPriceSaga);
  yield takeLatest(PRICE_REQUEST.Request, priceRequestSaga);
  yield takeLatest(PRICE_REQUEST_APPROVE.Request, priceRequestApproveSaga);
  yield takeLatest(PRICE_REQUEST_REJECT.Request, priceRequestRejectSaga);
  yield takeLatest(GET_PRICE_REQUESTS.Request, getPriceRequestsSaga);
  yield takeLatest(GET_NOTIFICATIONS.Request, getNotificationsSaga);
  yield takeLatest(DELETE_NOTIFICATION.Request, deleteNotificationSaga);
}

export default function * commonSaga() {
  yield all([
    watchCommonSaga(),
  ]);
}
