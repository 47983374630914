import axios from 'axios';
import BASE_API_URL from '../constants/global';

export const getAllActivities = forSelection => {
  return forSelection
    ? axios.get(`${BASE_API_URL}/filters/getAllActivities/${forSelection}`)
    : axios.get(`${BASE_API_URL}/filters/getAllActivities`);
};

export const getAllSubActivities = forSelection => {
  return forSelection
    ? axios.get(`${BASE_API_URL}/filters/getAllSubActivities/${forSelection}`)
    : axios.get(`${BASE_API_URL}/filters/getAllSubActivities`);
};

export const getAllItemNames = forSelection => {
  return forSelection
    ? axios.get(`${BASE_API_URL}/filters/getAllItemNames/${forSelection}`)
    : axios.get(`${BASE_API_URL}/filters/getAllItemNames`);
};

export const getAllBrands = forSelection => {
  return forSelection
    ? axios.get(`${BASE_API_URL}/filters/getAllBrands/${forSelection}`)
    : axios.get(`${BASE_API_URL}/filters/getAllBrands`);
};

export const getAllModels = forSelection => {
  return forSelection
    ? axios.get(`${BASE_API_URL}/filters/getAllModels/${forSelection}`)
    : axios.get(`${BASE_API_URL}/filters/getAllModels`);
};

export const getAllSpecifications = forSelection => {
  return forSelection
    ? axios.get(`${BASE_API_URL}/filters/getAllSpecifications/${forSelection}`)
    : axios.get(`${BASE_API_URL}/filters/getAllSpecifications`);
};

export const getAllMadeIn = forSelection => {
  return forSelection
    ? axios.get(`${BASE_API_URL}/filters/getAllMadeIn/${forSelection}`)
    : axios.get(`${BASE_API_URL}/filters/getAllMadeIn`);
};
