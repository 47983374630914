import { useRoutes } from 'react-router-dom';
import AdminRoute from './AdminRoute';
import CompanyRoute from './CompanyRoute';
import GroupRoute from './GroupRoute';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import MemberRoute from './MemberRoute';
import NewLayoutRoute1 from './NewLayoutRoute1';

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, LoginRoutes, NewLayoutRoute1, AdminRoute, GroupRoute, CompanyRoute, MemberRoute]);
}
