import React from 'react';
import {
  AccountCircle,
  Group,
  Groups,
  People,
  SelectAll,
  ViewList,
  List,
  GroupAdd,
  PlaylistAdd,
  RequestQuoteOutlined
} from '@mui/icons-material';

export const ADMIN_ROOT_PATH = '/admin';
export const GROUP_ROOT_PATH = '/group';
export const COMPANY_ROOT_PATH = '/company';
export const MEMBER_ROOT_PATH = '/member';

export default {
  admin: {
    pages: [
      {
        id: 100,
        label: 'Members',
        path: `${ADMIN_ROOT_PATH}/members`,
        icon: <People />,
      },
      {
        id: 110,
        label: 'Groups',
        path: '',
        icon: <Group />,
        children: [
          {
            label: 'Group List',
            path: `${ADMIN_ROOT_PATH}/group/list`,
            icon: <List />,
          },
          {
            label: 'New Groups',
            path: `${ADMIN_ROOT_PATH}/group/new-list`,
            icon: <GroupAdd />,
          },
          {
            label: 'Group Activity',
            path: `${ADMIN_ROOT_PATH}/group/activity`,
            icon: <PlaylistAdd />,
          },
        ],
      },
      {
        id: 120,
        label: 'Company',
        path: '',
        icon: <Group />,
        children: [
          {
            label: 'Company List',
            path: `${ADMIN_ROOT_PATH}/company/list`,
            icon: <List />,
          },
          {
            label: 'New Companies',
            path: `${ADMIN_ROOT_PATH}/company/new-list`,
            icon: <GroupAdd />,
          },
          {
            label: 'Company Activity',
            path: `${ADMIN_ROOT_PATH}/company/activity`,
            icon: <PlaylistAdd />,
          },
        ],
      },
      {
        id: 130,
        label: 'Material Master',
        path: `${ADMIN_ROOT_PATH}/material-master`,
        icon: <List />,
      },
    ],
  },
  group: {
    pages: [
      {
        id: 1,
        label: 'Teams Contact',
        path: `${GROUP_ROOT_PATH}/teams-contact`,
        icon: <Groups />,
      },
      {
        id: 2,
        label: 'Material Data',
        path: `${GROUP_ROOT_PATH}/material-data`,
        icon: <ViewList />,
      },
      {
        id: 3,
        label: 'Material Selection',
        path: `${GROUP_ROOT_PATH}/material-selection`,
        icon: <SelectAll />,
      },
      {
        id: 4,
        label: 'Price Request',
        path: `${GROUP_ROOT_PATH}/material-price-request`,
        icon: <RequestQuoteOutlined />,
      },
    ],
    user: [
      {
        label: 'Profile',
        path: `${GROUP_ROOT_PATH}/profile`,
        icon: <AccountCircle />,
      },
      {
        label: 'Account',
        path: `${GROUP_ROOT_PATH}/account`,
        icon: <AccountCircle />,
      },
    ],
  },
  company: {
    pages: [
      {
        id: 1,
        label: 'Teams Contact',
        path: `${COMPANY_ROOT_PATH}/teams-contact`,
        icon: <Groups />,
      },
      {
        id: 2,
        label: 'Material Data',
        path: `${COMPANY_ROOT_PATH}/material-data`,
        icon: <ViewList />,
      },
    ],
    user: [
      {
        label: 'Profile',
        path: `${COMPANY_ROOT_PATH}/profile`,
        icon: <AccountCircle />,
      },
      {
        label: 'Account',
        path: `${COMPANY_ROOT_PATH}/account`,
        icon: <AccountCircle />,
      },
    ],
  },
  member: {
    user: [
      {
        label: 'Profile',
        path: `${MEMBER_ROOT_PATH}/profile`,
        icon: <AccountCircle />,
      },
      {
        label: 'Account',
        path: `${MEMBER_ROOT_PATH}/account`,
        icon: <AccountCircle />,
      },
    ],
  },
};
