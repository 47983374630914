import {
  DELETE_EDUCATION_DETAILS,
  DELETE_EMPLOYMENT_DETAILS,
  DELETE_EMPLOYMENT_WORK_DETAILS,
  DELETE_JOB_DETAILS,
  DELETE_JOB_WORK_DETAILS,
  GET_EMPLOYMENT_DETAILS,
  GET_JOB_DETAILS,
  GET_USER_DETAILS,
  PERSONAL_DETAILS,
  RESET_SAVE_EDUCATION_DETAILS,
  RESET_SAVE_EMPLOYMENT_DETAILS,
  RESET_SAVE_JOB_DETAILS,
  SAVE_EDUCATION_DETAILS,
  SAVE_EMPLOYMENT_DETAILS,
  SAVE_JOB_DETAILS,
  SAVE_PERSONAL_DETAILS,
  SAVE_USER_DETAILS
} from '../constants/contact';
import {
  GET_DOCUMENT_DETAILS,
  MEMBER_BANK_DETAILS,
  MEMBER_CONTACT_DETAILS,
  MEMBER_EDUCATION_DETAILS,
  MEMBER_EMPLOYEE_DETAILS,
  MEMBER_JOB_DETAILS,
  MEMBER_REGISTRATION_DETAILS
} from '../constants/member';

const dataObjArray = {
  fetching: false,
  data: [],
};

const dataObj = {
  fetching: false,
  data: {},
};

const saveObj = {
  saving: false,
  data: null,
};

export const initialState = {
  personDetails: dataObj,
  contactDetails: dataObj,
  savePersonDetails: saveObj,
  getDocumentDetails: dataObj,
  educationDetails: dataObjArray,
  saveEducationDetails: saveObj,
  bankDetails: dataObjArray,
  employmentDetails: dataObjArray,
  saveEmploymentDetails: saveObj,
  jobRequestDetails: dataObjArray,
  saveJobRequestDetails: saveObj,
  registrationDetails: dataObjArray,
  userDetails: dataObjArray,
  saveUserDetails: saveObj,
};

const contactReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PERSONAL_DETAILS.Request: {
    return {
      ...state,
      personDetails: {
        fetching: true,
        data: {},
      },
    };
  }
  case PERSONAL_DETAILS.Success: {
    return {
      ...state,
      personDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case PERSONAL_DETAILS.Fail: {
    return {
      ...state,
      personDetails: dataObj,
    };
  }
  case MEMBER_CONTACT_DETAILS.Request: {
    return {
      ...state,
      contactDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_CONTACT_DETAILS.Success: {
    return {
      ...state,
      contactDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_CONTACT_DETAILS.Fail: {
    return {
      ...state,
      contactDetails: dataObj,
    };
  }
  case SAVE_PERSONAL_DETAILS.Request: {
    return {
      ...state,
      savePersonDetails: {
        saving: true,
        data: null,
      },
    };
  }
  case SAVE_PERSONAL_DETAILS.Success: {
    return {
      ...state,
      savePersonDetails: {
        saving: false,
        data: payload,
      },
    };
  }
  case SAVE_PERSONAL_DETAILS.Fail: {
    return {
      ...state,
      savePersonDetails: saveObj,
    };
  }
  case GET_DOCUMENT_DETAILS.Request: {
    return {
      ...state,
      getDocumentDetails: {
        fetching: true,
      },
    };
  }
  case GET_DOCUMENT_DETAILS.Success: {
    return {
      ...state,
      getDocumentDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_DOCUMENT_DETAILS.Fail: {
    return {
      ...state,
      getDocumentDetails: dataObj,
    };
  }
  case MEMBER_EDUCATION_DETAILS.Request: {
    return {
      ...state,
      educationDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_EDUCATION_DETAILS.Success: {
    return {
      ...state,
      educationDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_EDUCATION_DETAILS.Fail: {
    return {
      ...state,
      educationDetails: dataObjArray,
    };
  }
  case MEMBER_BANK_DETAILS.Request: {
    return {
      ...state,
      bankDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_BANK_DETAILS.Success: {
    return {
      ...state,
      bankDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_BANK_DETAILS.Fail: {
    return {
      ...state,
      bankDetails: dataObj,
    };
  }
  case MEMBER_EMPLOYEE_DETAILS.Request: {
    return {
      ...state,
      bankDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_EMPLOYEE_DETAILS.Success: {
    return {
      ...state,
      bankDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_EMPLOYEE_DETAILS.Fail: {
    return {
      ...state,
      bankDetails: dataObj,
    };
  }
  case MEMBER_JOB_DETAILS.Request: {
    return {
      ...state,
      jobDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_JOB_DETAILS.Success: {
    return {
      ...state,
      jobDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_JOB_DETAILS.Fail: {
    return {
      ...state,
      jobDetails: dataObj,
    };
  }
  case MEMBER_REGISTRATION_DETAILS.Request: {
    return {
      ...state,
      registrationDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_REGISTRATION_DETAILS.Success: {
    return {
      ...state,
      registrationDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_REGISTRATION_DETAILS.Fail: {
    return {
      ...state,
      registrationDetails: dataObj,
    };
  }
  case SAVE_EDUCATION_DETAILS.Request: {
    return {
      ...state,
      saveEducationDetails: {
        ...initialState.saveEducationDetails,
        saving: true,
      },
    };
  }
  case SAVE_EDUCATION_DETAILS.Success: {
    return {
      ...state,
      saveEducationDetails: {
        ...initialState.saveEducationDetails,
        saving: false,
        data: payload,
      },
    };
  }
  case SAVE_EDUCATION_DETAILS.Fail: {
    return {
      ...state,
      saveEducationDetails: {
        ...initialState.saveEducationDetails,
        hasErrors: true,
      },
    };
  }
  case RESET_SAVE_EDUCATION_DETAILS: {
    return {
      ...state,
      saveEducationDetails: initialState.saveEducationDetails,
    };
  }
  case DELETE_EDUCATION_DETAILS.Success: {
    return {
      ...state,
    };
  }
  case GET_EMPLOYMENT_DETAILS.Request: {
    return {
      ...state,
      employmentDetails: {
        fetching: true,
      },
    };
  }
  case GET_EMPLOYMENT_DETAILS.Success: {
    return {
      ...state,
      employmentDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_EMPLOYMENT_DETAILS.Fail: {
    return {
      ...state,
      employmentDetails: dataObjArray,
    };
  }
  case SAVE_EMPLOYMENT_DETAILS.Request: {
    return {
      ...state,
      saveEmploymentDetails: {
        ...saveObj,
        saving: true,
      },
    };
  }
  case SAVE_EMPLOYMENT_DETAILS.Success: {
    return {
      ...state,
      saveEmploymentDetails: {
        saving: false,
        data: payload,
      },
    };
  }
  case SAVE_EMPLOYMENT_DETAILS.Fail: {
    return {
      ...state,
      saveEmploymentDetails: {
        ...saveObj,
        hasErrors: true,
      },
    };
  }
  case RESET_SAVE_EMPLOYMENT_DETAILS: {
    return {
      ...state,
      saveEmploymentDetails: saveObj,
    };
  }
  case DELETE_EMPLOYMENT_DETAILS.Success:
  case DELETE_EMPLOYMENT_WORK_DETAILS.Success: {
    return {
      ...state,
    };
  }
  case GET_JOB_DETAILS.Request: {
    return {
      ...state,
      jobRequestDetails: {
        fetching: true,
      },
    };
  }
  case GET_JOB_DETAILS.Success: {
    return {
      ...state,
      jobRequestDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_JOB_DETAILS.Fail: {
    return {
      ...state,
      jobRequestDetails: dataObj,
    };
  }
  case SAVE_JOB_DETAILS.Request: {
    return {
      ...state,
      saveJobRequestDetails: {
        ...saveObj,
        saving: true,
      },
    };
  }
  case SAVE_JOB_DETAILS.Success: {
    return {
      ...state,
      saveJobRequestDetails: {
        saving: false,
        data: payload,
      },
    };
  }
  case SAVE_JOB_DETAILS.Fail: {
    return {
      ...state,
      saveJobRequestDetails: {
        ...saveObj,
        hasErrors: true,
      },
    };
  }
  case RESET_SAVE_JOB_DETAILS: {
    return {
      ...state,
      saveJobRequestDetails: saveObj,
    };
  }
  case DELETE_JOB_DETAILS.Success:
  case DELETE_JOB_WORK_DETAILS.Success: {
    return {
      ...state,
    };
  }
  case GET_USER_DETAILS.Request: {
    return {
      ...state,
      userDetails: {
        fetching: true,
        data: {},
      },
    };
  }
  case GET_USER_DETAILS.Success: {
    return {
      ...state,
      userDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_USER_DETAILS.Fail: {
    return {
      ...state,
      userDetails: dataObj,
    };
  }
  case SAVE_USER_DETAILS.Request: {
    return {
      ...state,
      saveUserDetails: {
        saving: true,
        data: payload,
      },
    };
  }
  case SAVE_USER_DETAILS.Success: {
    return {
      ...state,
      saveUserDetails: {
        saving: false,
        data: payload,
      },
    };
  }
  case SAVE_USER_DETAILS.Fail: {
    return {
      ...state,
      saveUserDetails: {
        ...saveObj,
        hasErrors: true,
      },
    };
  }
  default: return state;
  }
};
export default contactReducer;
