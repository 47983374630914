import { lazy, React } from 'react';
import Loadable from './LazyComponents/Loadable';
import ProtectedLayout from '../layout/ProtectedLayout';

const MaterialSelection = Loadable(lazy(() => import('../pages/Material/Selection')));
const TeamsContact = Loadable(lazy(() => import('../pages/TeamsContact')));
const MaterialData = Loadable(lazy(() => import('../pages/Material/Data')));
const ContactDetails = Loadable(lazy(() => import('../pages/ContactDetails')));
const GroupDetails = Loadable(lazy(() => import('../pages/ContactDetails/Group')));
const Contact = Loadable(lazy(() => import('../pages/ContactDetails/Contact')));
const Bank = Loadable(lazy(() => import('../pages/ContactDetails/Bank')));
const Document = Loadable(lazy(() => import('../pages/ContactDetails/Document')));
const Profile = Loadable(lazy(() => import('../pages/ContactDetails/Profile')));
const MaterialRequest = Loadable(lazy(() => import('../pages/MaterialRequest')));
const SentReceived = Loadable(lazy(() => import('../pages/MaterialRequest/sentReceived')));
const RequestSent = Loadable(lazy(() => import('../pages/MaterialRequest/Pending/Sent')));
const RequestReceived = Loadable(lazy(() => import('../pages/MaterialRequest/Pending/Received')));
const ApprovalSent = Loadable(lazy(() => import('../pages/MaterialRequest/Approved/Sent')));
const ApprovalReceived = Loadable(lazy(() => import('../pages/MaterialRequest/Approved/Received')));
const RejectSent = Loadable(lazy(() => import('../pages/MaterialRequest/Reject/Sent')));
const RejectReceived = Loadable(lazy(() => import('../pages/MaterialRequest/Reject/Received')));

export const accountDetails = [
  {
    path: 'contact',
    element: <Contact />,
  },
  {
    path: 'bank',
    element: <Bank />,
  },
  {
    path: 'documents',
    element: <Document />,
  },
];

export default {
  element: <ProtectedLayout />,
  children: [
    {
      path: 'account',
      element: <ContactDetails />,
      children: [
        {
          path: '',
          element: <GroupDetails />,
        },
        {
          path: 'details',
          element: <GroupDetails />,
        },
        ...accountDetails,
      ],
    },
    {
      path: 'teams-contact',
      element: <TeamsContact />,
    },
    {
      path: 'material-data',
      element: <MaterialData />,
    },
    {
      path: 'material-selection',
      element: <MaterialSelection />,
    },
    {
      path: 'material-price-request',
      element: <MaterialRequest />,
      children: [
        {
          path: '',
          element: <SentReceived path="request"/>,
          children: [
            {
              path: '',
              element: <RequestSent />,
            },
          ],
        },
        {
          path: 'request',
          element: <SentReceived />,
          children: [
            {
              path: '',
              element: <RequestSent />,
            },
            {
              path: 'sent',
              element: <RequestSent />,
            },
            {
              path: 'received',
              element: <RequestReceived />,
            },
          ],
        },
        {
          path: 'approval',
          element: <SentReceived subPath="approval"/>,
          children: [
            {
              path: '',
              element: <ApprovalSent />,
            },
            {
              path: 'sent',
              element: <ApprovalSent />,
            },
            {
              path: 'received',
              element: <ApprovalReceived />,
            },
          ],
        },
        {
          path: 'reject',
          element: <SentReceived subPath="reject"/>,
          children: [
            {
              path: '',
              element: <RejectSent />,
            },
            {
              path: 'sent',
              element: <RejectSent />,
            },
            {
              path: 'received',
              element: <RejectReceived />,
            },
          ],
        },
      ],
    },
    {
      path: 'profile',
      element: <Profile />,
    },
  ],
};
