import axios from 'axios';
import BASE_API_URL from '../constants/global';

export const getMemberContactlDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getMemberContactlDetails?userId=${id}`);
export const getMemberPersonalDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getMemberPersonalDetails?userId=${id}`);
export const getDocumentDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getDocumentDetails?userId=${id}`);
export const getMemberEduDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getMemberEduDetails?userId=${id}`);
export const getMemberBankDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getMemberBankDetails?userId=${id}`);
export const getMemberEmpDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getMemberEmpDetails?userId=${id}`);
export const getMemberJobReqDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getMemberJobReqDetails?userId=${id}`);
export const getAllGroupRegDetails = query => axios.get(`${BASE_API_URL}/admin/userProfile/getAllGroupRegDetails?${query}`);
export const getAllMembers = query => axios.get(`${BASE_API_URL}/admin/userProfile/getAllMembers?${query || ''}`);
export const getGroupDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getGroupDetails?groupId=${id}`);
export const getGroupContactDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getGroupContactDetails?userId=${id}`);
export const getGroupDocumentDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getGroupDocumentDetails?userId=${id}`);
export const getGroupBankDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getGroupBankDetails?userId=${id}`);
export const getNewGroupRegDetails = query => axios.get(`${BASE_API_URL}/admin/userProfile/getNewGroupRegDetails?${query}`);
export const getAllCompanyRegDetails = query => axios.get(`${BASE_API_URL}/admin/userProfile/getAllCompanyRegDetails?${query}`);
export const getCompanyDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getCompanyDetails?groupId=${id}`);
export const getCompanyContactDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getCompanyContactDetails?userId=${id}`);
export const getCompanyDocumentDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getCompanyDocumentDetails?userId=${id}`);
export const getCompanyBankDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getCompanyBankDetails?userId=${id}`);
export const getNewCompanyRegDetails = query => axios.get(`${BASE_API_URL}/admin/userProfile/getNewCompanyRegDetails?${query}`);
export const getGroupUserActivityDetails = id => axios.get(`${BASE_API_URL}/admin/userProfile/getUserActivityDetails/${id}`);

export const enableMembers = data => axios.post(`${BASE_API_URL}/admin/userProfile/enableMembers`, data);
export const saveActivityDetails = (data, memberId) => axios.post(`${BASE_API_URL}/admin/userProfile/saveActivityDetails/${memberId}`, data);
