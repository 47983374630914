import {
  ADMIN_ROOT_PATH,
  COMPANY_ROOT_PATH,
  GROUP_ROOT_PATH,
  MEMBER_ROOT_PATH
} from '../constants/menu';
import ROLES from '../constants/roles';

export const PathUtils = {
  getPath({ hasAccessToDashboard, roles = [] }) {
    let path = '';
    if (roles.includes(ROLES.ADMIN)) {
      path = '/admin/members';
    } else if (roles.includes(ROLES.GROUP)) {
      path = '/group/account';
    } else if (roles.includes(ROLES.COMPANY)) {
      path = '/company/account';
    } else {
      if (hasAccessToDashboard) {
        path = '/member/dashboard';
      } else {
        path = '/member/account';
      }
    }
    return path;
  },
  hasCorrectPath({ roles }, currentPath = '') {
    let path = null;
    if (roles.includes(ROLES.ADMIN)) {
      path = ADMIN_ROOT_PATH;
    } else if (roles.includes(ROLES.GROUP)) {
      path = GROUP_ROOT_PATH;
    } else if (roles.includes(ROLES.COMPANY)) {
      path = COMPANY_ROOT_PATH;
    } else if (roles.includes(ROLES.MEMBER)) {
      path = MEMBER_ROOT_PATH;
    }
    return currentPath.startsWith(path);
  },
  getMenuName({ roles }) {
    let menuName = null;
    if (roles.includes(ROLES.ADMIN)) {
      menuName = ADMIN_ROOT_PATH.replace('/', '');
    } else if (roles.includes(ROLES.GROUP)) {
      menuName = GROUP_ROOT_PATH.replace('/', '');
    } else if (roles.includes(ROLES.COMPANY)) {
      menuName = COMPANY_ROOT_PATH.replace('/', '');
    } else if (roles.includes(ROLES.MEMBER)) {
      menuName = MEMBER_ROOT_PATH.replace('/', '');
    }
    return menuName;
  },
  isGroupUser({ roles }) {
    return roles.includes(ROLES.GROUP);
  },
};

export default PathUtils;
