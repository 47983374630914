/* eslint-disable react/prop-types */
import { createContext, React, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import PathUtils from '../utils/pathUtils';
import { setAuthenticateToken } from '../api/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null);
  const navigate = useNavigate();

  const login = async data => {
    setUser(data);
    const { token } = data;
    setAuthenticateToken(token);
    navigate(PathUtils.getPath(data), { replace: true });
  };

  const logout = () => {
    setUser(null);
    navigate('/', { replace: true });
    location.reload();
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
