import axios from 'axios';
import BASE_API_URL from '../constants/global';

export const getPersonalDetails = id => id ? axios.get(`${BASE_API_URL}/user/contact/getPersonalDetails/${id}`) : axios.get(`${BASE_API_URL}/user/contact/getPersonalDetails`);
export const savePersonalDetails = (id, data) => {
  return id ? axios.post(`${BASE_API_URL}/user/contact/savePersonalDetails/${id}`, data) : axios.post(`${BASE_API_URL}/user/contact/savePersonalDetails`, data);
};

export const getContactDetails = id => id ? axios.get(`${BASE_API_URL}/user/contact/getContactDetails/${id}`) : axios.get(`${BASE_API_URL}/user/contact/getContactDetails`);
export const getBankDetails = id => id ? axios.get(`${BASE_API_URL}/user/contact/getBankDetails/${id}`) : axios.get(`${BASE_API_URL}/user/contact/getBankDetails`);
export const getDocumentDetails = id => id ? axios.get(`${BASE_API_URL}/user/contact/getDocumentDetails/${id}`) : axios.get(`${BASE_API_URL}/user/contact/getDocumentDetails`);
export const getEducationDetails = id => id ? axios.get(`${BASE_API_URL}/user/contact/getEducationDetails/${id}`) : axios.get(`${BASE_API_URL}/user/contact/getEducationDetails`);

export const saveEducationDetails = (data, userId) => {
  return userId
    ? axios.post(`${BASE_API_URL}/user/contact/saveEducationDetails/${userId}`, data)
    : axios.post(`${BASE_API_URL}/user/contact/saveEducationDetails`, data);
};
export const deleteEducationDetails = id => axios.delete(`${BASE_API_URL}/user/contact/deleteEducationDetails/${id}`);
export const deleteEducationDocument = id => axios.delete(`${BASE_API_URL}/user/contact/deleteEducationDocument/${id}`);

export const getEmploymentDetails = userId => userId ? axios.get(`${BASE_API_URL}/user/contact/getEmploymentDetails/${userId}`) : axios.get(`${BASE_API_URL}/user/contact/getEmploymentDetails`);
export const saveEmploymentDetails = (data, userId) => {
  return userId
    ? axios.post(`${BASE_API_URL}/user/contact/saveEmploymentDetails/${userId}`, data)
    : axios.post(`${BASE_API_URL}/user/contact/saveEmploymentDetails`, data);
};
export const deleteEmploymentDetails = id => axios.delete(`${BASE_API_URL}/user/contact/deleteEmploymentDetails/${id}`);
export const deleteEmploymentWorkDetails = id => axios.delete(`${BASE_API_URL}/user/contact/deleteEmploymentWorkDetails/${id}`);

export const getJobRequestDetails = userId => userId ? axios.get(`${BASE_API_URL}/user/contact/getJobRequestDetails/${userId}`) : axios.get(`${BASE_API_URL}/user/contact/getJobRequestDetails`);
export const saveJobRequestDetails = (data, userId) => {
  return userId
    ? axios.post(`${BASE_API_URL}/user/contact/saveJobRequestDetails/${userId}`, data)
    : axios.post(`${BASE_API_URL}/user/contact/saveJobRequestDetails`, data);
};
export const deleteJobRequestDetails = id => axios.delete(`${BASE_API_URL}/user/contact/deleteJobRequestDetails/${id}`);
export const deleteJobRequestWorkDetails = id => axios.delete(`${BASE_API_URL}/user/contact/deleteJobRequestWorkDetails/${id}`);

export const getUserDetails = userId => userId ? axios.get(`${BASE_API_URL}/user/contact/getUserDetails/${userId}`) : axios.get(`${BASE_API_URL}/user/contact/getUserDetails`);
export const saveUserDetails = (data, userId) => {
  return userId
    ? axios.post(`${BASE_API_URL}/user/contact/saveUserDetails/${userId}`, data)
    : axios.post(`${BASE_API_URL}/user/contact/saveUserDetails`, data);
};
