import { ActionType, RequestActionType } from '../actions/utils';

export const GROUP_MATERIAL = RequestActionType('GROUP_MATERIAL');

export const GROUP_MATERIAL_SELECTION = RequestActionType('GROUP_MATERIAL_SELECTION');

export const GROUP_TEAMS_CONTACT = RequestActionType('GROUP_TEAMS_CONTACT');

export const GROUP_ADMIN_ROLES = RequestActionType('GROUP_ADMIN_ROLES');

export const GROUP_TEAM_MEMBER = RequestActionType('GROUP_TEAM_MEMBER');

export const SAVE_GROUP_TEAM_MEMBER = RequestActionType('SAVE_GROUP_TEAM_MEMBER');

export const RESET_SAVE_GROUP_TEAM_MEMBER = ActionType('RESET_SAVE_GROUP_TEAM_MEMBER');

export const GET_SUB_ACTIVITIES = RequestActionType('GET_SUB_ACTIVITIES');
export const GET_ACTIVITIES = RequestActionType('GET_ACTIVITIES');
export const GET_SUB_ACTIVITY_NAMES = RequestActionType('GET_SUB_ACTIVITY_NAMES');
export const GET_SPECIFICATIONS = RequestActionType('GET_SPECIFICATIONS');
export const GET_BRANDS = RequestActionType('GET_BRANDS');
export const GET_COUNTRIES = RequestActionType('GET_COUNTRIES');
export const GET_PACKAGE = RequestActionType('GET_PACKAGE');
export const GET_UNITS = RequestActionType('GET_UNITS');

export const ADD_SUB_ACTIVITY_NAMES = RequestActionType('ADD_SUB_ACTIVITY_NAMES');
export const ADD_SPECIFICATIONS = RequestActionType('ADD_SPECIFICATIONS');
export const ADD_BRANDS = RequestActionType('ADD_BRANDS');
export const ADD_PACKAGE = RequestActionType('ADD_PACKAGE');
export const ADD_UNITS = RequestActionType('ADD_UNITS');
export const RESET_ADD_SUB_ACTIVITY_NAMES = ActionType('RESET_ADD_SUB_ACTIVITY_NAMES');
export const RESET_ADD_BRANDS = ActionType('RESET_ADD_BRANDS');
export const RESET_ADD_SPECIFICATIONS = ActionType('RESET_ADD_SPECIFICATIONS');
export const RESET_ADD_PACKAGE = ActionType('RESET_ADD_PACKAGE');
export const RESET_ADD_UNITS = ActionType('RESET_ADD_UNITS');

export const GROUP_CONTACT_DETAILS = RequestActionType('GROUP_CONTACT_DETAILS');
export const GROUP_DOCUMENT_DETAILS = RequestActionType('GROUP_DOCUMENT_DETAILS');
export const GROUP_BANK_DETAILS = RequestActionType('GROUP_BANK_DETAILS');

export const SAVE_MATERIAL_MASTER = RequestActionType('SAVE_MATERIAL_MASTER');
export const RESET_SAVE_MATERIAL_MASTER = ActionType('RESET_SAVE_MATERIAL_MASTER');

export const GET_GROUP_DETAILS = RequestActionType('GET_GROUP_DETAILS');
export const SAVE_GROUP_DETAILS = RequestActionType('SAVE_GROUP_DETAILS');
export const SAVE_DOCUMENT_DETAILS = RequestActionType('SAVE_DOCUMENT_DETAILS');
export const RESET_SAVE_DOCUMENT_DETAILS = ActionType('RESET_SAVE_DOCUMENT_DETAILS');
export const SAVE_CONTACT_DETAILS = RequestActionType('SAVE_CONTACT_DETAILS');
export const RESET_SAVE_CONTACT_DETAILS = ActionType('RESET_SAVE_CONTACT_DETAILS');
export const SAVE_BANK_DETAILS = RequestActionType('SAVE_BANK_DETAILS');
export const RESET_SAVE_BANK_DETAILS = ActionType('RESET_SAVE_BANK_DETAILS');

export const DELETE_CONTACT_DETAILS = RequestActionType('DELETE_CONTACT_DETAILS');
export const DELETE_BANK_DETAILS = RequestActionType('DELETE_BANK_DETAILS');
export const DELETE_DOCUMENT_DETAILS = RequestActionType('DELETE_DOCUMENT_DETAILS');

export const DELETE_DOCUMENT = RequestActionType('DELETE_DOCUMENT');
