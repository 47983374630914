/* eslint-disable react/prop-types */
import React from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
const ResponsiveMenuIcon = ({
  handleToggle,
  ...other
}) => {
  return (
    <IconButton
      color="inherit"
      aria-label="open menu"
      edge="start"
      onClick={handleToggle}
      {...other}
    >
      <MenuIcon />
    </IconButton>
  );
};

export default ResponsiveMenuIcon;
