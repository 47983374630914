import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getAllDocType } from '../api/common';
import { getEducationDetails } from '../api/contact';
import {
  getDocumentDetails,
  getMemberBankDetails,
  getMemberContactlDetails,
  getMemberEmpDetails,
  getMemberJobReqDetails,
  getMemberPersonalDetails,
  getMemberRegistrationDetails,
  saveMemberPersonalDetails
} from '../api/member';
import { DOCUMENT_TYPE } from '../constants/common';
import {
  GET_DOCUMENT_DETAILS,
  MEMBER_BANK_DETAILS,
  MEMBER_CONTACT_DETAILS,
  MEMBER_EDUCATION_DETAILS,
  MEMBER_EMPLOYEE_DETAILS,
  MEMBER_JOB_DETAILS,
  MEMBER_PERSONAL_DETAILS,
  MEMBER_REGISTRATION_DETAILS,
  SAVE_MEMBER_PERSONAL_DETAILS
} from '../constants/member';
import { snackActions } from '../utils/SnackBarUtils';

export function * getMemberPersonalDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberPersonalDetails, payload);
    const { data: { personDetails } } = response;
    yield put({
      type: MEMBER_PERSONAL_DETAILS.Success,
      payload: personDetails,
    });
  } catch (e) {
    yield put({
      type: MEMBER_PERSONAL_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member personal details');
  }
}

export function * getMemberContactlDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberContactlDetails, payload);
    const { data: { contactDetails } } = response;
    yield put({
      type: MEMBER_CONTACT_DETAILS.Success,
      payload: contactDetails,
    });
  } catch (e) {
    yield put({
      type: MEMBER_CONTACT_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member contact Details');
  }
}

export function * saveMemberPersonalDetailsSaga({ payload }) {
  try {
    const response = yield call(saveMemberPersonalDetails, payload);
    const { data } = response;
    yield put({
      type: SAVE_MEMBER_PERSONAL_DETAILS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: SAVE_MEMBER_PERSONAL_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the save member personal Details');
  }
}
export function * getDocumentDetailsSaga({ payload }) {
  try {
    const response = yield call(getDocumentDetails, payload);
    const { data: { documentDetailDTO } } = response;
    yield put({
      type: GET_DOCUMENT_DETAILS.Success,
      payload: documentDetailDTO,
    });
  } catch (e) {
    yield put({
      type: GET_DOCUMENT_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member document Details');
  }
}

export function * getMemberEduDetailsSaga({ payload }) {
  try {
    const [response, docTypesRes] = yield all([call(getEducationDetails, payload), call(getAllDocType)]);
    const { data: eduDetails } = response;
    yield put({
      type: MEMBER_EDUCATION_DETAILS.Success,
      payload: eduDetails,
    });
    const { data } = docTypesRes;
    yield put({
      type: DOCUMENT_TYPE.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: MEMBER_EDUCATION_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member education Details');
  }
}

export function * getMemberBankDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberBankDetails, payload);
    const { data: { bankDetails } } = response;
    yield put({
      type: MEMBER_BANK_DETAILS.Success,
      payload: bankDetails,
    });
  } catch (e) {
    yield put({
      type: MEMBER_BANK_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member bank Details');
  }
}

export function * getMemberEmpDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberEmpDetails, payload);
    const { data: { empDetails } } = response;
    yield put({
      type: MEMBER_EMPLOYEE_DETAILS.Success,
      payload: empDetails,
    });
  } catch (e) {
    yield put({
      type: MEMBER_EMPLOYEE_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member employee Details');
  }
}

export function * getMemberJobReqDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberJobReqDetails, payload);
    const { data: { empDetails } } = response;
    yield put({
      type: MEMBER_JOB_DETAILS.Success,
      payload: empDetails,
    });
  } catch (e) {
    yield put({
      type: MEMBER_JOB_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member job Details');
  }
}

export function * getMemberRegistrationDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberRegistrationDetails, payload);
    const { data: { memberRegistrationDetailDTO } } = response;
    yield put({
      type: MEMBER_JOB_DETAILS.Success,
      payload: memberRegistrationDetailDTO,
    });
  } catch (e) {
    yield put({
      type: MEMBER_JOB_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member registration Details');
  }
}

export function * watchMemberSaga() {
  yield takeLatest(MEMBER_PERSONAL_DETAILS.Request, getMemberPersonalDetailsSaga);
  yield takeLatest(MEMBER_CONTACT_DETAILS.Request, getMemberContactlDetailsSaga);
  yield takeLatest(SAVE_MEMBER_PERSONAL_DETAILS.Request, saveMemberPersonalDetailsSaga);
  yield takeLatest(GET_DOCUMENT_DETAILS.Request, getDocumentDetailsSaga);
  yield takeLatest(MEMBER_BANK_DETAILS.Request, getMemberBankDetailsSaga);
  yield takeLatest(MEMBER_EMPLOYEE_DETAILS.Request, getMemberEmpDetailsSaga);
  yield takeLatest(MEMBER_JOB_DETAILS.Request, getMemberJobReqDetailsSaga);
  yield takeLatest(MEMBER_REGISTRATION_DETAILS.Request, getMemberRegistrationDetailsSaga);
}

export default function * memberSaga() {
  yield all([
    watchMemberSaga(),
  ]);
}
