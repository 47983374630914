import { ActionType } from './ActionTypes';
import makePromise from './makePromise';
import ReduxAction from './Action';

/**
 * @abstract
 */
export class ActionBuilder {
  constructor() {
    throw new Error('Action builder is an abstract class, must not be initialised');
  }

  /**
   * @param {RequestType|VisibilityChangeType|ActionType|string} type
   * @param payload
   * @param error
   * @returns {Action}
   * @constructor
   */
  static Action({ type, payload, error }) {
    return new ReduxAction({
      type: ActionType(type).toString(),
      payload,
      error,
    });
  }
}

export const { Action } = ActionBuilder;

ActionBuilder.Action.withPromise = ({ type, payload, error }) => makePromise(
  Action({ type, payload, error })
);
