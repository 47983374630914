const userValues = require('./auth');
const groupValues = require('./group');

let mocks = {
  user: null,
};
if (process.env.NODE_ENV !== 'production') {
  mocks = {
    user: userValues.default,
    group: groupValues.default,
  };
}

class MockProvider {
  static resolve(category, mock) {
    if (!mocks[category]) {
      return null;
    }
    if (typeof mocks[category][mock] === 'undefined') {
      throw new Error(`Mock "${category}/${mock}" does not exist`);
    }
    return mocks[category][mock];
  }
}

export default MockProvider;
