import { SIGN_OUT, SIGN_UP, SIGN_USER, VALIDATE_OTP } from '../constants/auth';

const saveObj = {
  saving: false,
  data: null,
  hasErrors: false,
};

export const initialState = {
  auth: {
    fetching: false,
    data: null,
    error: null,
  },
  signUp: saveObj,
  validateOTP: saveObj,
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case SIGN_USER.Request: {
    return {
      ...state,
      auth: {
        fetching: true,
        data: null,
        error: null,
      },
    };
  }
  case SIGN_USER.Success: {
    return {
      ...state,
      auth: {
        fetching: false,
        data: payload,
        error: null,
      },
    };
  }
  case SIGN_USER.Fail: {
    return {
      ...state,
      auth: {
        fetching: false,
        data: null,
        error: payload,
      },
    };
  }
  case SIGN_OUT: {
    return {
      ...state,
      auth: {
        fetching: false,
        data: null,
        error: null,
      },
    };
  }
  case SIGN_UP.Request: {
    return {
      ...state,
      signUp: {
        saving: true,
        data: null,
      },
    };
  }
  case SIGN_UP.Success: {
    return {
      ...state,
      signUp: {
        saving: false,
        data: payload,
      },
    };
  }
  case SIGN_UP.Fail: {
    return {
      ...state,
      signUp: saveObj,
    };
  }
  case VALIDATE_OTP.Request: {
    return {
      ...state,
      validateOTP: {
        saving: true,
        data: null,
      },
    };
  }
  case VALIDATE_OTP.Success: {
    return {
      ...state,
      validateOTP: {
        saving: false,
        data: payload,
      },
    };
  }
  case VALIDATE_OTP.Fail: {
    return {
      ...state,
      validateOTP: saveObj,
    };
  }
  default: return state;
  }
};

export default userReducer;
