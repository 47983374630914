import { lazy, React } from 'react';
import Loadable from './LazyComponents/Loadable';
import { LoginLayout } from '../layout/LoginLayout';

const AuthLogin = Loadable(lazy(() => import('../pages/LoginNew')));
const Register = Loadable(lazy(() => import('../pages/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/ResetPassword')));

const LoginRoutes = {
  path: '/',
  element: <LoginLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />,
    },
    {
      path: '/',
      element: <AuthLogin />,
    },
    {
      path: '/register',
      element: <Register />,
    },
    {
      path: '/reset-password',
      element: <ResetPassword />,
    },
  ],
};

export default LoginRoutes;
