import axios from 'axios';
import BASE_API_URL from '../constants/global';

export const getAllActiveMembers = () => axios.get(`${BASE_API_URL}/common/getActiveMembers`);
export const getAllContactType = () => axios.get(`${BASE_API_URL}/dss/getAllContactType`);
export const getAllDocType = () => axios.get(`${BASE_API_URL}/dss/getAllDocType`);
export const getBankMaster = () => axios.get(`${BASE_API_URL}/dss/getBankMaster`);
export const getBloodGroup = () => axios.get(`${BASE_API_URL}/dss/getBloodGroup`);
export const getWorkTimeMaster = () => axios.get(`${BASE_API_URL}/dss/getWorkTimeMaster`);
export const getPaidBasisMaster = () => axios.get(`${BASE_API_URL}/dss/getPaidBasisMaster`);
export const getAllRoleGroupAdmin = () => axios.get(`${BASE_API_URL}/dss/getAllRoleGroupAdmin`);
export const getAllGroupActivitiesActive = () => axios.get(`${BASE_API_URL}/dss/getAllGroupActivitesActive`);

export const saveMaterialMaster = data => axios.post(`${BASE_API_URL}/material/save`, data);
export const getAllMaterials = query => axios.get(`${BASE_API_URL}/material/all?${query || ''}`);
export const getMaterialById = query => axios.get(`${BASE_API_URL}/material/getMaterialById?matId=${query}`);

export const saveMaterialImages = formData => axios.post(`${BASE_API_URL}/common/saveAttachments`, formData);
export const deleteMaterialImage = imageId => axios.delete(`${BASE_API_URL}/material/deleteFile/${imageId}`);
export const getMaterialImage = (imageId, groupId) => axios.get(`${BASE_API_URL}/material/downloadFile/${imageId}${groupId ? '/' + groupId : ''}`, {
  responseType: 'blob',
});
export const uploadDocument = (formData, memberId) => {
  return memberId
    ? axios.post(`${BASE_API_URL}/common/saveUserFile/${memberId}`, formData)
    : axios.post(`${BASE_API_URL}/common/saveUserFile`, formData);
};

export const getCurrencyCodes = () => axios.get(`${BASE_API_URL}/common/currencyCodes`);

export const getDescription = (parentId, masterType) => axios.get(`${BASE_API_URL}/material/getDescription?masterType=${masterType}${parentId ? '&parentId=' + parentId : ''}`);
export const addDescription = data => axios.post(`${BASE_API_URL}/material/addDescription`, data);

export const getItemNames = (parentId, masterType) => axios.get(`${BASE_API_URL}/material/getItemNames?parentId=${parentId}`);
export const getBrands = (parentId, masterType) => axios.get(`${BASE_API_URL}/material/getBrands?parentId=${parentId}`);
export const getModels = (parentId, masterType) => axios.get(`${BASE_API_URL}/material/getModels?parentId=${parentId}`);
export const getSpecifications = (parentId, masterType) => axios.get(`${BASE_API_URL}/material/getSpecifications?parentId=${parentId}`);

export const deletePriceDetails = (matId, id) => axios.delete(`${BASE_API_URL}/material/delete/${matId}/price/${id}`);

export const getGoogleFile = (fileId, userId) => {
  return userId
    ? axios.get(`${BASE_API_URL}/common/downloadCloudFile/${userId}/${fileId}`, {
      responseType: 'blob',
    })
    : axios.get(`${BASE_API_URL}/common/downloadCloudFile/${fileId}`, {
      responseType: 'blob',
    });
};

export const downloadFile = (fileId, userId) => {
  return userId
    ? axios.get(`${BASE_API_URL}/common/downloadFile/${userId}/${fileId}`, {
      responseType: 'blob',
    })
    : axios.get(`${BASE_API_URL}/common/downloadFile/${fileId}`, {
      responseType: 'blob',
    });
};

export const getMaterialDetails = matId => axios.get(`${BASE_API_URL}/material/getMaterialDetails/${matId}`);
export const addMaterial = data => axios.post(`${BASE_API_URL}/material/addMaterial`, data);

export const getLatestPrice = matId => axios.get(`${BASE_API_URL}/material/latestPrice/${matId}`);

export const priceRequest = data => axios.post(`${BASE_API_URL}/material/priceRequest`, data);
export const priceRequestApprove = data => axios.post(`${BASE_API_URL}/material/priceRequestApprove`, data);
export const priceRequestReject = data => axios.post(`${BASE_API_URL}/material/priceRequestReject`, data);
export const getPriceRequests = (type, status) => axios.get(`${BASE_API_URL}/material/priceRequest?type=${type}&status=${status}`);

export const getNotifications = () => axios.get(`${BASE_API_URL}/material/notifications`);
export const deleteNotification = id => axios.delete(`${BASE_API_URL}/material/notification/${id}`);
export const validateMaterialAdd = matId => axios.get(`${BASE_API_URL}/material/validateMaterialAdd/${matId}`);
