import { styled } from '@mui/material/styles';

export const MainWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
}));

export const HeaderWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  width: '100%',
}));

export const BodyWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  width: '100%',
  overflow: 'auto',
}));

export const FooterWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  width: '100%',
}));
