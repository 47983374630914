export default class Action extends Object {
  type;

  payload;

  error;

  constructor({ type, payload, error }) {
    super();
    this.type = type;
    this.payload = payload;

    if (error) {
      this.error = error;
    }
  }

  valueOf() {
    return {
      type: this.type,
      payload: this.payload,
    };
  }
}
