/* eslint-disable max-classes-per-file */
class MultiActionType {
  constructor(type) {
    this.type = type;
  }

  toString() {
    return this.type;
  }

  valueOf() {
    return this.type;
  }
}

export class RequestType extends MultiActionType {
  Success;

  Fail;

  Request;

  constructor(type) {
    super(type);
    this.Success = `${type}_SUCCESS`;
    this.Fail = `${type}_FAIL`;
    this.Request = `${type}_REQUEST`;
  }
}

export class VisibilityChangeType extends MultiActionType {
  Show;

  Hide;

  constructor(type) {
    super(type);
    this.Show = `${type}_SHOW`;
    this.Hide = `${type}_HIDE`;
  }
}

export class ToggleType extends MultiActionType {
  Enable;

  Disable;

  Toggle;

  constructor(type) {
    super(type);
    this.Enable = `${type}_ENABLE`;
    this.Disable = `${type}_DISABLE`;
    this.Toggle = `${type}_TOGGLE`;
  }
}

export const ActionType = type => type.toString();

export function RequestActionType(type) {
  return new RequestType(type);
}

export function VisibilityChangeActionType(type) {
  return new VisibilityChangeType(type);
}

export function ToggleActionType(type) {
  return new ToggleType(type);
}
