import axios from 'axios';
import BASE_API_URL from '../constants/global';
import MocksProvider from './mocks';

const mockedMode = true;
export const getGroupMaterial = mockedMode
  ? MocksProvider.resolve('group', 'groupMaterial')
  : user => axios.get(`${BASE_API_URL}/auth/signin`, user);

export const getGroupMaterialSelection = query => axios.get(`${BASE_API_URL}/material/allForSelection?${query}`);

export const getAllTeamMembers = query => axios.get(`${BASE_API_URL}/group/userProfile/getAllTeamMembers`, query);
export const getAllRoleGroupAdmin = () => axios.get(`${BASE_API_URL}/dss/getAllRoleGroupAdmin`);
export const getGroupTeamMember = id => axios.get(`${BASE_API_URL}/group/userProfile/getTeamContactByMember?memberId=${id}`);
export const saveGroupTeamContact = data => axios.post(`${BASE_API_URL}/group/userProfile/saveGroupTeamMember`, data);

export const loadGrpActItem = () => axios.get(`${BASE_API_URL}/group/material/loadGrpActItem`);
export const loadGrpSubActItem = activityId => axios.get(`${BASE_API_URL}/group/material/loadGrpSubActItem?activityId=${activityId}`);
export const getSubActNameDesc = id => axios.get(`${BASE_API_URL}/group/material/getSubActNameDesc?subActId=${id}`);
export const getMaterialSpec = id => axios.get(`${BASE_API_URL}/group/material/getMaterialSpec?nameId=${id}`);
export const getAllMaterialBrand = () => axios.get(`${BASE_API_URL}/group/material/getAllMaterialBrand`);
export const getAllMadeCountry = () => axios.get(`${BASE_API_URL}/group/material/getAllMadeCountry`);
export const getAllPackage = () => axios.get(`${BASE_API_URL}/group/material/getAllPackage`);
export const getAllUnit = () => axios.get(`${BASE_API_URL}/group/material/getAllUnit`);

export const addSubActNameDesc = data => axios.post(`${BASE_API_URL}/group/material/addSubActNameDesc`, data);
export const addMaterialBrand = data => axios.post(`${BASE_API_URL}/group/material/addMaterialBrand`, data);
export const addPackage = data => axios.post(`${BASE_API_URL}/group/material/addPackage`, data);
export const addMaterialSpec = data => axios.post(`${BASE_API_URL}/group/material/addMaterialSpec`, data);
export const addUnit = data => axios.post(`${BASE_API_URL}/group/material/addUnit`, data);

export const getGroupContactDetails = id => {
  return id
    ? axios.get(`${BASE_API_URL}/group/userProfile/getGroupContactDetails/${id}`)
    : axios.get(`${BASE_API_URL}/group/userProfile/getGroupContactDetails`);
};
export const getGroupDocumentDetails = () => axios.get(`${BASE_API_URL}/group/userProfile/getGroupDocumentDetails`);
export const getGroupBankDetails = () => axios.get(`${BASE_API_URL}/group/userProfile/getGroupBankDetails`);

export const getGroupDetails = memberId => {
  return memberId
    ? axios.get(`${BASE_API_URL}/group/userProfile/getGroupDetails/${memberId}`)
    : axios.get(`${BASE_API_URL}/group/userProfile/getGroupDetails`);
};
export const saveDocumentDetails = (id, data) => {
  return id
    ? axios.post(`${BASE_API_URL}/user/contact/saveDocumentDetails/${id}`, data)
    : axios.post(`${BASE_API_URL}/user/contact/saveDocumentDetails`, data);
};
export const saveGroupDetails = (data, memberId) => {
  return memberId
    ? axios.post(`${BASE_API_URL}/group/userProfile/saveGroupDetails/${memberId}`, data)
    : axios.post(`${BASE_API_URL}/group/userProfile/saveGroupDetails`, data);
};

export const saveContactDetails = (id, data) => {
  return id
    ? axios.post(`${BASE_API_URL}/group/userProfile/saveContactDetails/${id}`, data)
    : axios.post(`${BASE_API_URL}/group/userProfile/saveContactDetails`, data);
};

export const saveBankDetails = (id, data) => {
  return id
    ? axios.post(`${BASE_API_URL}/group/userProfile/saveBankDetails/${id}`, data)
    : axios.post(`${BASE_API_URL}/group/userProfile/saveBankDetails`, data);
};

export const deleteContactDetails = id => axios.delete(`${BASE_API_URL}/group/userProfile/deleteContactDetails/${id}`);
export const deleteBankDetails = id => axios.delete(`${BASE_API_URL}/group/userProfile/deleteBankDetails/${id}`);
export const deleteDocDetails = id => axios.delete(`${BASE_API_URL}/group/userProfile/deleteDocDetails/${id}`);
export const deleteDocument = id => axios.delete(`${BASE_API_URL}/user/contact/deleteDocument/${id}`);
