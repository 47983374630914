import { SnackbarProvider } from 'notistack';
import React from 'react';
import MyRoutes from './routes';
import { SnackbarUtilsConfigurator } from './utils/SnackBarUtils';
export default function App() {
  return (
    <SnackbarProvider autoHideDuration={4000} maxSnack={10} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
      <SnackbarUtilsConfigurator />
      <MyRoutes />
    </SnackbarProvider>
  );
}
