import { SIGN_OUT, SIGN_UP, SIGN_USER, VALIDATE_OTP } from '../constants/auth';

export const actionSignUser = user => ({
  type: SIGN_USER.Request,
  payload: user,
});

export const actionSignOut = () => ({
  type: SIGN_OUT,
});

export const actionSignUp = formData => ({
  type: SIGN_UP.Request,
  payload: formData,
});

export const actionValidateOTP = data => ({
  type: VALIDATE_OTP.Request,
  payload: data,
});
