import axios from 'axios';
import BASE_API_URL from '../constants/global';

export const getMemberPersonalDetails = id => {
  axios.get(`${BASE_API_URL}/member/userProfile/getMemberPersonalDetails?userId=${id}`);
};

export const getMemberContactlDetails = id => axios.get(`${BASE_API_URL}/member/userProfile/getMemberContactlDetails?userId=${id}`);
export const saveMemberPersonalDetails = id => axios.post(`${BASE_API_URL}/member/userProfile/saveMemberPersonalDetails?userId=${id}`);
export const getDocumentDetails = id => axios.get(`${BASE_API_URL}/member/userProfile/getDocumentDetails?userId=${id}`);
export const getMemberEduDetails = id => axios.get(`${BASE_API_URL}/member/userProfile/getMemberEduDetails?userId=${id}`);
export const getMemberBankDetails = id => axios.get(`${BASE_API_URL}/member/userProfile/getMemberBankDetails?userId=${id}`);
export const getMemberEmpDetails = id => axios.get(`${BASE_API_URL}/member/userProfile/getMemberEmpDetails?userId=${id}`);
export const getMemberJobReqDetails = id => axios.get(`${BASE_API_URL}/member/userProfile/getMemberJobReqDetails?userId=${id}`);
export const getMemberRegistrationDetails = id => axios.get(`${BASE_API_URL}/member/userProfile/getMemberRegistrationDetails?userId=${id}`);
