import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getAllActivities,
  getAllBrands,
  getAllItemNames,
  getAllMadeIn,
  getAllModels,
  getAllSpecifications,
  getAllSubActivities
} from '../api/filter';
import {
  ACTIVITIES,
  BRANDS,
  MADE_IN_COUNTRIES,
  MODELS,
  NAMES,
  SPECIFICATIONS,
  SUB_ACTIVITIES
} from '../constants/filter';
import { snackActions } from '../utils/SnackBarUtils';

export function * getAllActivitiesSaga({ payload }) {
  try {
    const response = yield call(getAllActivities, payload);
    const { data } = response;
    yield put({
      type: ACTIVITIES.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ACTIVITIES.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllSubActivitiesSaga({ payload }) {
  try {
    const response = yield call(getAllSubActivities, payload);
    const { data } = response;
    yield put({
      type: SUB_ACTIVITIES.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: SUB_ACTIVITIES.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllItemNamesSaga({ payload }) {
  try {
    const response = yield call(getAllItemNames, payload);
    const { data } = response;
    yield put({
      type: NAMES.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: NAMES.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllBrandsSaga({ payload }) {
  try {
    const response = yield call(getAllBrands, payload);
    const { data } = response;
    yield put({
      type: BRANDS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: BRANDS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllModelsSaga({ payload }) {
  try {
    const response = yield call(getAllModels, payload);
    const { data } = response;
    yield put({
      type: MODELS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: MODELS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllSpecificationsSaga({ payload }) {
  try {
    const response = yield call(getAllSpecifications, payload);
    const { data } = response;
    yield put({
      type: SPECIFICATIONS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: SPECIFICATIONS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllMadeInSaga({ payload }) {
  try {
    const response = yield call(getAllMadeIn, payload);
    const { data } = response;
    yield put({
      type: MADE_IN_COUNTRIES.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: MADE_IN_COUNTRIES.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * watchFilterSaga() {
  yield takeLatest(ACTIVITIES.Request, getAllActivitiesSaga);
  yield takeLatest(SUB_ACTIVITIES.Request, getAllSubActivitiesSaga);
  yield takeLatest(NAMES.Request, getAllItemNamesSaga);
  yield takeLatest(BRANDS.Request, getAllBrandsSaga);
  yield takeLatest(MODELS.Request, getAllModelsSaga);
  yield takeLatest(SPECIFICATIONS.Request, getAllSpecificationsSaga);
  yield takeLatest(MADE_IN_COUNTRIES.Request, getAllMadeInSaga);
}

export default function * filterSaga() {
  yield all([
    watchFilterSaga(),
  ]);
}
