import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getAllDocType,
  getBloodGroup,
  getCurrencyCodes,
  getPaidBasisMaster,
  getWorkTimeMaster,
  uploadDocument
} from '../api/common';
import {
  deleteEducationDetails,
  deleteEducationDocument,
  deleteEmploymentDetails,
  deleteEmploymentWorkDetails,
  deleteJobRequestDetails,
  deleteJobRequestWorkDetails,
  getEducationDetails,
  getEmploymentDetails,
  getJobRequestDetails,
  getPersonalDetails,
  getUserDetails,
  saveEducationDetails,
  saveEmploymentDetails,
  saveJobRequestDetails,
  savePersonalDetails,
  saveUserDetails
} from '../api/contact';
import {
  getDocumentDetails,
  getMemberBankDetails,
  getMemberContactlDetails,
  getMemberEmpDetails,
  getMemberJobReqDetails,
  getMemberRegistrationDetails
} from '../api/member';
import { BLOOD_GROUP, DOCUMENT_TYPE, GET_CURRENCY_CODES, PAID_BASIC_MASTER, WORK_TIME_MASTER } from '../constants/common';
import {
  DELETE_EDUCATION_DETAILS,
  DELETE_EDUCATION_DOCUMENT,
  DELETE_EMPLOYMENT_DETAILS,
  DELETE_EMPLOYMENT_WORK_DETAILS,
  DELETE_JOB_DETAILS,
  DELETE_JOB_WORK_DETAILS,
  GET_EMPLOYMENT_DETAILS,
  GET_JOB_DETAILS,
  GET_USER_DETAILS,
  PERSONAL_DETAILS,
  SAVE_EDUCATION_DETAILS,
  SAVE_EMPLOYMENT_DETAILS,
  SAVE_JOB_DETAILS,
  SAVE_PERSONAL_DETAILS,
  SAVE_USER_DETAILS
} from '../constants/contact';
import {
  GET_DOCUMENT_DETAILS,
  MEMBER_BANK_DETAILS,
  MEMBER_CONTACT_DETAILS,
  MEMBER_EDUCATION_DETAILS,
  MEMBER_EMPLOYEE_DETAILS,
  MEMBER_JOB_DETAILS,
  MEMBER_REGISTRATION_DETAILS
} from '../constants/member';
import { snackActions } from '../utils/SnackBarUtils';

export function * getPersonalDetailsSaga({ payload }) {
  try {
    const [response, bloodGroupRes] = yield all([call(getPersonalDetails, payload), call(getBloodGroup)]);
    const { data } = response;
    yield put({
      type: PERSONAL_DETAILS.Success,
      payload: data,
    });
    const { data: bloodGroupList } = bloodGroupRes;
    yield put({
      type: BLOOD_GROUP.Success,
      payload: bloodGroupList,
    });
  } catch (e) {
    yield put({
      type: PERSONAL_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the Personal Details');
  }
}

export function * getMemberContactlDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberContactlDetails, payload);
    const { data: { contactDetails } } = response;
    yield put({
      type: MEMBER_CONTACT_DETAILS.Success,
      payload: contactDetails,
    });
  } catch (e) {
    yield put({
      type: MEMBER_CONTACT_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member contact Details');
  }
}

export function * savePersonalDetailsSaga({ payload }) {
  try {
    const { id, data } = payload;
    const response = yield call(savePersonalDetails, id, data);
    const { data: { responseCode, responseDesc } } = response;
    if (responseCode) {
      snackActions.error(responseDesc);
      yield put({
        type: SAVE_PERSONAL_DETAILS.Fail,
      });
    } else {
      yield put({
        type: SAVE_PERSONAL_DETAILS.Success,
        payload: 'success',
      });
      snackActions.success('Personal Details saved successfully');
    }
  } catch (e) {
    yield put({
      type: SAVE_PERSONAL_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to save Personal Details');
  }
}
export function * getDocumentDetailsSaga({ payload }) {
  try {
    const response = yield call(getDocumentDetails, payload);
    const { data: { documentDetailDTO } } = response;
    yield put({
      type: GET_DOCUMENT_DETAILS.Success,
      payload: documentDetailDTO,
    });
  } catch (e) {
    yield put({
      type: GET_DOCUMENT_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member document Details');
  }
}

export function * getMemberEduDetailsSaga({ payload }) {
  try {
    const [response, docTypesRes] = yield all([call(getEducationDetails, payload), call(getAllDocType)]);
    const { data: eduDetails } = response;
    yield put({
      type: MEMBER_EDUCATION_DETAILS.Success,
      payload: eduDetails,
    });
    const { data } = docTypesRes;
    yield put({
      type: DOCUMENT_TYPE.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: MEMBER_EDUCATION_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member education Details');
  }
}

export function * getMemberBankDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberBankDetails, payload);
    const { data: { bankDetails } } = response;
    yield put({
      type: MEMBER_BANK_DETAILS.Success,
      payload: bankDetails,
    });
  } catch (e) {
    yield put({
      type: MEMBER_BANK_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member bank Details');
  }
}

export function * getMemberEmpDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberEmpDetails, payload);
    const { data: { empDetails } } = response;
    yield put({
      type: MEMBER_EMPLOYEE_DETAILS.Success,
      payload: empDetails,
    });
  } catch (e) {
    yield put({
      type: MEMBER_EMPLOYEE_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member employee Details');
  }
}

export function * getMemberJobReqDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberJobReqDetails, payload);
    const { data: { empDetails } } = response;
    yield put({
      type: MEMBER_JOB_DETAILS.Success,
      payload: empDetails,
    });
  } catch (e) {
    yield put({
      type: MEMBER_JOB_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member job Details');
  }
}

export function * getMemberRegistrationDetailsSaga({ payload }) {
  try {
    const response = yield call(getMemberRegistrationDetails, payload);
    const { data: { memberRegistrationDetailDTO } } = response;
    yield put({
      type: MEMBER_JOB_DETAILS.Success,
      payload: memberRegistrationDetailDTO,
    });
  } catch (e) {
    yield put({
      type: MEMBER_JOB_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the member registration Details');
  }
}

export function * saveEducationDetailsSaga({ payload }) {
  try {
    const { memberId, formData: { data, attachment } } = payload;
    const response = yield call(saveEducationDetails, data, memberId);
    const { data: { eduId, isNew, responseCode, responseDesc } } = response;
    if (responseCode) {
      snackActions.error(responseDesc);
      yield put({
        type: SAVE_EDUCATION_DETAILS.Fail,
      });
    } else {
      if (attachment) {
        const formData = new FormData();
        formData.append('docTypeId', data.docTypeId);
        formData.append('attachment', attachment);
        try {
          const { data: fileResponse } = yield call(uploadDocument, formData, memberId);
          const { id: fileId, contentType } = fileResponse;
          const newData = {
            ...data,
            eduId,
            fileId,
            contentType,
            hasAttachment: 1,
          };
          yield call(saveEducationDetails, newData, memberId);
        } catch (e) {
          snackActions.error('Could not able to upload the document');
        }
      }
      if (isNew) {
        snackActions.success('Education Details added');
      } else {
        snackActions.success('Education Details updated successfully');
      }
      yield put({
        type: SAVE_EDUCATION_DETAILS.Success,
        payload: 'success',
      });
      yield put({
        type: MEMBER_EDUCATION_DETAILS.Request,
        payload: memberId,
      });
    }
  } catch (e) {
    snackActions.error('Could not able to save the data');
    yield put({
      type: SAVE_EDUCATION_DETAILS.Fail,
    });
  }
}

export function * deleteEducationDetailsSaga({ payload }) {
  try {
    const { memberId, id } = payload;
    yield call(deleteEducationDetails, id);
    yield put({
      type: DELETE_EDUCATION_DETAILS.Success,
    });
    snackActions.success('EDUCATION Details deleted successfully!');
    yield put({
      type: MEMBER_EDUCATION_DETAILS.Request,
      payload: memberId,
    });
  } catch (e) {
    yield put({
      type: DELETE_EDUCATION_DETAILS.Fail,
    });
  }
}

export function * deleteEducationDocumentSaga({ payload }) {
  try {
    const { memberId, id } = payload;
    yield call(deleteEducationDocument, id);
    yield put({
      type: DELETE_EDUCATION_DOCUMENT.Success,
    });
    snackActions.success('Document deleted successfully!');
    yield put({
      type: MEMBER_EDUCATION_DETAILS.Request,
      payload: memberId,
    });
  } catch (e) {
    yield put({
      type: DELETE_EDUCATION_DOCUMENT.Fail,
    });
  }
}

export function * getEmploymentDetailsSaga({ payload }) {
  try {
    const [response, workTime, paidBasis, currencyRes] = yield all([
      call(getEmploymentDetails, payload),
      call(getWorkTimeMaster),
      call(getPaidBasisMaster),
      call(getCurrencyCodes),
    ]);
    const { data: details } = response;
    yield put({
      type: GET_EMPLOYMENT_DETAILS.Success,
      payload: details,
    });
    const { data: workTimeData } = workTime;
    yield put({
      type: WORK_TIME_MASTER.Success,
      payload: workTimeData,
    });
    const { data: paidBasisData } = paidBasis;
    yield put({
      type: PAID_BASIC_MASTER.Success,
      payload: paidBasisData,
    });
    yield put({
      type: GET_CURRENCY_CODES.Success,
      payload: currencyRes.data,
    });
  } catch (e) {
    yield put({
      type: MEMBER_EDUCATION_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the Details');
  }
}

export function * saveEmploymentDetailsSaga({ payload }) {
  try {
    const { memberId, formData: { data } } = payload;
    const response = yield call(saveEmploymentDetails, data, memberId);
    const { data: { isNew, responseCode, responseDesc } } = response;
    if (responseCode) {
      snackActions.error(responseDesc);
      yield put({
        type: SAVE_EMPLOYMENT_DETAILS.Fail,
      });
    } else {
      if (isNew) {
        snackActions.success('Employment Details added');
      } else {
        snackActions.success('Employment Details updated successfully');
      }
      yield put({
        type: SAVE_EMPLOYMENT_DETAILS.Success,
        payload: 'success',
      });
      yield put({
        type: GET_EMPLOYMENT_DETAILS.Request,
        payload: memberId,
      });
    }
  } catch (e) {
    snackActions.error('Could not able to save the data');
    yield put({
      type: SAVE_EDUCATION_DETAILS.Fail,
    });
  }
}

export function * deleteEmploymentDetailsSaga({ payload }) {
  try {
    const { memberId, id } = payload;
    yield call(deleteEmploymentDetails, id);
    yield put({
      type: DELETE_EMPLOYMENT_DETAILS.Success,
    });
    snackActions.success('Employment Details deleted successfully!');
    yield put({
      type: GET_EMPLOYMENT_DETAILS.Request,
      payload: memberId,
    });
  } catch (e) {
    yield put({
      type: DELETE_EMPLOYMENT_DETAILS.Fail,
    });
  }
}

export function * deleteEmploymentWorkDetailsSaga({ payload }) {
  try {
    yield call(deleteEmploymentWorkDetails, payload);
    yield put({
      type: DELETE_EMPLOYMENT_WORK_DETAILS.Success,
    });
    yield put({
      type: GET_EMPLOYMENT_DETAILS.Request,
    });
  } catch (e) {
    yield put({
      type: DELETE_EMPLOYMENT_WORK_DETAILS.Fail,
    });
  }
}

export function * getJobRequestDetailsSaga({ payload }) {
  try {
    const [response, workTime, paidBasis, currencyRes] = yield all([
      call(getJobRequestDetails, payload),
      call(getWorkTimeMaster),
      call(getPaidBasisMaster),
      call(getCurrencyCodes),
    ]);
    const { data: details } = response;
    yield put({
      type: GET_JOB_DETAILS.Success,
      payload: details,
    });
    const { data: workTimeData } = workTime;
    yield put({
      type: WORK_TIME_MASTER.Success,
      payload: workTimeData,
    });
    const { data: paidBasisData } = paidBasis;
    yield put({
      type: PAID_BASIC_MASTER.Success,
      payload: paidBasisData,
    });
    yield put({
      type: GET_CURRENCY_CODES.Success,
      payload: currencyRes.data,
    });
  } catch (e) {
    yield put({
      type: MEMBER_EDUCATION_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the Details');
  }
}

export function * saveJobRequestDetailsSaga({ payload }) {
  try {
    const { memberId, formData: { data } } = payload;
    const response = yield call(saveJobRequestDetails, data, memberId);
    const { data: { isNew, responseCode, responseDesc } } = response;
    if (responseCode) {
      snackActions.error(responseDesc);
      yield put({
        type: SAVE_JOB_DETAILS.Fail,
      });
    } else {
      if (isNew) {
        snackActions.success('Job Request Details added');
      } else {
        snackActions.success('Job Request Details updated successfully');
      }
      yield put({
        type: SAVE_JOB_DETAILS.Success,
        payload: 'success',
      });
      yield put({
        type: GET_JOB_DETAILS.Request,
        payload: memberId,
      });
    }
  } catch (e) {
    snackActions.error('Could not able to save the data');
    yield put({
      type: SAVE_EDUCATION_DETAILS.Fail,
    });
  }
}

export function * deleteJobRequestDetailsSaga({ payload }) {
  try {
    const { memberId, id } = payload;
    yield call(deleteJobRequestDetails, id);
    yield put({
      type: DELETE_JOB_DETAILS.Success,
    });
    snackActions.success('Job Request Details deleted successfully!');
    yield put({
      type: GET_JOB_DETAILS.Request,
      payload: memberId,
    });
  } catch (e) {
    yield put({
      type: DELETE_JOB_DETAILS.Fail,
    });
  }
}

export function * deleteJobRequestWorkDetailsSaga({ payload }) {
  try {
    yield call(deleteJobRequestWorkDetails, payload);
    yield put({
      type: DELETE_JOB_WORK_DETAILS.Success,
    });
    yield put({
      type: GET_JOB_DETAILS.Request,
    });
  } catch (e) {
    yield put({
      type: DELETE_JOB_WORK_DETAILS.Fail,
    });
  }
}

export function * getUserDetailsSaga({ payload }) {
  try {
    const response = yield call(getUserDetails, payload);
    const { data } = response;
    yield put({
      type: GET_USER_DETAILS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GET_USER_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the Details');
  }
}

export function * saveUserDetailsSaga({ payload }) {
  try {
    const { data, attachment, userId } = payload;
    let message = 'profile';
    if (attachment) {
      const formData = new FormData();
      formData.append('docTypeId', 1);
      formData.append('attachment', attachment);
      try {
        const { data: fileResponse } = yield call(uploadDocument, formData);
        const { id: fileId } = fileResponse;
        const newData = {
          ...data,
          fileId,
        };
        yield call(saveUserDetails, newData, userId);
        message = fileId;
      } catch (e) {
        snackActions.error('Could not able to upload the document');
      }
    } else {
      const response = yield call(saveUserDetails, data, userId);
      const { data: { responseCode, responseDesc } } = response;
      if (responseCode !== 1) {
        snackActions.error(responseDesc);
        yield put({
          type: SAVE_USER_DETAILS.Fail,
        });
      }
      snackActions.success('Profile Details updated successfully');
    }
    yield put({
      type: SAVE_USER_DETAILS.Success,
      payload: message,
    });
  } catch (e) {
    snackActions.error('Could not able to save the data');
    yield put({
      type: SAVE_USER_DETAILS.Fail,
    });
  }
}

export function * watchContactSaga() {
  yield takeLatest(PERSONAL_DETAILS.Request, getPersonalDetailsSaga);
  yield takeLatest(MEMBER_CONTACT_DETAILS.Request, getMemberContactlDetailsSaga);
  yield takeLatest(SAVE_PERSONAL_DETAILS.Request, savePersonalDetailsSaga);
  yield takeLatest(GET_DOCUMENT_DETAILS.Request, getDocumentDetailsSaga);
  yield takeLatest(MEMBER_BANK_DETAILS.Request, getMemberBankDetailsSaga);
  yield takeLatest(MEMBER_EDUCATION_DETAILS.Request, getMemberEduDetailsSaga);
  yield takeLatest(MEMBER_EMPLOYEE_DETAILS.Request, getMemberEmpDetailsSaga);
  yield takeLatest(MEMBER_JOB_DETAILS.Request, getMemberJobReqDetailsSaga);
  yield takeLatest(MEMBER_REGISTRATION_DETAILS.Request, getMemberRegistrationDetailsSaga);
  yield takeLatest(SAVE_EDUCATION_DETAILS.Request, saveEducationDetailsSaga);
  yield takeLatest(DELETE_EDUCATION_DETAILS.Request, deleteEducationDetailsSaga);
  yield takeLatest(DELETE_EDUCATION_DOCUMENT.Request, deleteEducationDocumentSaga);
  yield takeLatest(GET_EMPLOYMENT_DETAILS.Request, getEmploymentDetailsSaga);
  yield takeLatest(SAVE_EMPLOYMENT_DETAILS.Request, saveEmploymentDetailsSaga);
  yield takeLatest(DELETE_EMPLOYMENT_DETAILS.Request, deleteEmploymentDetailsSaga);
  yield takeLatest(DELETE_EMPLOYMENT_WORK_DETAILS.Request, deleteEmploymentWorkDetailsSaga);
  yield takeLatest(GET_JOB_DETAILS.Request, getJobRequestDetailsSaga);
  yield takeLatest(SAVE_JOB_DETAILS.Request, saveJobRequestDetailsSaga);
  yield takeLatest(DELETE_JOB_DETAILS.Request, deleteJobRequestDetailsSaga);
  yield takeLatest(DELETE_JOB_WORK_DETAILS.Request, deleteJobRequestWorkDetailsSaga);
  yield takeLatest(GET_USER_DETAILS.Request, getUserDetailsSaga);
  yield takeLatest(SAVE_USER_DETAILS.Request, saveUserDetailsSaga);
}

export default function * contactSaga() {
  yield all([
    watchContactSaga(),
  ]);
}
