const makePromise = action => {
  let onResolve;
  let onReject;
  const promise = new Promise((resolve, reject) => {
    onResolve = resolve;
    onReject = reject;
  });
  return {
    ...action,
    payload: {
      ...action.payload,
      onResolve,
      onReject,
      promise,
    },
  };
};

export default makePromise;
