import {
  ACTIVITIES,
  BRANDS,
  MADE_IN_COUNTRIES,
  MODELS,
  NAMES,
  SPECIFICATIONS,
  SUB_ACTIVITIES
} from '../constants/filter';

const dataObjArray = {
  fetching: false,
  data: [],
};

export const initialState = {
  activities: dataObjArray,
  subActivities: dataObjArray,
  names: dataObjArray,
  brands: dataObjArray,
  models: dataObjArray,
  specifications: dataObjArray,
  madeInCountries: dataObjArray,
};

const filterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ACTIVITIES.Request: {
    return {
      ...state,
      activities: {
        fetching: true,
        data: [],
      },
    };
  }
  case ACTIVITIES.Success: {
    return {
      ...state,
      activities: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ACTIVITIES.Fail: {
    return {
      ...state,
      activities: dataObjArray,
    };
  }
  case SUB_ACTIVITIES.Request: {
    return {
      ...state,
      subActivities: {
        fetching: true,
        data: [],
      },
    };
  }
  case SUB_ACTIVITIES.Success: {
    return {
      ...state,
      subActivities: {
        fetching: false,
        data: payload,
      },
    };
  }
  case SUB_ACTIVITIES.Fail: {
    return {
      ...state,
      subActivities: dataObjArray,
    };
  }
  case NAMES.Request: {
    return {
      ...state,
      names: {
        fetching: true,
        data: [],
      },
    };
  }
  case NAMES.Success: {
    return {
      ...state,
      names: {
        fetching: false,
        data: payload,
      },
    };
  }
  case NAMES.Fail: {
    return {
      ...state,
      names: dataObjArray,
    };
  }
  case BRANDS.Request: {
    return {
      ...state,
      brands: {
        fetching: true,
        data: [],
      },
    };
  }
  case BRANDS.Success: {
    return {
      ...state,
      brands: {
        fetching: false,
        data: payload,
      },
    };
  }
  case BRANDS.Fail: {
    return {
      ...state,
      brands: dataObjArray,
    };
  }
  case MODELS.Request: {
    return {
      ...state,
      models: {
        fetching: true,
        data: [],
      },
    };
  }
  case MODELS.Success: {
    return {
      ...state,
      models: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MODELS.Fail: {
    return {
      ...state,
      models: dataObjArray,
    };
  }
  case SPECIFICATIONS.Request: {
    return {
      ...state,
      specifications: {
        fetching: true,
        data: [],
      },
    };
  }
  case SPECIFICATIONS.Success: {
    return {
      ...state,
      specifications: {
        fetching: false,
        data: payload,
      },
    };
  }
  case SPECIFICATIONS.Fail: {
    return {
      ...state,
      specifications: dataObjArray,
    };
  }
  case MADE_IN_COUNTRIES.Request: {
    return {
      ...state,
      madeInCountries: {
        fetching: true,
        data: [],
      },
    };
  }
  case MADE_IN_COUNTRIES.Success: {
    return {
      ...state,
      madeInCountries: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MADE_IN_COUNTRIES.Fail: {
    return {
      ...state,
      madeInCountries: dataObjArray,
    };
  }
  default: return state;
  }
};
export default filterReducer;
