/* eslint-disable react/prop-types */
import { Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import React from 'react';

const getNotificationText = (notificationType, materialIdStr, message) => {
  switch (notificationType) {
  case 0:
    return `The price has been changed for ${materialIdStr} ${message ? `(${message})` : ''}`;
  case 1:
    return `New Price Request for ${materialIdStr} ${message ? `(${message})` : ''}`;
  case 2:
    return `The ${materialIdStr} has been approved ${message ? `(${message})` : ''}`;
  case 3:
    return `The ${materialIdStr} has been rejected ${message ? `(${message})` : ''}`;
  default:
    break;
  }
};

const NotificationDialog = props => {
  const { open, onClose, onDelete, data = [] } = props;
  if (!open) {
    return null;
  }
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
    >
      <DialogTitle>Notifications</DialogTitle>
      <DialogContent sx={{
        height: '95vh',
      }} dividers>
        <List>
          <Divider component="li" />
          {
            data.map(({
              id,
              createdDate,
              materialIdStr,
              message,
              notificationType,
            }) => (
              <>
                <ListItem
                  key={`notification-${id}`}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="comments"
                      color="error"
                      onClick={() => {
                        onDelete(id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  }
                  disablePadding
                  sx={{
                    padding: '10px 0',
                  }}
                >
                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant="body2"
                          color="text.primary"
                        >
                          {
                            getNotificationText(notificationType, materialIdStr, message)
                          }
                        </Typography>
                        <Typography variant="caption">
                          Date: {createdDate}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </>
            ))
          }
        </List>
      </DialogContent>
      <DialogActions>
        <Stack spacing={2} direction="row">
          <Button autoFocus onClick={onClose} variant="contained" color="error">
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationDialog;
