const ROLES = {
  MEMBER: 'ROLE_MEMBER',
  GROUP: 'ROLE_GROUP',
  COMPANY: 'ROLE_COMPANY',
  ADMIN: 'ROLE_ADMIN',
  GROUP_ADMIN: 'ROLE_GROUP_ADMIN',
  SALES: 'ROLE_SALES',
  DRAWING: 'ROLE_DRAWING',
  WORK: 'ROLE_WORK',
  MATERIAL: 'ROLE_MATERIAL',
  PRICE: 'ROLE_PRICE',
  PAYMENT: 'ROLE_PAYMENT',
  ATTENDANCE: 'ROLE_ATTENDANCE',
  DESIGN: 'ROLE_DESIGN',
  PURCHASE: 'ROLE_PURCHASE',
  HR: 'ROLE_HR',
  CASH: 'ROLE_CASH',
  COMPANY_ADMIN: 'ROLE_COMPANY_ADMIN',
};
export const hasGroupAdminRole = ({ roles }) => {
  return roles.includes(ROLES.GROUP_ADMIN) || roles.includes(ROLES.COMPANY_ADMIN);
};
export default ROLES;
