import {
  ADMIN_ALL_COMPANY_REG_DETAILS,
  ADMIN_ALL_GROUP_REG_DETAILS,
  ADMIN_ALL_MEMBER_REG_DETAILS,
  ADMIN_BANK_DETAILS,
  ADMIN_COMPANY_BANK_DETAILS,
  ADMIN_COMPANY_CONTACT_DETAILS,
  ADMIN_COMPANY_DETAILS,
  ADMIN_COMPANY_DOCUMENT_DETAILS,
  ADMIN_CONTACT_DETAILS,
  ADMIN_DOCUMENT_DETAILS,
  ADMIN_EDUCATION_DETAILS,
  ADMIN_EMPLOYEE_DETAILS,
  ADMIN_GROUP_BANK_DETAILS,
  ADMIN_GROUP_CONTACT_DETAILS,
  ADMIN_GROUP_DETAILS,
  ADMIN_GROUP_DOCUMENT_DETAILS,
  ADMIN_GROUP_USER_ACTIVITY_DETAILS,
  ADMIN_JOB_DETAILS,
  ADMIN_NEW_COMPANY_REG_DETAILS,
  ADMIN_NEW_GROUP_REG_DETAILS,
  ADMIN_PERSONAL_DETAILS,
  ENABLE_MEMBERS,
  RESET_ENABLE_MEMBERS,
  RESET_SAVE_USER_ACTIVITY_DETAILS,
  SAVE_USER_ACTIVITY_DETAILS
} from '../constants/admin';

const dataObj = {
  fetching: false,
  data: {},
};
const dataArrObj = {
  fetching: false,
  data: [],
};
const saveObj = {
  saving: false,
  data: null,
};
export const initialState = {
  personDetails: dataObj,
  contactDetails: dataObj,
  documentDetails: dataObj,
  educationDetails: dataObj,
  bankDetails: dataObj,
  employeeDetails: dataObj,
  jobDetails: dataObj,
  allGroupRegDetails: dataObj,
  allMembers: dataArrObj,
  groupDetails: dataObj,
  groupContactDetails: dataObj,
  groupDocumentDetails: dataObj,
  groupBankDetails: dataObj,
  newGroupRegDetails: dataObj,
  allCompanyRegDetails: dataObj,
  companyDetails: dataObj,
  companyContactDetails: dataObj,
  companyDocumentDetails: dataObj,
  companyBankDetails: dataObj,
  newCompanyRegDetails: dataObj,
  groupUserActivityDetails: dataArrObj,
  saveEnableMembers: saveObj,
  saveActivityDetails: saveObj,
};

const adminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ADMIN_PERSONAL_DETAILS.Request: {
    return {
      ...state,
      personDetails: {
        fetching: true,
        data: {},
      },
    };
  }
  case ADMIN_PERSONAL_DETAILS.Success: {
    return {
      ...state,
      personDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_PERSONAL_DETAILS.Fail: {
    return {
      ...state,
      personDetails: dataObj,
    };
  }
  case ADMIN_CONTACT_DETAILS.Request: {
    return {
      ...state,
      contactDetails: {
        fetching: true,
        data: {},
      },
    };
  }
  case ADMIN_CONTACT_DETAILS.Success: {
    return {
      ...state,
      contactDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_CONTACT_DETAILS.Fail: {
    return {
      ...state,
      contactDetails: dataObj,
    };
  }
  case ADMIN_DOCUMENT_DETAILS.Request: {
    return {
      ...state,
      documentDetails: {
        fetching: true,
        data: {},
      },
    };
  }
  case ADMIN_DOCUMENT_DETAILS.Success: {
    return {
      ...state,
      documentDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_DOCUMENT_DETAILS.Fail: {
    return {
      ...state,
      documentDetails: dataObj,
    };
  }
  case ADMIN_EDUCATION_DETAILS.Request: {
    return {
      ...state,
      educationDetails: {
        fetching: true,
        data: {},
      },
    };
  }
  case ADMIN_EDUCATION_DETAILS.Success: {
    return {
      ...state,
      educationDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_EDUCATION_DETAILS.Fail: {
    return {
      ...state,
      educationDetails: dataObj,
    };
  }
  case ADMIN_BANK_DETAILS.Request: {
    return {
      ...state,
      bankDetails: {
        fetching: true,
        data: {},
      },
    };
  }
  case ADMIN_BANK_DETAILS.Success: {
    return {
      ...state,
      bankDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_BANK_DETAILS.Fail: {
    return {
      ...state,
      bankDetails: dataObj,
    };
  }
  case ADMIN_EMPLOYEE_DETAILS.Request: {
    return {
      ...state,
      employeeDetails: {
        fetching: true,
        data: {},
      },
    };
  }
  case ADMIN_EMPLOYEE_DETAILS.Success: {
    return {
      ...state,
      employeeDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_EMPLOYEE_DETAILS.Fail: {
    return {
      ...state,
      employeeDetails: dataObj,
    };
  }
  case ADMIN_JOB_DETAILS.Request: {
    return {
      ...state,
      jobDetails: {
        fetching: true,
        data: {},
      },
    };
  }
  case ADMIN_JOB_DETAILS.Success: {
    return {
      ...state,
      jobDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_JOB_DETAILS.Fail: {
    return {
      ...state,
      jobDetails: dataObj,
    };
  }
  case ADMIN_ALL_GROUP_REG_DETAILS.Request: {
    return {
      ...state,
      allGroupRegDetails: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_ALL_GROUP_REG_DETAILS.Success: {
    return {
      ...state,
      allGroupRegDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_ALL_GROUP_REG_DETAILS.Fail: {
    return {
      ...state,
      allGroupRegDetails: dataObj,
    };
  }
  case ADMIN_ALL_MEMBER_REG_DETAILS.Request: {
    return {
      ...state,
      allMembers: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_ALL_MEMBER_REG_DETAILS.Success: {
    return {
      ...state,
      allMembers: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_ALL_MEMBER_REG_DETAILS.Fail: {
    return {
      ...state,
      allMembers: dataObj,
    };
  }
  case ADMIN_GROUP_DETAILS.Request: {
    return {
      ...state,
      groupDetails: {
        fetching: true,
        data: {},
      },
    };
  }
  case ADMIN_GROUP_DETAILS.Success: {
    return {
      ...state,
      groupDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_GROUP_DETAILS.Fail: {
    return {
      ...state,
      groupDetails: dataObj,
    };
  }
  case ADMIN_GROUP_CONTACT_DETAILS.Request: {
    return {
      ...state,
      groupContactDetails: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_GROUP_CONTACT_DETAILS.Success: {
    return {
      ...state,
      groupContactDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_GROUP_CONTACT_DETAILS.Fail: {
    return {
      ...state,
      groupContactDetails: dataObj,
    };
  }
  case ADMIN_GROUP_DOCUMENT_DETAILS.Request: {
    return {
      ...state,
      groupDocumentDetails: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_GROUP_DOCUMENT_DETAILS.Success: {
    return {
      ...state,
      groupDocumentDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_GROUP_DOCUMENT_DETAILS.Fail: {
    return {
      ...state,
      groupDocumentDetails: dataObj,
    };
  }
  case ADMIN_GROUP_BANK_DETAILS.Request: {
    return {
      ...state,
      groupBankDetails: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_GROUP_BANK_DETAILS.Success: {
    return {
      ...state,
      groupBankDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_GROUP_BANK_DETAILS.Fail: {
    return {
      ...state,
      groupBankDetails: dataObj,
    };
  }
  case ADMIN_NEW_GROUP_REG_DETAILS.Request: {
    return {
      ...state,
      newGroupRegDetails: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_NEW_GROUP_REG_DETAILS.Success: {
    return {
      ...state,
      newGroupRegDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_NEW_GROUP_REG_DETAILS.Fail: {
    return {
      ...state,
      newGroupRegDetails: dataObj,
    };
  }
  case ADMIN_ALL_COMPANY_REG_DETAILS.Request: {
    return {
      ...state,
      allCompanyRegDetails: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_ALL_COMPANY_REG_DETAILS.Success: {
    return {
      ...state,
      allCompanyRegDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_ALL_COMPANY_REG_DETAILS.Fail: {
    return {
      ...state,
      allCompanyRegDetails: dataObj,
    };
  }
  case ADMIN_COMPANY_DETAILS.Request: {
    return {
      ...state,
      companyDetails: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_COMPANY_DETAILS.Success: {
    return {
      ...state,
      companyDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_COMPANY_DETAILS.Fail: {
    return {
      ...state,
      companyDetails: dataObj,
    };
  }
  case ADMIN_COMPANY_CONTACT_DETAILS.Request: {
    return {
      ...state,
      companyContactDetails: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_COMPANY_CONTACT_DETAILS.Success: {
    return {
      ...state,
      companyContactDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_COMPANY_CONTACT_DETAILS.Fail: {
    return {
      ...state,
      companyContactDetails: dataObj,
    };
  }
  case ADMIN_COMPANY_DOCUMENT_DETAILS.Request: {
    return {
      ...state,
      companyDocumentDetails: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_COMPANY_DOCUMENT_DETAILS.Success: {
    return {
      ...state,
      companyDocumentDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_COMPANY_DOCUMENT_DETAILS.Fail: {
    return {
      ...state,
      companyDocumentDetails: dataObj,
    };
  }
  case ADMIN_COMPANY_BANK_DETAILS.Request: {
    return {
      ...state,
      companyBankDetails: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_COMPANY_BANK_DETAILS.Success: {
    return {
      ...state,
      companyBankDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_COMPANY_BANK_DETAILS.Fail: {
    return {
      ...state,
      companyBankDetails: dataObj,
    };
  }
  case ADMIN_NEW_COMPANY_REG_DETAILS.Request: {
    return {
      ...state,
      newCompanyRegDetails: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_NEW_COMPANY_REG_DETAILS.Success: {
    return {
      ...state,
      newCompanyRegDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_NEW_COMPANY_REG_DETAILS.Fail: {
    return {
      ...state,
      newCompanyRegDetails: dataObj,
    };
  }
  case ADMIN_GROUP_USER_ACTIVITY_DETAILS.Request: {
    return {
      ...state,
      groupUserActivityDetails: {
        fetching: true,
        data: [],
      },
    };
  }
  case ADMIN_GROUP_USER_ACTIVITY_DETAILS.Success: {
    return {
      ...state,
      groupUserActivityDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADMIN_GROUP_USER_ACTIVITY_DETAILS.Fail: {
    return {
      ...state,
      groupUserActivityDetails: dataArrObj,
    };
  }
  case ENABLE_MEMBERS.Request: {
    return {
      ...state,
      saveEnableMembers: {
        ...saveObj,
        saving: true,
      },
    };
  }
  case ENABLE_MEMBERS.Success: {
    return {
      ...state,
      saveEnableMembers: {
        saving: false,
        data: payload,
      },
    };
  }
  case ENABLE_MEMBERS.Fail: {
    return {
      ...state,
      saveEnableMembers: {
        ...saveObj,
        hasErrors: true,
      },
    };
  }
  case RESET_ENABLE_MEMBERS: {
    return {
      ...state,
      saveEnableMembers: saveObj,
    };
  }
  case SAVE_USER_ACTIVITY_DETAILS.Request: {
    return {
      ...state,
      saveActivityDetails: {
        ...saveObj,
        saving: true,
      },
    };
  }
  case SAVE_USER_ACTIVITY_DETAILS.Success: {
    return {
      ...state,
      saveActivityDetails: {
        saving: false,
        data: payload,
      },
    };
  }
  case SAVE_USER_ACTIVITY_DETAILS.Fail: {
    return {
      ...state,
      saveActivityDetails: {
        ...saveObj,
        hasErrors: true,
      },
    };
  }
  case RESET_SAVE_USER_ACTIVITY_DETAILS: {
    return {
      ...state,
      saveActivityDetails: saveObj,
    };
  }
  default: return state;
  }
};

export default adminReducer;
