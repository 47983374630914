import React from 'react';
import { Navigate, useLocation, useOutlet } from 'react-router-dom';
import { Box, Container, Toolbar } from '@mui/material';
import menu from '../constants/menu';
import { useAuth } from '../hooks/useAuth';
import PathUtils from '../utils/pathUtils';
import AdminAppBar from '../components/AdminAppBar';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import { setAuthenticateToken } from '../api/auth';
import { hasGroupAdminRole } from '../constants/roles';
import { GROUP_MENU_IDS } from '../constants/global';

const ProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  const location = useLocation();
  const { pathname } = location;

  if (!user) {
    return <Navigate to='/' />;
  } else {
    const hasCorrectPath = PathUtils.hasCorrectPath(user, pathname);
    if (!hasCorrectPath) {
      return <Navigate to='/' />;
    }
    const { token } = user;
    setAuthenticateToken(token);
  }
  const menuName = PathUtils.getMenuName(user);
  const menuItems = menu[menuName];
  if (menuName === 'group' || menuName === 'company') {
    const groupAdminRole = hasGroupAdminRole(user);
    if (!groupAdminRole) {
      const { pages } = menuItems;
      menuItems.pages = pages.filter(({ id }) => GROUP_MENU_IDS.indexOf(id) >= 0);
    }
  }
  const padding = menuName !== 'member' ? { p: 2 } : {};
  return (
    <Box sx={{ display: 'flex' }}>
      { menuName === 'member' ? <ResponsiveAppBar menuItems={menuItems}/> : <AdminAppBar menuItems={menuItems}/>}
      <Box component="main" sx={{ flexGrow: 1, height: '100vh', width: '100%', ...padding }}>
        <Toolbar />
        {
          menuName === 'member'
            ? (
              <Container maxWidth="xl" sx={{
                py: 2,
              }}>
                {outlet}
              </Container>
            )
            : outlet
        }
      </Box>
    </Box>
  );
};

export default ProtectedLayout;
