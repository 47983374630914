import { combineReducers } from 'redux';
import groupReducer from './group';
import userReducer from './auth';
import commonReducer from './common';
import memberReducer from './member';
import adminReducer from './admin';
import contactReducer from './contact';
import filterReducer from './filter';

export default combineReducers({
  user: userReducer,
  group: groupReducer,
  common: commonReducer,
  member: memberReducer,
  admin: adminReducer,
  contact: contactReducer,
  filter: filterReducer,
});
