/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { actionSignOut } from '../actions/auth';
import UserAvatar from './UserAvatar';
import NotificationDialog from './Common/NotificationDialog';
import { actionDeleteNotifications, actionGetNotifications } from '../actions/common';
import PathUtils from '../utils/pathUtils';

const UserMenu = props => {
  const { handleCloseNavMenu, userMenu } = props;
  const { logout, user } = useAuth();
  const userDispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  const state = useSelector(({
    common: {
      notifications,
    },
  }) => ({
    notifications,
  }));

  useEffect(() => {
    if (PathUtils.isGroupUser(user)) {
      userDispatch(actionGetNotifications());
    }
  }, []);
  const { notifications: { data: notificationData = [] } = {} } = state;
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = index => {
    setSelectedIndex(index);
  };

  const handleNotifications = () => {
    if (notificationData.length) {
      setOpen(true);
    }
  };

  const onDelete = id => {
    userDispatch(actionDeleteNotifications(id));
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        size="large"
        aria-label={`Show ${notificationData.length} new Notifications`}
        color="inherit"
        onClick={handleNotifications}
      >
        <Badge badgeContent={notificationData.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <UserAvatar
          fontSize="1.25rem"
          text={user.username}
          size={40}
          fileId={user.fileId}
          uploadCount={0}
        />
      </IconButton>
      <Menu
        sx={{ mt: '45px', p: 1 }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        keepMounted
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {userMenu.map(({ label, icon, path }, index) => (
          <MenuItem key={label} selected={index === selectedIndex} onClick={() => {
            handleCloseNavMenu(path);
            handleMenuItemClick(index);
            handleCloseUserMenu();
          }}>
            <ListItemIcon>
              {icon}
            </ListItemIcon>
            <Typography textAlign="center">{label}</Typography>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem key={'logout'} onClick={() => {
          userDispatch(actionSignOut());
          logout();
        }}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
      <NotificationDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onDelete={onDelete}
        data={notificationData}
      />
    </Box>
  );
};

export default UserMenu;
