import {
  GET_DOCUMENT_DETAILS,
  MEMBER_BANK_DETAILS,
  MEMBER_CONTACT_DETAILS,
  MEMBER_EDUCATION_DETAILS,
  MEMBER_EMPLOYEE_DETAILS,
  MEMBER_JOB_DETAILS,
  MEMBER_PERSONAL_DETAILS,
  MEMBER_REGISTRATION_DETAILS,
  SAVE_MEMBER_PERSONAL_DETAILS
} from '../constants/member';

const dataObj = {
  fetching: false,
  data: {},
};

export const initialState = {
  personDetails: dataObj,
  contactDetails: dataObj,
  savePersonDetails: dataObj,
  getDocumentDetails: dataObj,
  educationDetails: dataObj,
  bankDetails: dataObj,
  employeeDetails: dataObj,
  jobDetails: dataObj,
  registrationDetails: dataObj,
};

const memberReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case MEMBER_PERSONAL_DETAILS.Request: {
    return {
      ...state,
      personDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_PERSONAL_DETAILS.Success: {
    return {
      ...state,
      personDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_PERSONAL_DETAILS.Fail: {
    return {
      ...state,
      personDetails: dataObj,
    };
  }
  case MEMBER_CONTACT_DETAILS.Request: {
    return {
      ...state,
      contactDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_CONTACT_DETAILS.Success: {
    return {
      ...state,
      contactDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_CONTACT_DETAILS.Fail: {
    return {
      ...state,
      contactDetails: dataObj,
    };
  }
  case SAVE_MEMBER_PERSONAL_DETAILS.Request: {
    return {
      ...state,
      savePersonDetails: {
        fetching: true,
      },
    };
  }
  case SAVE_MEMBER_PERSONAL_DETAILS.Success: {
    return {
      ...state,
      savePersonDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case SAVE_MEMBER_PERSONAL_DETAILS.Fail: {
    return {
      ...state,
      savePersonDetails: dataObj,
    };
  }
  case GET_DOCUMENT_DETAILS.Request: {
    return {
      ...state,
      getDocumentDetails: {
        fetching: true,
      },
    };
  }
  case GET_DOCUMENT_DETAILS.Success: {
    return {
      ...state,
      getDocumentDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_DOCUMENT_DETAILS.Fail: {
    return {
      ...state,
      getDocumentDetails: dataObj,
    };
  }
  case MEMBER_EDUCATION_DETAILS.Request: {
    return {
      ...state,
      educationDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_EDUCATION_DETAILS.Success: {
    return {
      ...state,
      educationDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_EDUCATION_DETAILS.Fail: {
    return {
      ...state,
      educationDetails: dataObj,
    };
  }
  case MEMBER_BANK_DETAILS.Request: {
    return {
      ...state,
      bankDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_BANK_DETAILS.Success: {
    return {
      ...state,
      bankDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_BANK_DETAILS.Fail: {
    return {
      ...state,
      bankDetails: dataObj,
    };
  }
  case MEMBER_EMPLOYEE_DETAILS.Request: {
    return {
      ...state,
      bankDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_EMPLOYEE_DETAILS.Success: {
    return {
      ...state,
      bankDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_EMPLOYEE_DETAILS.Fail: {
    return {
      ...state,
      bankDetails: dataObj,
    };
  }
  case MEMBER_JOB_DETAILS.Request: {
    return {
      ...state,
      jobDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_JOB_DETAILS.Success: {
    return {
      ...state,
      jobDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_JOB_DETAILS.Fail: {
    return {
      ...state,
      jobDetails: dataObj,
    };
  }
  case MEMBER_REGISTRATION_DETAILS.Request: {
    return {
      ...state,
      registrationDetails: {
        fetching: true,
      },
    };
  }
  case MEMBER_REGISTRATION_DETAILS.Success: {
    return {
      ...state,
      registrationDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case MEMBER_REGISTRATION_DETAILS.Fail: {
    return {
      ...state,
      registrationDetails: dataObj,
    };
  }
  default: return state;
  }
};
export default memberReducer;
