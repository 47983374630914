import { ActionType, RequestActionType } from '../actions/utils';

export const ACTIVE_MEMBERS = RequestActionType('ACTIVE_MEMBERS');
export const CONTACT_TYPE = RequestActionType('CONTACT_TYPE');
export const DOCUMENT_TYPE = RequestActionType('DOCUMENT_TYPE');
export const BANK_MASTER = RequestActionType('BANK_MASTER');
export const BLOOD_GROUP = RequestActionType('BLOOD_GROUP');
export const WORK_TIME_MASTER = RequestActionType('WORK_TIME_MASTER');
export const PAID_BASIC_MASTER = RequestActionType('PAID_BASIC_MASTER');
export const ALL_ROLE_GROUP_ADMIN = RequestActionType('ALL_ROLE_GROUP_ADMIN');
export const ALL_ROLE_GROUP_ACTIVITIES = RequestActionType('ALL_ROLE_GROUP_ACTIVITIES');

export const GET_ALL_MATERIALS = RequestActionType('GET_ALL_MATERIALS');
export const GET_MATERIAL = RequestActionType('GET_MATERIAL');
export const SAVE_MATERIAL_IMAGES = RequestActionType('SAVE_MATERIAL_IMAGES');
export const GET_CURRENCY_CODES = RequestActionType('GET_CURRENCY_CODES');
export const ADD_DESCRIPTION = RequestActionType('ADD_DESCRIPTION');
export const GET_DESCRIPTION = RequestActionType('GET_DESCRIPTION');
export const DELETE_PRICE = RequestActionType('DELETE_PRICE');

export const GET_MATERIAL_DETAILS = RequestActionType('GET_MATERIAL_DETAILS');
export const ADD_MATERIAL_DETAILS = RequestActionType('ADD_MATERIAL_DETAILS');
export const RESET_ADD_MATERIAL_DETAILS = ActionType('RESET_ADD_MATERIAL_DETAILS');

export const GET_LATEST_PRICE = RequestActionType('GET_LATEST_PRICE');

export const PRICE_REQUEST = RequestActionType('PRICE_REQUEST');
export const RESET_PRICE_REQUEST = ActionType('RESET_PRICE_REQUEST');
export const PRICE_REQUEST_APPROVE = RequestActionType('PRICE_REQUEST_APPROVE');
export const PRICE_REQUEST_REJECT = RequestActionType('PRICE_REQUEST_REJECT');
export const GET_PRICE_REQUESTS = RequestActionType('GET_PRICE_REQUESTS');

export const GET_NOTIFICATIONS = RequestActionType('GET_NOTIFICATIONS');
export const DELETE_NOTIFICATION = RequestActionType('DELETE_NOTIFICATION');
