import { ActionType, RequestActionType } from '../actions/utils';

export const PERSONAL_DETAILS = RequestActionType('PERSONAL_DETAILS');
export const SAVE_PERSONAL_DETAILS = RequestActionType('SAVE_PERSONAL_DETAILS');
export const SAVE_EDUCATION_DETAILS = RequestActionType('SAVE_EDUCATION_DETAILS');
export const RESET_SAVE_EDUCATION_DETAILS = ActionType('RESET_SAVE_EDUCATION_DETAILS');
export const DELETE_EDUCATION_DETAILS = RequestActionType('DELETE_EDUCATION_DETAILS');

export const GET_EMPLOYMENT_DETAILS = RequestActionType('GET_EMPLOYMENT_DETAILS');
export const SAVE_EMPLOYMENT_DETAILS = RequestActionType('SAVE_EMPLOYMENT_DETAILS');
export const RESET_SAVE_EMPLOYMENT_DETAILS = ActionType('RESET_SAVE_EMPLOYMENT_DETAILS');
export const DELETE_EMPLOYMENT_DETAILS = RequestActionType('DELETE_EMPLOYMENT_DETAILS');
export const DELETE_EMPLOYMENT_WORK_DETAILS = RequestActionType('DELETE_EMPLOYMENT_WORK_DETAILS');

export const GET_JOB_DETAILS = RequestActionType('GET_JOB_DETAILS');
export const SAVE_JOB_DETAILS = RequestActionType('SAVE_JOB_DETAILS');
export const RESET_SAVE_JOB_DETAILS = ActionType('RESET_SAVE_JOB_DETAILS');
export const DELETE_JOB_DETAILS = RequestActionType('DELETE_JOB_DETAILS');
export const DELETE_JOB_WORK_DETAILS = RequestActionType('DELETE_JOB_WORK_DETAILS');

export const GET_USER_DETAILS = RequestActionType('GET_USER_DETAILS');
export const SAVE_USER_DETAILS = RequestActionType('SAVE_USER_DETAILS');

export const DELETE_EDUCATION_DOCUMENT = RequestActionType('DELETE_EDUCATION_DOCUMENT');
