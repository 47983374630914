/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import UserMenu from './UserMenu';
import Logo from './Logo';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const drawerWidth = 240;

const AdminAppBar = ({ menuItems, window }) => {
  const { pages, user: userMenu = [] } = menuItems;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [subMenu, setSubMenu] = React.useState({});
  const navigate = useNavigate();
  const handleCloseNavMenu = (path, hasChildren, id) => {
    if (path) {
      setMobileOpen(false);
      navigate(path);
    }
    if (hasChildren) {
      setSubMenu({
        ...subMenu,
        [id]: !subMenu[id],
      });
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <>
      <Toolbar>Menu</Toolbar>
      <Box sx={{ overflow: 'auto' }}>
        <List component="nav">
          {pages.map(({ id, label, icon, path, children }) => (
            <>
              <ListItem key={label} disablePadding onClick={() => handleCloseNavMenu(path, !!children, id)}>
                <ListItemButton>
                  <ListItemIcon>
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={label} />
                  {
                    children
                      ? !subMenu[id] ? <ExpandLess /> : <ExpandMore />
                      : null
                  }
                </ListItemButton>
              </ListItem>
              {
                children
                  ? (
                    <Collapse in={!subMenu[id]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {
                          children.map(({ label, icon, path }) => (
                            <ListItemButton key={label} sx={{ pl: 4 }} onClick={() => handleCloseNavMenu(path)}>
                              <ListItemIcon>
                                {icon}
                              </ListItemIcon>
                              <ListItemText primary={label} />
                            </ListItemButton>
                          ))
                        }
                      </List>
                    </Collapse>
                  )
                  : null
              }
            </>
          ))}
        </List>
      </Box>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const zIndex = createTheme().zIndex.drawer;
  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: { md: zIndex + 1 } }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Logo />
          <Box sx={{ flexGrow: 1 }} />
          <UserMenu userMenu={userMenu} handleCloseNavMenu={handleCloseNavMenu} />
        </Toolbar>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { md: 'block', sm: 'none', xs: 'none' },
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

AdminAppBar.propTypes = {
  window: PropTypes.func,
};

export default AdminAppBar;
