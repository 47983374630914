import axios from 'axios';
import BASE_API_URL from '../constants/global';
import MocksProvider from './mocks';

const mockedMode = false;
export const signUser = mockedMode
  ? MocksProvider.resolve('user', 'signUser')
  : user => axios.post(`${BASE_API_URL}/auth/signin`, user);

export const setAuthenticateToken = token => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

axios.interceptors.response.use(undefined, error => {
  const statusCode = error.response ? error.response.status : null;
  if (statusCode === 403 || statusCode === 401) {
    window.localStorage.setItem('user', null);
    location.reload();
  }
  return Promise.reject(error);
});

export const signUp = formData => axios.post(`${BASE_API_URL}/auth/signup`, formData);
export const sendOTP = data => axios.post(`${BASE_API_URL}/otp/sendOTP`, data);
export const validateOTP = data => axios.post(`${BASE_API_URL}/otp/validateOTP`, data);

export const verifyUser = formData => axios.post(`${BASE_API_URL}/auth/verifyUser`, formData);
export const reset = formData => axios.post(`${BASE_API_URL}/auth/reset`, formData);
