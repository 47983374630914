import { lazy, React } from 'react';
import Loadable from './LazyComponents/Loadable';
import ProtectedLayout from '../layout/ProtectedLayout';

const GroupMaterialData = Loadable(lazy(() => import('../pages/GroupMaterialData')));

const MainRoutes = {
  path: '/dashboard',
  element: <ProtectedLayout />,
  children: [
    {
      path: 'group',
      element: <GroupMaterialData />,
    },
  ],
};

export default MainRoutes;
