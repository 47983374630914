import { ActionType, RequestActionType } from '../actions/utils';

export const ADMIN_CONTACT_DETAILS = RequestActionType('ADMIN_CONTACT_DETAILS');
export const ADMIN_PERSONAL_DETAILS = RequestActionType('ADMIN_PERSONAL_DETAILS');
export const ADMIN_DOCUMENT_DETAILS = RequestActionType('ADMIN_DOCUMENT_DETAILS');
export const ADMIN_EDUCATION_DETAILS = RequestActionType('ADMIN_EDUCATION_DETAILS');
export const ADMIN_BANK_DETAILS = RequestActionType('ADMIN_BANK_DETAILS');
export const ADMIN_EMPLOYEE_DETAILS = RequestActionType('ADMINs_EMPLOYEE_DETAILS');
export const ADMIN_JOB_DETAILS = RequestActionType('ADMIN_JOB_DETAILS');
export const ADMIN_ALL_GROUP_REG_DETAILS = RequestActionType('ADMIN_ALL_GROUP_REG_DETAILS');
export const ADMIN_ALL_MEMBER_REG_DETAILS = RequestActionType('ADMIN_ALL_MEMBER_REG_DETAILS');
export const ADMIN_GROUP_DETAILS = RequestActionType('ADMIN_GROUP_DETAILS');
export const ADMIN_GROUP_CONTACT_DETAILS = RequestActionType('ADMIN_GROUP_CONTACT_DETAILS');
export const ADMIN_GROUP_DOCUMENT_DETAILS = RequestActionType('ADMIN_GROUP_DOCUMENT_DETAILS');
export const ADMIN_GROUP_BANK_DETAILS = RequestActionType('ADMIN_GROUP_BANK_DETAILS');
export const ADMIN_NEW_GROUP_REG_DETAILS = RequestActionType('ADMIN_NEW_GROUP_REG_DETAILS');
export const ADMIN_ALL_COMPANY_REG_DETAILS = RequestActionType('ADMIN_ALL_COMPANY_REG_DETAILS');
export const ADMIN_COMPANY_DETAILS = RequestActionType('ADMIN_COMPANY_DETAILS');
export const ADMIN_COMPANY_CONTACT_DETAILS = RequestActionType('ADMIN_COMPANY_CONTACT_DETAILS');
export const ADMIN_COMPANY_DOCUMENT_DETAILS = RequestActionType('ADMIN_COMPANY_DOCUMENT_DETAILS');
export const ADMIN_COMPANY_BANK_DETAILS = RequestActionType('ADMIN_COMPANY_BANK_DETAILS');
export const ADMIN_NEW_COMPANY_REG_DETAILS = RequestActionType('ADMIN_NEW_COMPANY_REG_DETAILS');
export const ADMIN_GROUP_USER_ACTIVITY_DETAILS = RequestActionType('ADMIN_GROUP_USER_ACTIVITY_DETAILS');

export const ENABLE_MEMBERS = RequestActionType('ENABLE_MEMBERS');
export const RESET_ENABLE_MEMBERS = ActionType('RESET_ENABLE_MEMBERS');

export const SAVE_USER_ACTIVITY_DETAILS = RequestActionType('SAVE_USER_ACTIVITY_DETAILS');
export const RESET_SAVE_USER_ACTIVITY_DETAILS = ActionType('RESET_SAVE_USER_ACTIVITY_DETAILS');
