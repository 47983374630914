import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { getAllActiveMembers, getAllContactType, getAllDocType, getBankMaster, getCurrencyCodes, saveMaterialMaster, uploadDocument } from '../api/common';
import { getBankDetails, getContactDetails, getDocumentDetails } from '../api/contact';
import {
  addMaterialBrand,
  addMaterialSpec,
  addPackage,
  addSubActNameDesc,
  addUnit,
  deleteBankDetails,
  deleteContactDetails,
  deleteDocDetails,
  deleteDocument,
  getAllMadeCountry,
  getAllMaterialBrand,
  getAllPackage,
  getAllRoleGroupAdmin,
  getAllTeamMembers,
  getAllUnit,
  getGroupDetails,
  getGroupMaterial,
  getGroupMaterialSelection,
  getGroupTeamMember,
  getMaterialSpec,
  getSubActNameDesc,
  loadGrpActItem,
  loadGrpSubActItem,
  saveBankDetails,
  saveContactDetails,
  saveDocumentDetails,
  saveGroupDetails,
  saveGroupTeamContact
} from '../api/group';
import { ACTIVE_MEMBERS, BANK_MASTER, CONTACT_TYPE, DOCUMENT_TYPE, GET_ALL_MATERIALS, GET_CURRENCY_CODES, SAVE_MATERIAL_IMAGES } from '../constants/common';
import { ACTIVITIES, BRANDS, MADE_IN_COUNTRIES, MODELS, NAMES, SPECIFICATIONS, SUB_ACTIVITIES } from '../constants/filter';
import {
  ADD_BRANDS,
  ADD_PACKAGE,
  ADD_SPECIFICATIONS,
  ADD_SUB_ACTIVITY_NAMES,
  ADD_UNITS,
  DELETE_BANK_DETAILS,
  DELETE_CONTACT_DETAILS,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_DETAILS,
  GET_ACTIVITIES,
  GET_BRANDS,
  GET_COUNTRIES,
  GET_GROUP_DETAILS,
  GET_PACKAGE,
  GET_SPECIFICATIONS,
  GET_SUB_ACTIVITIES,
  GET_SUB_ACTIVITY_NAMES,
  GET_UNITS,
  GROUP_ADMIN_ROLES,
  GROUP_BANK_DETAILS,
  GROUP_CONTACT_DETAILS,
  GROUP_DOCUMENT_DETAILS,
  GROUP_MATERIAL,
  GROUP_MATERIAL_SELECTION,
  GROUP_TEAMS_CONTACT,
  GROUP_TEAM_MEMBER,
  SAVE_BANK_DETAILS,
  SAVE_CONTACT_DETAILS,
  SAVE_DOCUMENT_DETAILS,
  SAVE_GROUP_DETAILS,
  SAVE_GROUP_TEAM_MEMBER,
  SAVE_MATERIAL_MASTER
} from '../constants/group';
import FormUtils from '../utils/formUtils';
import { snackActions } from '../utils/SnackBarUtils';

export function * getGroupMaterialSaga({ payload }) {
  try {
    yield delay(3000);
    const response = yield call(getGroupMaterial, payload);
    const { data: { data } } = response;
    yield put({
      type: GROUP_MATERIAL.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GROUP_MATERIAL.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getGroupMaterialSelectionSaga({ payload }) {
  try {
    const response = yield call(getGroupMaterialSelection, FormUtils.getFilterQuery(payload));
    const { data } = response;
    yield put({
      type: GROUP_MATERIAL_SELECTION.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GROUP_MATERIAL_SELECTION.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllTeamMembersSaga({ payload }) {
  try {
    const response = yield call(getAllTeamMembers, payload);
    const { data } = response;
    yield put({
      type: GROUP_TEAMS_CONTACT.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GROUP_TEAMS_CONTACT.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllRoleGroupAdminSaga() {
  try {
    const response = yield call(getAllRoleGroupAdmin);
    const { data } = response;
    yield put({
      type: GROUP_ADMIN_ROLES.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GROUP_ADMIN_ROLES.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getGroupTeamMemberSaga({ payload }) {
  try {
    const response = yield call(getGroupTeamMember, payload);
    const { data } = response;
    yield put({
      type: GROUP_TEAM_MEMBER.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GROUP_TEAM_MEMBER.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * loadGrpSubActItemSaga({ payload }) {
  try {
    const response = yield call(loadGrpSubActItem, payload);
    const { data } = response;
    yield put({
      type: GET_SUB_ACTIVITIES.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GET_SUB_ACTIVITIES.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * loadGrpActItemSaga() {
  try {
    const response = yield call(loadGrpActItem);
    const { data } = response;
    yield put({
      type: GET_ACTIVITIES.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GET_ACTIVITIES.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllMaterialBrandSaga() {
  try {
    const response = yield call(getAllMaterialBrand);
    const { data: { brandDetail: { keyValuePairList: data } } } = response;
    yield put({
      type: GET_BRANDS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GET_BRANDS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllMadeCountrySaga() {
  try {
    const response = yield call(getAllMadeCountry);
    const { data: { madeDetail: { keyValuePairList: data } } } = response;
    yield put({
      type: GET_COUNTRIES.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GET_COUNTRIES.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllPackageSaga() {
  try {
    const response = yield call(getAllPackage);
    const { data: { packageDetail: { keyValuePairList: data } } } = response;
    yield put({
      type: GET_PACKAGE.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GET_PACKAGE.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getAllUnitSaga() {
  try {
    const response = yield call(getAllUnit);
    const { data: { unitDetail: { keyValuePairList: data } } } = response;
    yield put({
      type: GET_UNITS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GET_UNITS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getSubActNameDescSaga({ payload }) {
  try {
    const response = yield call(getSubActNameDesc, payload);
    const { data: { subActNameDetail: { keyValuePairList: data } } } = response;
    yield put({
      type: GET_SUB_ACTIVITY_NAMES.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GET_SUB_ACTIVITY_NAMES.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * getMaterialSpecSaga({ payload }) {
  try {
    const response = yield call(getMaterialSpec, payload);
    const { data: { specDetail: { keyValuePairList: data } } } = response;
    yield put({
      type: GET_SPECIFICATIONS.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GET_SPECIFICATIONS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the data');
  }
}

export function * saveGroupTeamContactSaga({ payload }) {
  try {
    const { filters, data } = payload;
    const response = yield call(saveGroupTeamContact, data);
    const { data: { responseCode, responseDesc } } = response;
    if (responseCode === '2105') {
      snackActions.error(responseDesc);
      yield put({
        type: SAVE_GROUP_TEAM_MEMBER.Fail,
      });
    } else {
      snackActions.success('Member data saved successfully');
      yield put({
        type: SAVE_GROUP_TEAM_MEMBER.Success,
        payload: 'success',
      });
      yield put({
        type: GROUP_TEAMS_CONTACT.Request,
        payload: filters,
      });
    }
  } catch (e) {
    snackActions.error('Could not able to the data member');
    yield put({
      type: SAVE_GROUP_TEAM_MEMBER.Fail,
    });
  }
}

export function * saveMaterialMasterSaga({ payload }) {
  try {
    const { filters, data, attachments } = payload;
    const response = yield call(saveMaterialMaster, data);
    const { data: { materialId, isNew, responseCode, responseDesc, matId } } = response;
    if (responseCode) {
      snackActions.error(responseDesc);
      yield put({
        type: SAVE_MATERIAL_MASTER.Fail,
      });
    } else {
      if (isNew) {
        snackActions.success(`New Material added with ${materialId}`);
        if (attachments) {
          yield put({
            type: SAVE_MATERIAL_IMAGES.Request,
            payload: { materialId: matId, attachments },
          });
        }
      } else {
        snackActions.success(`Material ${materialId} updated successfully`);
      }
      yield put({
        type: SAVE_MATERIAL_MASTER.Success,
        payload: 'success',
      });
      yield put({
        type: GET_ALL_MATERIALS.Request,
        payload: filters,
      });
      yield put({
        type: ACTIVITIES.Request,
      });
      yield put({
        type: SUB_ACTIVITIES.Request,
      });
      yield put({
        type: NAMES.Request,
      });
      yield put({
        type: BRANDS.Request,
      });
      yield put({
        type: MODELS.Request,
      });
      yield put({
        type: SPECIFICATIONS.Request,
      });
      yield put({
        type: MADE_IN_COUNTRIES.Request,
      });
    }
  } catch (e) {
    snackActions.error('Could not able to the data member');
    yield put({
      type: SAVE_MATERIAL_MASTER.Fail,
    });
  }
}

export function * getGroupDetailsSaga({ payload }) {
  try {
    const [memberRes, detailRes, currencyRes] = yield all([
      call(getAllActiveMembers),
      call(getGroupDetails, payload),
      call(getCurrencyCodes),
    ]);
    const { data: { groupDetails } } = detailRes;
    const { data } = memberRes;
    yield put({
      type: ACTIVE_MEMBERS.Success,
      payload: data,
    });
    yield put({
      type: GET_GROUP_DETAILS.Success,
      payload: groupDetails,
    });
    yield put({
      type: GET_CURRENCY_CODES.Success,
      payload: currencyRes.data,
    });
  } catch (e) {
    yield put({
      type: GET_GROUP_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the details');
  }
}

export function * saveGroupDetailsSaga({ payload }) {
  try {
    const { memberId, formData } = payload;
    const response = yield call(saveGroupDetails, formData, memberId);
    const { data: { responseCode, responseDesc } } = response;
    if (responseCode === '2105') {
      snackActions.error(responseDesc);
      yield put({
        type: SAVE_GROUP_DETAILS.Fail,
      });
    } else {
      snackActions.success('Details saved successfully');
      yield put({
        type: SAVE_GROUP_DETAILS.Success,
        payload: 'success',
      });
    }
  } catch (e) {
    snackActions.error('Could not able to save details');
    yield put({
      type: SAVE_GROUP_TEAM_MEMBER.Fail,
    });
  }
}

export function * addSubActNameDescSaga({ payload }) {
  try {
    const { subActId, data } = payload;
    const response = yield call(addSubActNameDesc, data);
    const { data: responseData } = response;
    snackActions.success('New Name added successfully');
    yield put({
      type: ADD_SUB_ACTIVITY_NAMES.Success,
      payload: responseData,
    });
    yield put({
      type: GET_SUB_ACTIVITY_NAMES.Request,
      payload: subActId,
    });
  } catch (e) {
    snackActions.error('Could able to added the Name');
    yield put({
      type: ADD_SUB_ACTIVITY_NAMES.Fail,
    });
  }
}

export function * addMaterialSpecSaga({ payload }) {
  try {
    const { nameId, data } = payload;
    const response = yield call(addMaterialSpec, data);
    const { data: responseData } = response;
    snackActions.success('New specification added successfully');
    yield put({
      type: ADD_SPECIFICATIONS.Success,
      payload: responseData,
    });
    yield put({
      type: GET_SPECIFICATIONS.Request,
      payload: nameId,
    });
  } catch (e) {
    snackActions.error('Could able to added the new specification');
    yield put({
      type: ADD_SPECIFICATIONS.Fail,
    });
  }
}

export function * addMaterialBrandSaga({ payload }) {
  try {
    const response = yield call(addMaterialBrand, payload);
    const { data: responseData } = response;
    snackActions.success('New Brand added successfully');
    yield put({
      type: ADD_BRANDS.Success,
      payload: responseData,
    });
    yield put({
      type: GET_BRANDS.Request,
    });
  } catch (e) {
    snackActions.error('Could able to added the new Brand');
    yield put({
      type: ADD_BRANDS.Fail,
    });
  }
}

export function * addPackageSaga({ payload }) {
  try {
    const response = yield call(addPackage, payload);
    const { data: responseData } = response;
    snackActions.success('New Package added successfully');
    yield put({
      type: ADD_PACKAGE.Success,
      payload: responseData,
    });
    yield put({
      type: GET_PACKAGE.Request,
    });
  } catch (e) {
    snackActions.error('Could able to added the new Package');
    yield put({
      type: ADD_PACKAGE.Fail,
    });
  }
}

export function * addUnitSaga({ payload }) {
  try {
    const response = yield call(addUnit, payload);
    const { data: responseData } = response;
    snackActions.success('New Unit added successfully');
    yield put({
      type: ADD_UNITS.Success,
      payload: responseData,
    });
    yield put({
      type: GET_UNITS.Request,
    });
  } catch (e) {
    snackActions.error('Could able to added the new Unit');
    yield put({
      type: ADD_UNITS.Fail,
    });
  }
}

export function * getGroupContactDetailsSaga({ payload }) {
  try {
    const [contactRes, contactTypeRes] = yield all([call(getContactDetails, payload), call(getAllContactType)]);
    const { data: contactDetails } = contactRes;
    const { data } = contactTypeRes;
    yield put({
      type: CONTACT_TYPE.Success,
      payload: data,
    });
    yield put({
      type: GROUP_CONTACT_DETAILS.Success,
      payload: contactDetails,
    });
  } catch (e) {
    yield put({
      type: GROUP_CONTACT_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the contact Details');
  }
}

export function * getGroupDocumentDetailsSaga({ payload }) {
  try {
    const [docRes, docTypesRes] = yield all([call(getDocumentDetails, payload), call(getAllDocType)]);
    call(getAllDocType);
    const { data: documentDetails } = docRes;
    yield put({
      type: GROUP_DOCUMENT_DETAILS.Success,
      payload: documentDetails,
    });
    const { data } = docTypesRes;
    yield put({
      type: DOCUMENT_TYPE.Success,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: GROUP_DOCUMENT_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the Document Details');
  }
}

export function * getGroupBankDetailsSaga({ payload }) {
  try {
    const [response, bankMaster] = yield all([call(getBankDetails, payload), call(getBankMaster)]);
    const { data: bankDetails } = response;
    yield put({
      type: GROUP_BANK_DETAILS.Success,
      payload: bankDetails,
    });
    yield put({
      type: BANK_MASTER.Success,
      payload: bankMaster.data,
    });
  } catch (e) {
    yield put({
      type: GROUP_BANK_DETAILS.Fail,
      payload: e,
    });
    snackActions.error('Could not able to fetch the Bank Details');
  }
}

export function * saveDocumentDetailsSaga({ payload }) {
  try {
    const { memberId, formData: { data, attachment } } = payload;
    const response = yield call(saveDocumentDetails, memberId, data);
    const { data: { docId, isNew, responseCode, responseDesc } } = response;
    if (responseCode) {
      snackActions.error(responseDesc);
      yield put({
        type: SAVE_DOCUMENT_DETAILS.Fail,
      });
    } else {
      if (attachment) {
        const formData = new FormData();
        formData.append('docTypeId', data.docTypeId);
        formData.append('attachment', attachment);
        try {
          const { data: fileResponse } = yield call(uploadDocument, formData, memberId);
          const { id: fileId, contentType } = fileResponse;
          const newData = {
            ...data,
            docId,
            fileId,
            contentType,
            hasAttachment: 1,
          };
          yield call(saveDocumentDetails, memberId, newData);
        } catch (e) {
          snackActions.error('Could not able to upload the document');
        }
      }
      if (isNew) {
        snackActions.success('New Document Details added');
      } else {
        snackActions.success('Document Details updated successfully');
      }
      yield put({
        type: SAVE_DOCUMENT_DETAILS.Success,
        payload: 'success',
      });
      yield put({
        type: GROUP_DOCUMENT_DETAILS.Request,
        payload: memberId,
      });
    }
  } catch (e) {
    snackActions.error('Could not able to save the data');
    yield put({
      type: SAVE_DOCUMENT_DETAILS.Fail,
    });
  }
}

export function * saveContactDetailsSaga({ payload }) {
  try {
    const { memberId, data } = payload;
    const response = yield call(saveContactDetails, memberId, data);
    const { data: { isNew, responseCode, responseDesc } } = response;
    if (responseCode && responseCode !== '1') {
      snackActions.error(responseDesc);
      yield put({
        type: SAVE_CONTACT_DETAILS.Fail,
      });
    } else {
      if (isNew) {
        snackActions.success('New Address Details added');
      } else {
        snackActions.success('Address Details updated successfully');
      }
      yield put({
        type: SAVE_CONTACT_DETAILS.Success,
        payload: 'success',
      });
      yield put({
        type: GROUP_CONTACT_DETAILS.Request,
        payload: memberId,
      });
    }
  } catch (e) {
    snackActions.error('Could not able to save the data');
    yield put({
      type: SAVE_CONTACT_DETAILS.Fail,
    });
  }
}

export function * saveBankDetailsSaga({ payload }) {
  try {
    const { memberId, data } = payload;
    const response = yield call(saveBankDetails, memberId, data);
    const { data: { isNew, responseCode, responseDesc } } = response;
    if (responseCode && responseCode !== '1') {
      snackActions.error(responseDesc);
      yield put({
        type: SAVE_BANK_DETAILS.Fail,
      });
    } else {
      if (isNew) {
        snackActions.success('New Bank Details added');
      } else {
        snackActions.success('Bank Details updated successfully');
      }
      yield put({
        type: SAVE_BANK_DETAILS.Success,
        payload: 'success',
      });
      yield put({
        type: GROUP_BANK_DETAILS.Request,
        payload: memberId,
      });
    }
  } catch (e) {
    snackActions.error('Could not able to save the data');
    yield put({
      type: SAVE_BANK_DETAILS.Fail,
    });
  }
}

export function * deleteDocumentDetailsSaga({ payload }) {
  try {
    const { memberId, id } = payload;
    yield call(deleteDocDetails, id);
    yield put({
      type: DELETE_DOCUMENT_DETAILS.Success,
    });
    snackActions.success('Document Details deleted successfully!');
    yield put({
      type: GROUP_DOCUMENT_DETAILS.Request,
      payload: memberId,
    });
  } catch (e) {
    yield put({
      type: DELETE_DOCUMENT_DETAILS.Fail,
    });
  }
}

export function * deleteDocumentSaga({ payload }) {
  try {
    const { memberId, id } = payload;
    yield call(deleteDocument, id);
    yield put({
      type: DELETE_DOCUMENT.Success,
    });
    snackActions.success('Document deleted successfully!');
    yield put({
      type: GROUP_DOCUMENT_DETAILS.Request,
      payload: memberId,
    });
  } catch (e) {
    yield put({
      type: DELETE_DOCUMENT.Fail,
    });
  }
}

export function * deleteContactDetailsSaga({ payload }) {
  try {
    const { memberId, id } = payload;
    yield call(deleteContactDetails, id);
    yield put({
      type: DELETE_CONTACT_DETAILS.Success,
    });
    snackActions.success('Contact Details deleted successfully!');
    yield put({
      type: GROUP_CONTACT_DETAILS.Request,
      payload: memberId,
    });
  } catch (e) {
    yield put({
      type: DELETE_CONTACT_DETAILS.Fail,
    });
  }
}

export function * deleteBankDetailsSaga({ payload }) {
  try {
    const { memberId, id } = payload;
    yield call(deleteBankDetails, id);
    yield put({
      type: DELETE_BANK_DETAILS.Success,
    });
    snackActions.success('Bank Details deleted successfully!');
    yield put({
      type: GROUP_BANK_DETAILS.Request,
      payload: memberId,
    });
  } catch (e) {
    yield put({
      type: DELETE_BANK_DETAILS.Fail,
    });
  }
}

export function * watchGroupSaga() {
  yield takeLatest(GROUP_MATERIAL.Request, getGroupMaterialSaga);
  yield takeLatest(GROUP_MATERIAL_SELECTION.Request, getGroupMaterialSelectionSaga);
  yield takeLatest(GROUP_TEAMS_CONTACT.Request, getAllTeamMembersSaga);
  yield takeLatest(GROUP_ADMIN_ROLES.Request, getAllRoleGroupAdminSaga);
  yield takeLatest(GROUP_TEAM_MEMBER.Request, getGroupTeamMemberSaga);
  yield takeLatest(SAVE_GROUP_TEAM_MEMBER.Request, saveGroupTeamContactSaga);
  yield takeLatest(GET_SUB_ACTIVITIES.Request, loadGrpSubActItemSaga);
  yield takeLatest(GET_ACTIVITIES.Request, loadGrpActItemSaga);
  yield takeLatest(GET_SUB_ACTIVITY_NAMES.Request, getSubActNameDescSaga);
  yield takeLatest(GET_SPECIFICATIONS.Request, getMaterialSpecSaga);
  yield takeLatest(GET_BRANDS.Request, getAllMaterialBrandSaga);
  yield takeLatest(GET_COUNTRIES.Request, getAllMadeCountrySaga);
  yield takeLatest(GET_PACKAGE.Request, getAllPackageSaga);
  yield takeLatest(GET_UNITS.Request, getAllUnitSaga);
  yield takeLatest(ADD_SUB_ACTIVITY_NAMES.Request, addSubActNameDescSaga);
  yield takeLatest(ADD_BRANDS.Request, addMaterialBrandSaga);
  yield takeLatest(ADD_PACKAGE.Request, addPackageSaga);
  yield takeLatest(ADD_SPECIFICATIONS.Request, addMaterialSpecSaga);
  yield takeLatest(ADD_UNITS.Request, addUnitSaga);
  yield takeLatest(GROUP_CONTACT_DETAILS.Request, getGroupContactDetailsSaga);
  yield takeLatest(GROUP_DOCUMENT_DETAILS.Request, getGroupDocumentDetailsSaga);
  yield takeLatest(GROUP_BANK_DETAILS.Request, getGroupBankDetailsSaga);
  yield takeLatest(SAVE_MATERIAL_MASTER.Request, saveMaterialMasterSaga);
  yield takeLatest(GET_GROUP_DETAILS.Request, getGroupDetailsSaga);
  yield takeLatest(SAVE_GROUP_DETAILS.Request, saveGroupDetailsSaga);
  yield takeLatest(SAVE_CONTACT_DETAILS.Request, saveContactDetailsSaga);
  yield takeLatest(SAVE_BANK_DETAILS.Request, saveBankDetailsSaga);
  yield takeLatest(SAVE_DOCUMENT_DETAILS.Request, saveDocumentDetailsSaga);
  yield takeLatest(DELETE_DOCUMENT_DETAILS.Request, deleteDocumentDetailsSaga);
  yield takeLatest(DELETE_DOCUMENT.Request, deleteDocumentSaga);
  yield takeLatest(DELETE_CONTACT_DETAILS.Request, deleteContactDetailsSaga);
  yield takeLatest(DELETE_BANK_DETAILS.Request, deleteBankDetailsSaga);
}

export default function * groupSaga() {
  yield all([
    watchGroupSaga(),
  ]);
}
