import { lazy, React } from 'react';
import Loadable from './LazyComponents/Loadable';
import ProtectedLayout from '../layout/ProtectedLayout';
import { accountDetails } from './commonRoutes';

const MemberDashBoard = Loadable(lazy(() => import('../pages/member/DashBoard')));
const ContactDetails = Loadable(lazy(() => import('../pages/ContactDetails')));
const Personal = Loadable(lazy(() => import('../pages/ContactDetails/Personal')));
const Education = Loadable(lazy(() => import('../pages/ContactDetails/Education')));
const Employee = Loadable(lazy(() => import('../pages/ContactDetails/Employee')));
const Job = Loadable(lazy(() => import('../pages/ContactDetails/Job')));
const Profile = Loadable(lazy(() => import('../pages/ContactDetails/Profile')));

const MemberRoute = {
  path: '/member',
  element: <ProtectedLayout />,
  children: [
    {
      path: 'dashboard',
      element: <MemberDashBoard />,
    },
    {
      path: 'account',
      element: <ContactDetails />,
      children: [
        {
          path: '',
          element: <Personal />,
        },
        {
          path: 'details',
          element: <Personal />,
        },
        ...accountDetails,
        {
          path: 'education',
          element: <Education />,
        },
        {
          path: 'work',
          element: <Employee />,
        },
        {
          path: 'job',
          element: <Job />,
        },
      ],
    },
    {
      path: 'profile',
      element: <Profile />,
    },
  ],
};

export default MemberRoute;
