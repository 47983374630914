import {
  ACTIVE_MEMBERS,
  ADD_DESCRIPTION,
  ADD_MATERIAL_DETAILS,
  ALL_ROLE_GROUP_ACTIVITIES,
  ALL_ROLE_GROUP_ADMIN,
  BANK_MASTER,
  BLOOD_GROUP,
  CONTACT_TYPE,
  DELETE_NOTIFICATION,
  DOCUMENT_TYPE,
  GET_ALL_MATERIALS,
  GET_CURRENCY_CODES,
  GET_DESCRIPTION,
  GET_LATEST_PRICE,
  GET_MATERIAL,
  GET_MATERIAL_DETAILS,
  GET_NOTIFICATIONS,
  GET_PRICE_REQUESTS,
  PAID_BASIC_MASTER,
  PRICE_REQUEST,
  PRICE_REQUEST_APPROVE,
  PRICE_REQUEST_REJECT,
  RESET_ADD_MATERIAL_DETAILS,
  RESET_PRICE_REQUEST,
  SAVE_MATERIAL_IMAGES,
  WORK_TIME_MASTER
} from '../constants/common';

const dataObjArray = {
  fetching: false,
  data: [],
};

const dataObj = {
  fetching: false,
  data: {},
};

const saveObj = {
  saving: false,
  data: null,
  hasErrors: false,
};

export const initialState = {
  activeMembers: dataObjArray,
  contactType: dataObjArray,
  documentType: dataObjArray,
  bankMaster: dataObjArray,
  bloodGroup: dataObjArray,
  workTimeMaster: dataObjArray,
  paidBasisMaster: dataObjArray,
  allRoleGroupAdmin: dataObjArray,
  allGroupActivities: dataObj,
  allMaterials: dataObjArray,
  material: dataObj,
  materialDetails: dataObj,
  currencyCodes: dataObjArray,
  addActivities: saveObj,
  getActivities: dataObj,
  addMaterial: saveObj,
  latestPrice: dataObjArray,
  priceRequest: saveObj,
  priceRequestApprove: saveObj,
  priceRequestReject: saveObj,
  getPriceRequests: dataObjArray,
  notifications: dataObjArray,
  deleteNotification: dataObj,
};

const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ACTIVE_MEMBERS.Request: {
    return {
      ...state,
      activeMembers: initialState.activeMembers,
    };
  }
  case ACTIVE_MEMBERS.Success: {
    return {
      ...state,
      activeMembers: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ACTIVE_MEMBERS.Fail: {
    return {
      ...state,
      activeMembers: initialState.activeMembers,
    };
  }
  case CONTACT_TYPE.Request: {
    return {
      ...state,
      contactType: {
        fetching: true,
      },
    };
  }
  case CONTACT_TYPE.Success: {
    return {
      ...state,
      contactType: {
        fetching: false,
        data: payload,
      },
    };
  }
  case CONTACT_TYPE.Fail: {
    return {
      ...state,
      contactType: dataObjArray,
    };
  }

  case DOCUMENT_TYPE.Request: {
    return {
      ...state,
      documentType: {
        fetching: true,
      },
    };
  }
  case DOCUMENT_TYPE.Success: {
    return {
      ...state,
      documentType: {
        fetching: false,
        data: payload,
      },
    };
  }
  case DOCUMENT_TYPE.Fail: {
    return {
      ...state,
      documentType: dataObjArray,
    };
  }

  case BANK_MASTER.Request: {
    return {
      ...state,
      bankMaster: {
        fetching: true,
      },
    };
  }
  case BANK_MASTER.Success: {
    return {
      ...state,
      bankMaster: {
        fetching: false,
        data: payload,
      },
    };
  }
  case BANK_MASTER.Fail: {
    return {
      ...state,
      bankMaster: dataObjArray,
    };
  }

  case BLOOD_GROUP.Request: {
    return {
      ...state,
      bloodGroup: {
        fetching: true,
        data: {},
      },
    };
  }
  case BLOOD_GROUP.Success: {
    return {
      ...state,
      bloodGroup: {
        fetching: false,
        data: payload,
      },
    };
  }
  case BLOOD_GROUP.Fail: {
    return {
      ...state,
      bloodGroup: dataObjArray,
    };
  }
  case WORK_TIME_MASTER.Request: {
    return {
      ...state,
      workTimeMaster: {
        fetching: true,
        data: {},
      },
    };
  }
  case WORK_TIME_MASTER.Success: {
    return {
      ...state,
      workTimeMaster: {
        fetching: false,
        data: payload,
      },
    };
  }
  case WORK_TIME_MASTER.Fail: {
    return {
      ...state,
      workTimeMaster: dataObjArray,
    };
  }
  case PAID_BASIC_MASTER.Request: {
    return {
      ...state,
      paidBasisMaster: {
        fetching: true,
        data: {},
      },
    };
  }
  case PAID_BASIC_MASTER.Success: {
    return {
      ...state,
      paidBasisMaster: {
        fetching: false,
        data: payload,
      },
    };
  }
  case PAID_BASIC_MASTER.Fail: {
    return {
      ...state,
      paidBasisMaster: dataObjArray,
    };
  }
  case ALL_ROLE_GROUP_ADMIN.Request: {
    return {
      ...state,
      allRoleGroupAdmin: {
        fetching: true,
        data: {},
      },
    };
  }
  case ALL_ROLE_GROUP_ADMIN.Success: {
    return {
      ...state,
      allRoleGroupAdmin: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ALL_ROLE_GROUP_ADMIN.Fail: {
    return {
      ...state,
      allRoleGroupAdmin: dataObjArray,
    };
  }
  case ALL_ROLE_GROUP_ACTIVITIES.Request: {
    return {
      ...state,
      allGroupActivities: {
        fetching: true,
        data: {},
      },
    };
  }
  case ALL_ROLE_GROUP_ACTIVITIES.Success: {
    return {
      ...state,
      allGroupActivities: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ALL_ROLE_GROUP_ACTIVITIES.Fail: {
    return {
      ...state,
      allGroupActivities: dataObj,
    };
  }
  case GET_ALL_MATERIALS.Request: {
    return {
      ...state,
      allMaterials: {
        fetching: true,
        data: [],
      },
    };
  }
  case GET_ALL_MATERIALS.Success: {
    return {
      ...state,
      allMaterials: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_ALL_MATERIALS.Fail: {
    return {
      ...state,
      allMaterials: dataObjArray,
    };
  }
  case GET_MATERIAL.Request: {
    return {
      ...state,
      material: {
        fetching: true,
        data: {},
      },
    };
  }
  case GET_MATERIAL.Success: {
    return {
      ...state,
      material: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_MATERIAL.Fail: {
    return {
      ...state,
      material: dataObj,
    };
  }
  case GET_MATERIAL_DETAILS.Request: {
    return {
      ...state,
      materialDetails: {
        fetching: true,
        data: {},
      },
    };
  }
  case GET_MATERIAL_DETAILS.Success: {
    return {
      ...state,
      materialDetails: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_MATERIAL_DETAILS.Fail: {
    return {
      ...state,
      materialDetails: dataObj,
    };
  }
  case SAVE_MATERIAL_IMAGES.Request: {
    return {
      ...state,
    };
  }
  case GET_CURRENCY_CODES.Success: {
    return {
      ...state,
      currencyCodes: {
        fetching: false,
        data: payload,
      },
    };
  }
  case ADD_DESCRIPTION.Request: {
    return {
      ...state,
      addActivities: {
        saving: true,
        masterType: payload.masterType,
        data: {},
      },
    };
  }
  case ADD_DESCRIPTION.Success: {
    return {
      ...state,
      addActivities: {
        data: payload.data,
        saving: false,
        masterType: payload.masterType,
      },
    };
  }
  case ADD_DESCRIPTION.Fail: {
    return {
      ...state,
      addActivities: saveObj,
    };
  }
  case GET_DESCRIPTION.Request: {
    const { getActivities: { data } } = state;
    return {
      ...state,
      getActivities: {
        fetching: true,
        data,
        masterType: payload.masterType,
      },
    };
  }
  case GET_DESCRIPTION.Success: {
    const { masterType, data: serverData } = payload;
    const { getActivities: { data } } = state;
    return {
      ...state,
      getActivities: {
        fetching: false,
        data: {
          ...data,
          [masterType]: serverData,
        },
      },
    };
  }
  case GET_DESCRIPTION.Fail: {
    return {
      ...state,
    };
  }
  case ADD_MATERIAL_DETAILS.Request: {
    return {
      ...state,
      addMaterial: {
        saving: true,
        data: null,
      },
    };
  }
  case ADD_MATERIAL_DETAILS.Success: {
    return {
      ...state,
      addMaterial: {
        saving: false,
        data: payload,
      },
    };
  }
  case ADD_MATERIAL_DETAILS.Fail:
  case RESET_ADD_MATERIAL_DETAILS:{
    return {
      ...state,
      addMaterial: saveObj,
    };
  }
  case PRICE_REQUEST.Request: {
    return {
      ...state,
      priceRequest: {
        saving: true,
        data: null,
      },
    };
  }
  case PRICE_REQUEST.Success: {
    return {
      ...state,
      priceRequest: {
        saving: false,
        data: payload,
      },
    };
  }
  case PRICE_REQUEST.Fail:
  case RESET_PRICE_REQUEST:{
    return {
      ...state,
      priceRequest: saveObj,
    };
  }
  case PRICE_REQUEST_APPROVE.Request: {
    return {
      ...state,
      priceRequestApprove: {
        saving: true,
        data: null,
      },
    };
  }
  case PRICE_REQUEST_APPROVE.Success: {
    return {
      ...state,
      priceRequestApprove: {
        saving: false,
        data: payload,
      },
    };
  }
  case PRICE_REQUEST_APPROVE.Fail: {
    return {
      ...state,
      priceRequestApprove: saveObj,
    };
  }
  case PRICE_REQUEST_REJECT.Request: {
    return {
      ...state,
      priceRequestReject: {
        saving: true,
        data: null,
      },
    };
  }
  case PRICE_REQUEST_REJECT.Success: {
    return {
      ...state,
      priceRequestReject: {
        saving: false,
        data: payload,
      },
    };
  }
  case PRICE_REQUEST_REJECT.Fail: {
    return {
      ...state,
      priceRequestReject: saveObj,
    };
  }
  case GET_LATEST_PRICE.Request: {
    return {
      ...state,
      latestPrice: {
        fetching: true,
        data: [],
      },
    };
  }
  case GET_LATEST_PRICE.Success: {
    return {
      ...state,
      latestPrice: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_LATEST_PRICE.Fail: {
    return {
      ...state,
      latestPrice: dataObjArray,
    };
  }
  case GET_PRICE_REQUESTS.Request: {
    return {
      ...state,
      getPriceRequests: {
        fetching: true,
        data: [],
      },
    };
  }
  case GET_PRICE_REQUESTS.Success: {
    return {
      ...state,
      getPriceRequests: {
        fetching: false,
        data: payload,
      },
    };
  }
  case GET_PRICE_REQUESTS.Fail: {
    return {
      ...state,
      getPriceRequests: dataObjArray,
    };
  }
  case GET_NOTIFICATIONS.Request: {
    return {
      ...state,
    };
  }
  case GET_NOTIFICATIONS.Success: {
    const { deletedNotifications = [] } = state;
    const updatedNotifications = payload.filter(item => !deletedNotifications.includes(item.id));
    return {
      ...state,
      notifications: {
        data: updatedNotifications,
      },
    };
  }
  case GET_NOTIFICATIONS.Fail: {
    return {
      ...state,
      notifications: dataObjArray,
    };
  }
  case DELETE_NOTIFICATION.Request: {
    const { notifications: { data = [] }, deletedNotifications = [] } = state;
    const { id } = payload;
    deletedNotifications.push(id);
    const updatedNotifications = data.filter(item => item.id !== id);
    return {
      ...state,
      notifications: {
        data: updatedNotifications,
      },
      deletedNotifications,
    };
  }
  default: return state;
  }
};

export default commonReducer;
